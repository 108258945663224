<template>
  <div class="generalContainer">
    <div class="contentTable">
      <table style="width: 100%">
        <thead
          style="
            color: whitesmoke;
            background: #3a4750;
            text-align: center;
            height: 28px;
          "
        >
          <tr>
            <th>Data Emissão</th>
            <th>Número</th>
            <th>Carregamento</th>
            <th>Descarregamento</th>
            <th>Nome Condutor</th>
            <th>Status</th>
            <th>Valor Total</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody style="color: #3a4750; width: 100%">
          <tr
            v-for="mdfe in dataSearchMdfe"
            :key="mdfe.id"
            style="text-align: center; height: 30px"
          >
            <td>
              {{ formatDate(mdfe.data_mdfe) }}
            </td>
            <td v-if="mdfe.response">{{ mdfe.response.mdfe }}</td>
            <td v-else>-</td>
            <td>
              {{ mdfe.carregamento[0].nome_municipio }} -
              {{ mdfe.uf_carregamento }}
            </td>
            <td>
              {{ mdfe.descarregamento[0].nome_municipio }} -
              {{ mdfe.uf_descarregamento }}
            </td>
            <td>
              {{ mdfe.nomeCondutor || "--" }}
            </td>
            <td>{{ mdfe.status || "Não Enviada" }}</td>
            <td>
              {{
                mdfe.valor_carga.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td style="display: flex; justify-content: center; width: 100%">
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findById(mdfe.id)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="mdfe.response"
                    @click="openPdf(mdfe.id)"
                  >
                    Abrir PDF
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="mdfe.status === 'aprovado'"
                    @click="closeMdfe(mdfe.id)"
                  >
                    Encerrar MDF-e
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="mdfe.status === null"
                    @click="getIdMdfe(mdfe.id)"
                    v-b-modal.modalConfirmDeletion
                  >
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="mdfe.status === 'aprovado'"
                    @click="handleUpdateOrCancelMdfe(mdfe)"
                  >
                    Cancelar MDF-e
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <div v-if="spin" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>

    <b-modal
      id="modalConfirmDeletion"
      hide-footer
      hide-header
      centered
      title="Excluir"
    >
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal">
          <button class="btnContent" @click="deleteMdfe">Deletar</button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modalConfirmDeletion')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalCancelMdfe"
      hide-footer
      hide-header
      centered
      title="Excluir"
      @hidden="clearId"
    >
      <div>
        <h6 class="titleModal">Cancelar Mdf-e</h6>
        <div class="rowContent mt-3">
          <div class="contentDescription">
            <label class="description">Motivo do cancelamento</label>

            <textarea
              class="inputTextArea"
              v-model="motivo"
              rows="3"
              max-rows="6"
            />
          </div>
        </div>
        <div class="btnModalCancelMdfe">
          <button class="btnContent" @click="cancelMdfe">
            Confirmar cancelamento
            <b-spinner v-if="spinFromCancelMdfe" class="spin" />
          </button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modalCancelMdfe')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import serviceMdfe from "../../services/serviceMdfe";
import { HandleErrors } from "../../utils/handleErrors";

import printReport from "../../reports/sale";

export default {
  props: {
    page: {
      type: [Number, String],
      default: 1,
    },
    searchPerStatus: {
      type: String,
      default: null,
    },
    dataFilterMdfe: {},
  },
  data() {
    return {
      dataSearchMdfe: [],
      spin: false,
      filter: {
        text: "",
        type: "",
        startDate: "",
        endDate: "",
      },
      filterOptions: [
        { value: "", text: "Selecione uma opção" },
        { value: "nomeCliente", text: "Cliente" },
      ],
      situationOptions: [
        { value: "", text: "Selecione um status" },
        { text: "Pendente", value: "Pendente" },
        { text: "Em andamento", value: "Em andamento" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "Faturado", value: "Faturado" },
        { text: "Cancelado", value: "Cancelado" },
      ],
      spinFromCancelMdfe: false,
      isCpfCnpj: false,
      motivo: "",
      idFromCancelMdfe: "",
      objectClose: {
        chave: "",
        uf: "",
        municipio: "",
        codigo_municipio: "",
      },
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_MDFE"]),

    async searchAll() {
      try {
        const data = await serviceMdfe.searchMdfe(
          "",
          "",
          1,
          this.filter.startDate,
          this.filter.endDate
        );
        this.dataSearchMdfe = data.data.mdfes;
        this.dataSearchMdfe = this.dataSearchMdfe.map((item) => {
          if (item.response) {
            item.response = JSON.parse(item.response);
          }
          return item;
        });
        this.$emit("tableLength", this.dataSearchMdfe.length);
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: error?.reponse?.data?.message || "Ocorreu um erro ao listar",
          type: "info",
        });
      }
    },

    async findById(id) {
      const data = await serviceMdfe.findById(id);
      data.response = JSON.parse(data.response);
      this.handleEdit(data);
    },

    getIdMdfe(idMdfe) {
      this.dataMdfeId = idMdfe;

      this.findById();
    },

    async deleteMdfe() {
      try {
        await serviceMdfe.delete(this.dataMdfeId);
        this.dataMdfeId = "";
        this.$bvModal.hide("modalConfirmDeletion");
        this.searchAll();

        return this.$toast.open({
          message: "Movimentação Excluida!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao deletar!",
          type: "info",
        });
      }
    },

    async handleUpdateOrCancelMdfe(mdfe) {
      if (mdfe.response) {
        if (mdfe && Object.keys(mdfe.response)) {
          this.idFromCancelMdfe = mdfe.id;
          this.openModalCancelMdfe();
        }
      } else {
        return this.$toast.open({
          message: "MDF-e não foi emitida!",
          type: "success",
        });
      }
    },

    clearId() {
      this.idFromCancelMdfe = "";
      this.motivo = "";
    },

    async cancelMdfe() {
      try {
        this.spinFromCancelMdfe = true;
        await serviceMdfe.cancelMdfe(this.idFromCancelMdfe, this.motivo);

        this.$bvModal.hide("modalCancelMdfe");
        this.$toast.open({
          message: "MDF-e cancelada com sucesso",
          type: "success",
        });
        this.searchAll();
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message,
          type: "info",
        });
      } finally {
        this.spinFromCancelMdfe = false;
      }
    },

    openModalCancelMdfe() {
      this.$bvModal.show("modalCancelMdfe");
    },

    async openPdf(id) {
      const data = await serviceMdfe.findById(id);
      data.response = JSON.parse(data.response);
      try {
        window.open(`${data.response.damdfe}`, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      }
    },

    async closeMdfe(id) {
      try {
        const data = await serviceMdfe.findById(id);
        const idMdfe = data.id;
        data.response = JSON.parse(data.response);
        this.objectClose.chave = data.response.chave;
        this.objectClose.uf = data.uf_descarregamento;
        this.objectClose.municipio = data.descarregamento[0].nome_municipio;
        this.objectClose.codigo_municipio =
          data.descarregamento[0].codigo_municipio;

        await serviceMdfe.closeMdfe(this.objectClose, idMdfe);

        this.$toast.open({
          message: "MDF-e encerrado com sucesso!",
          type: "success",
        });

        this.searchAll();
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao encerrar!",
          type: "info",
        });
      }
    },

    handleEdit(dataMdfe) {
      this.SET_DATA_SEARCH_FROM_MDFE(dataMdfe);
      this.$emit("openToEdit", "mdfe");
    },

    clearFilterText() {
      if (this.filter.type === "cpfCnpj") {
        this.isCpfCnpj = true;
      } else {
        this.isCpfCnpj = false;
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    printReportOrder(id) {
      printReport("reportOrder", id);
    },
  },
  created() {
    this.searchAll();
    this.SET_DATA_SEARCH_FROM_MDFE({});
  },
  mounted() {
    this.searchAll();
  },
  watch: {
    dataFilterMdfe() {
      this.searchAll();
    },
    searchPerStatus() {
      this.filter.text = this.searchPerStatus;
      this.filter.type = "status";
    },
  },
};
</script>

<style scoped>
.generalContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px;
  width: 100%;
  max-width: 100%;
}

.contentTable {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.btnActions {
  display: flex;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: normal;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.contentDescription {
  width: 100%;
  position: relative;
}

.description {
  position: absolute;
  top: -2px;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 10;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
  font-size: 12px;
}

.btnModalCancelMdfe {
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spin {
  position: absolute;
  right: 5px;
  height: 18px;
  width: 18px;
  color: white;
}

.btnContent {
  position: relative;
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

/* MODAL BILLS */
.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.closeModalBill:hover {
  color: red;
}

.contentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.noContentTable {
  background: #edebeb;
  padding: 10px;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentStatus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
}

.contentStatus span {
  cursor: context-menu;
}

.statusPayment {
  background: #eff0f0;
  color: green;
}

.statusPaymentOpen {
  background: #eff0f0;
  color: rgb(132, 132, 132);
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.closeModalBill {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: transparent;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalBill:hover {
  background: whitesmoke;
  color: red;
}

.closeModalBill:focus {
  background: red;
  border: none;
  box-shadow: none;
}
/* BTN REMOVE BILL */

.buttonRemoveBill {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3a4750;
  color: whitesmoke;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.buttonRemoveBill:hover {
  width: 70px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.buttonRemoveBill::before {
  position: absolute;
  top: -33px;
  content: "Deletar";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.buttonRemoveBill:hover::before {
  font-size: 10px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: 0.3s;
}

.searchContainer {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .searchContainer {
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .generalContainer {
    padding: 20px 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .searchContainer {
    padding: 0 0 1rem 0;
  }
}
</style>