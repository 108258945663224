import api from './axios'

class ServiceCustomer {
  async getCustomersForSelectBox(){
    const { data } = await api.get('/customers/combobox')
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/customers/${id}`);
    return data
  }

  async searchCustomer(text = '', type = 'nome', page = 1) {
    const { data } = await api.get(
      `/customers?q=${text}&type=${type}&page=${page}`
    );

    return data.data
  }

  async fillComboBoxWithPaginate(idCliente = '', text = '', type = 'nome', page = 1) {
    const { data } = await api.get(
      `/customers/combobox/with/paginate?idCliente=${idCliente}&q=${text}&type=${type}&page=${page}`
    );

    return data
  }
}

export default new ServiceCustomer
