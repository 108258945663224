import api from './axios'

class ServiceNotaFiscal {
  async saveNota(dataNote) {
    const { data } = await api.post('/nota', dataNote)
    return data
  }

  async findNotaById(idNota) {
    const { data } = await api.get(`/nota/${idNota}`)
    return data
  }

  async findNotaByChaveReferenciada(chave) {
    const { data } = await api.get(`/nota/buscar/nota/referenciada/${chave}`)
    return data
  }

  async updateNota(dataNota) {
    const { data } = await api.put(`/nota/${dataNota.id}`, dataNota)
    return data
  }

  async sendExitNota(idNota) {
    const { data } = await api.get(`/nota/send/${idNota}`)

    return data
  }

  async sendEntryNotaDevolution(dataReturnNota, id) {
    const { data } = await api.put(`/nota/send/exit/nota/entry/${id}`, dataReturnNota)
    return data
  }

  async sendExitNotaDevolution(idNota) {
    const { data } = await api.get(`/nota/send/devolution/nota/exit/provider/${idNota}`)
    return data
  }

  async cancelNota(dataNota, idNota) {
    const { data } = await api.put(`/nota/cancel/nota/${idNota}`, dataNota)
    return data
  }

  async correctionLetter(dataNota, idNota) {
    const { data } = await api.put(`/nota/correction/${idNota}`, dataNota)
    return data
  }

  async downloadXml(dataXml) {
    const { data } = await api.post(`/nota/download/xml/`, dataXml)
    return data
  }

  async sendCoupon(idSale) {
    const { data } = await api.get(`/nota/send/and/transform/coupon/${idSale}`)
    return data
  }
}

export default new ServiceNotaFiscal()