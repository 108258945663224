import api from "./axios";

class ServiceMachine {
  async createOrder(idSale) {
    const { data } = await api.get(`/machine/${idSale}`);

    return data;
  }

  async cancelOrder(idSale) {
    const { data } = await api.get(`/machine/cancel-order/${idSale}`);

    return data;
  }

  async checkIsPaidAndGenerateDocument(idSale) {
    const { data } = await api.get(`/machine/check-is-paid/generate-document/${idSale}`);

    return data;
  }

  async checkOrder(idSale) {
    const { data } = await api.get(`/machine/check-order/${idSale}`);

    return data;
  }

  async printDocument(idSale, base64Document) {
    await api.post(`/machine/print/${idSale}`, { base64Document })
  }
}

export default new ServiceMachine()