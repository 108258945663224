<template>
  <div>
    <div class="navBar">
      <div class="contentLogoAndTitle">
        <div>
          <img style="width: 100px; height: 35px" :src="logoBms" />
        </div>

        <div class="nameCompany" v-if="!handleVerifyUserIsAccountant">
          Olá, <span>{{ dataCompany.nome }}!</span>
        </div>

        <div class="textAccountant" v-else>
          <span> Área da contabilidade </span>
        </div>

        <div class="contentSelectCompany" v-if="enableSelectCompany">
          <InputSelec
            title="Selecione sua empresa"
            :enableIconCreate="false"
            label="Selecione sua empresa"
            :items="companies"
            valueField="token"
            textField="nameCompany"
            :dataSelect.sync="tokenCompanySelected"
            @selectedIten="handleCompanySelected"
            ref="companies"
          />
        </div>
      </div>

      <div class="contentHelpAndNotificationAccounting">
        <router-link to="/area-contador">
          <div class="contentNotificationAccounting">
            <p style="width: 120px; color: #313b42">Área do contador</p>
            <span class="numberMessages" v-if="numberOfNewMessages">{{
              numberOfNewMessages
            }}</span>
          </div>
        </router-link>

        <div
          class="contentHelp"
          @click="sideOpenAdnFindUser"
          v-if="!handleVerifyUserIsAccountant"
        >
          <b-icon-person-lines-fill
            variant="dark"
            @click.stop="sideOpenAdnFindUser"
            class="iconHelper buttonToOpenSideCommon"
          />
          <span class="warningExpiredPlan" v-if="!dataPlan.ativo">
            <b-icon-exclamation-circle-fill
              scale="1.3"
              style="color: red; background-color: white; border-radius: 50%"
            />
          </span>
        </div>
      </div>
      <div :class="`${sideOCompany} sideCommon`">
        <button class="closeSize" @click="sideOpenCompany">
          <b-icon-x-circle />
        </button>
        <div :class="`${contentSideOCompany} headerSide`">
          <img class="logoSide" :src="`${dataCompany.urlImage}`" alt="" />
          <div class="userContent">
            <p>Olá</p>
            <p>, {{ this.user.userName || dataCompany.nome }}</p>
            <div v-if="spinGeneral" class="card_content">
              , <b-spinner small class="spin"></b-spinner>
            </div>
          </div>
          <p style="font-size: 14px">{{ dataCompany.cpfcnpj }}</p>
        </div>
        <div :class="`${contentSideOCompany} contentSide`">
          <button
            v-b-modal.modalPlan
            @click="findPlan()"
            class="contentSideBtn contentBtnMyPlan"
          >
            Meu plano
            <span class="warningExpiredPlanToModal" v-if="!dataPlan.ativo">
              <b-icon-exclamation-circle-fill
                scale="1.3"
                style="color: red; background-color: white; border-radius: 50%"
              />
            </span>
          </button>
          <router-link to="/configuracoes">
            <button class="contentSideBtn">Meus dados</button>
          </router-link>
          <button class="contentSideBtn" @click="openContract">
            Detalhes do Contrato
          </button>
        </div>
        <div :class="`${contentSideOCompany} contentSideDown`">
          <button v-b-modal.modalInfo class="contentSideBtn">
            <b-icon-info-circle scale="1.5" class="mr-2" />Ajuda & Informações
          </button>
          <button @click="redirectWhatsApp" class="contentSideBtn">
            <b-icon-headset scale="1.5" class="mr-2" />Suporte
          </button>
          <router-link to="/">
            <button @click="exit" class="contentSideBtn">
              <b-icon-door-open scale="1.5" class="mr-2" />Sair
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div
      :class="`sidebar ${disable}`"
      @mouseenter="sidBarExpand"
      @mouseleave="sidBarExpand"
    >
      <ul class="nav-links">
        <li>
          <router-link to="/home">
            <button
              style="margin-top: 20px"
              id="selected"
              :class="`${iconsCenter} contentLink iconHome ${
                navItemSelected === 'home' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-house-door scale="1.3"></b-icon-house-door></i>
              <span :class="sizeText">Início</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/vendas">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'vendas' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-graph-up scale="1.3"></b-icon-graph-up></i>
              <span :class="sizeText">Vendas e Orçamentos</span>
            </button>
          </router-link>
        </li>

        <li v-if="enableMtq">
          <router-link to="/vendas-mt">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'vendasMt' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-tools scale="1.3"></b-icon-tools></i>
              <span :class="sizeText">MT2</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/ordem">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'ordem' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-file-text scale="1.3"></b-icon-file-text></i>
              <span :class="sizeText">Ordem de Serviço</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/cadastros">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'cadastros' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-person-lines-fill scale="1.3"></b-icon-person-lines-fill
              ></i>
              <span :class="sizeText">Cadastros</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/compras">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'compras' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-cart4 scale="1.3"></b-icon-cart4></i>
              <span :class="sizeText">Compras</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/estoque">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'estoque' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-box-seam scale="1.3"></b-icon-box-seam></i>
              <span :class="sizeText">Estoque Geral</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/financeiro">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'financeiro' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-cash-stack scale="1.3"></b-icon-cash-stack></i>
              <span :class="sizeText">Financeiro</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/fiscal">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'fiscal' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-file-earmark-text scale="1.3"></b-icon-file-earmark-text
              ></i>
              <span :class="sizeText">Emissão Fiscal</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/caixa">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'caixa' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-wallet scale="1.3"></b-icon-wallet></i>
              <span :class="sizeText">Caixa</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/agenda">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'agenda' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-calendar2-week scale="1.3"></b-icon-calendar2-week
              ></i>
              <span :class="sizeText">Agenda</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/relatorios">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'relatorios' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-printer scale="1.3"></b-icon-printer></i>
              <span :class="sizeText">Relatórios</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link :to="userIsAdm ? '/controle-ponto' : '/registro-ponto'">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'controle-ponto' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-book scale="1.3" /></i>
              <span :class="sizeText">Controle de Pontos</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/configuracoes">
            <button
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'configuracoes' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-gear scale="1.3"></b-icon-gear></i>
              <span :class="sizeText">Configurações</span>
            </button>
          </router-link>
        </li>
      </ul>

      <div class="containerBtnSair">
        <ul class="nav-links">
          <li>
            <router-link to="/">
              <button @click="exit" :class="`${iconsCenter} contentLink`">
                <i> <b-icon-door-open scale="1.3"></b-icon-door-open></i>
                <span :class="btnSair">Sair</span>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <b-modal
      id="modalPlan"
      size="lg"
      hide-footer
      hide-header
      centered
      title="Plan"
    >
      <button class="closeModal" @click="$bvModal.hide('modalPlan')">
        <b-icon-x scale="1.5"></b-icon-x>
      </button>
      <div class="contentContractPlan">
        <div class="titleContract">Meu Plano</div>
        <div class="bodyPlan">
          <div class="myPlan">
            <div class="myPlanTitle">
              <img class="imgMyPlan" loading="lazy" :src="planBlack" />
            </div>
            <div class="myPlanDescription">
              <div class="myPlanDescriptionItem">
                <b-icon-patch-check-fill class="myPlanDescriptionItemIcon" />
                <span v-if="objectPlan.tipoPlano === 'tester'">
                  Plano Tester
                </span>
                <span v-if="objectPlan.tipoPlano === 'gestao_simples'">
                  Plano Gestão Simples
                </span>
                <span v-if="objectPlan.tipoPlano === 'gestao_completo'">
                  Plano Gestão Completo
                </span>
                <span v-if="objectPlan.tipoPlano === 'gestao_personalizado'">
                  Plano Gestão Personalizado
                </span>
              </div>
              <div class="myPlanDescriptionItem">
                <b-icon-file-earmark-check-fill
                  class="myPlanDescriptionItemIcon"
                />
                <span v-if="objectPlan.tipoPlano === 'gestao_simples'">
                  Emissão Fiscal: Desabilitado
                </span>
                <span v-else> Emissão Fiscal: Habilitado </span>
              </div>
              <div class="myPlanDescriptionItem">
                <b-icon-calendar-check-fill class="myPlanDescriptionItemIcon" />
                <span>
                  Validade:
                  {{
                    objectPlan.dataFim
                      ? objectPlan.dataFim
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")
                      : ""
                  }}
                </span>
              </div>
              <div class="myPlanDescriptionItem">
                <b-icon-person-lines-fill class="myPlanDescriptionItemIcon" />
                <span>N° de Usuários Liberados: 1 </span>
              </div>
            </div>
            <div class="myPlanAlter">
              <button class="btnContentPlan" @click="redirectNewPlanWhatsApp">
                Alterar/Renovar meu Plano
              </button>
            </div>
          </div>

          <div class="addServicePlan">
            <span class="addServicePlanTitle">Serviços Adicionais</span>
            <div class="addServicePlanContent">
              <div class="rowContent">
                <div class="containerRadio">
                  <label @click="storeLabelName('Emissão Fiscal')">
                    <input type="radio" name="radio" />
                    <span>1. Emissão Fiscal</span>
                  </label>
                  <label @click="storeLabelName('Configurações extras')">
                    <input type="radio" name="radio" />
                    <span>2. Configurações extras</span>
                  </label>
                  <label @click="storeLabelName('Treinamentos extras')">
                    <input type="radio" name="radio" />
                    <span>3. Treinamentos extras</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="addServiceBtnPlan">
              <button
                class="btnContentPlan"
                @click="redirectServiceAddWhatsApp"
              >
                Contratar Serviços
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalInfo"
      size="md"
      hide-footer
      hide-header
      centered
      title="Info"
    >
      <div @click="$bvModal.hide('modalInfo')" class="closeModal">
        <b-icon-x scale="1.3"></b-icon-x>
      </div>

      <Tabs :tabs="tabs" :tabSelected="tabSelected">
        <template v-slot:videos>
          <div class="containerYt">
            <div class="contentYt">
              <span
                style="font-weight: bold; font-size: 13px; font-style: oblique"
                >Últimos Videos:</span
              >
              <div
                class="itemYt"
                onclick="window.open('https://www.youtube.com/watch?v=CkR45_IA8ZI&t=1s', '_blank');"
              >
                <div class="contentLogoYt">
                  <img class="imgYt" loading="lazy" :src="YtLogo" />
                </div>
                <div class="descriptionYt">
                  <span style="font-weight: bold; font-size: 13px"
                    >Apresentação</span
                  >
                  <span
                    style="
                      font-weight: bold;
                      font-size: 11px;
                      color: gray;
                      font-style: oblique;
                    "
                    >Clique aqui para assistir nosso video de
                    Apresentação.</span
                  >
                </div>
              </div>
              <div
                class="itemYt"
                onclick="window.open('https://www.youtube.com/watch?v=63LIDvPf51w', '_blank');"
              >
                <div class="contentLogoYt">
                  <img class="imgYt" loading="lazy" :src="YtLogo" />
                </div>
                <div class="descriptionYt">
                  <span style="font-weight: bold; font-size: 13px"
                    >Frente de Caixa</span
                  >
                  <span
                    style="
                      font-weight: bold;
                      font-size: 11px;
                      color: gray;
                      font-style: oblique;
                    "
                    >Clique aqui para assistir a demonstração do frente de caixa
                    e emissão de NFC-e.</span
                  >
                </div>
              </div>
              <div
                class="itemYt"
                onclick="window.open('https://www.youtube.com/watch?v=5Q-HSBrj18g', '_blank');"
              >
                <div class="contentLogoYt">
                  <img class="imgYt" loading="lazy" :src="YtLogo" />
                </div>
                <div class="descriptionYt">
                  <span style="font-weight: bold; font-size: 13px">Vendas</span>
                  <span
                    style="
                      font-weight: bold;
                      font-size: 11px;
                      color: gray;
                      font-style: oblique;
                    "
                    >Clique aqui para assistir a demonstração da tela de vendas
                    e emissão de NF-e.</span
                  >
                </div>
              </div>
              <div
                class="itemYt"
                onclick="window.open('https://www.youtube.com/watch?v=iL7Re2hSgCA', '_blank');"
              >
                <div class="contentLogoYt">
                  <img class="imgYt" loading="lazy" :src="YtLogo" />
                </div>
                <div class="descriptionYt">
                  <span style="font-weight: bold; font-size: 13px"
                    >Orçamentos</span
                  >
                  <span
                    style="
                      font-weight: bold;
                      font-size: 11px;
                      color: gray;
                      font-style: oblique;
                    "
                    >Clique aqui para assistir a demonstração da tela de
                    Orçamentos.</span
                  >
                </div>
              </div>
              <div class="socialMedia">
                <div class="descriptionsocialMedia">
                  <span
                    style="
                      font-weight: bold;
                      font-size: 11px;
                      color: gray;
                      font-style: oblique;
                    "
                    >Siga Nossas Redes Sociais</span
                  >
                </div>
                <div class="contentLogosocialMedia">
                  <img
                    onclick="window.open('https://www.youtube.com/@BmsSoftwares/', '_blank');"
                    style="
                      width: 55px;
                      height: 40px;
                      padding: 10px;
                      cursor: pointer;
                    "
                    loading="lazy"
                    :src="YtLogo"
                  />
                  <img
                    onclick="window.open('https://www.instagram.com/bms_gestao/', '_blank');"
                    style="
                      width: 50px;
                      height: 50px;
                      padding: 10px;
                      cursor: pointer;
                    "
                    loading="lazy"
                    :src="Instagram"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:tributario>
          <b-card class="scrollTrib"> </b-card>
        </template>
      </Tabs>
    </b-modal>

    <button
      class="containerMobile"
      v-if="pointRecordsIsActive"
      @click="sidBarMobileExpand"
    >
      <b-icon-list-nested v-if="collapsedMobile" scale="2" />
      <b-icon-x v-else scale="2" />
    </button>

    <div :class="`${alternClassMobile} sideMobile`">
      <ul class="nav-links">
        <li>
          <router-link to="/home">
            <button
              style="margin-top: 20px"
              id="selected"
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink iconHome ${
                navItemSelected === 'home' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-house-door scale="1.3"></b-icon-house-door></i>
              <span :class="sizeText">Início</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/vendas">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'vendas' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-graph-up scale="1.3"></b-icon-graph-up></i>
              <span :class="sizeText">Vendas e Orçamentos</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/ordem">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'ordem' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-file-text scale="1.3"></b-icon-file-text></i>
              <span :class="sizeText">Ordem de Serviço</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/cadastros">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'cadastros' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-person-lines-fill scale="1.3"></b-icon-person-lines-fill
              ></i>
              <span :class="sizeText">Cadastros</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/compras">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'compras' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-cart4 scale="1.3"></b-icon-cart4></i>
              <span :class="sizeText">Compras</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/estoque">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'estoque' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-box-seam scale="1.3"></b-icon-box-seam></i>
              <span :class="sizeText">Estoque Geral</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/financeiro">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'financeiro' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-cash-stack scale="1.3"></b-icon-cash-stack></i>
              <span :class="sizeText">Financeiro</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/fiscal">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'fiscal' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-file-earmark-text scale="1.3"></b-icon-file-earmark-text
              ></i>
              <span :class="sizeText">Emissão Fiscal</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/caixa">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'caixa' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-wallet scale="1.3"></b-icon-wallet></i>
              <span :class="sizeText">Caixa</span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/agenda">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'agenda' ? 'activeNavItems' : ''
              }`"
            >
              <i>
                <b-icon-calendar2-week scale="1.3"></b-icon-calendar2-week
              ></i>
              <span :class="sizeText">Agenda</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/relatorios">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'relatorios' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-printer scale="1.3"></b-icon-printer></i>
              <span :class="sizeText">Relatórios</span>
            </button>
          </router-link>
        </li>
        <li>
          <router-link to="/configuracoes">
            <button
              @click="sidBarMobileExpand"
              :class="`${iconsCenter} contentLink ${
                navItemSelected === 'configuracoes' ? 'activeNavItems' : ''
              }`"
            >
              <i> <b-icon-gear scale="1.3"></b-icon-gear></i>
              <span :class="sizeText">Configurações</span>
            </button>
          </router-link>
        </li>
      </ul>

      <div class="containerBtnSair">
        <ul class="nav-links">
          <li>
            <router-link to="/">
              <button @click="exit" :class="`${iconsCenter} contentLink`">
                <i> <b-icon-door-open scale="1.3"></b-icon-door-open></i>
                <span :class="btnSair">Sair</span>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import logoBms from "../../assets/LogoBms.png";
import InputSelec from "../UI/CustomInputs/InputSelect.vue";
import { clearTokens } from "../../utils/clearTokens";
import serviceUser from "../../services/serviceUser";
import planBlack from "../../assets/planBlack.png";
import YtLogo from "../../assets/YtLogo.png";
import Instagram from "../../assets/Instagram.png";
import api from "../../services/axios";
import Tabs from "../UI/Tabs/Tab.vue";

export default {
  components: {
    InputSelec,
    Tabs,
  },
  data() {
    return {
      logoBms: logoBms,
      planBlack: planBlack,
      YtLogo: YtLogo,
      Instagram: Instagram,
      colapse: true,
      collapsed: true,
      collapsedMobile: true,
      spinGeneral: false,
      disable: "",
      alternClassMobile: "disableMobile",
      sizeText: "link_name",
      iconsCenter: "btnsGerais",
      btnSair: "btnSair",
      sideOCompany: "sideCompanyDisable",
      contentSideOCompany: "disabledContent",
      companies: [],
      tokenCompanySelected: null,
      user: {},
      isSideCommonOpen: false,
      objectPlan: {},
      selectedLabel: "",
      tabSelected: 0,
      tabs: [
        { name: "videos", text: "Videos" },
        { name: "tributario", text: "Guia tributário" },
      ],
      pdfLink:
        "https://nfe-web-termo.s3.sa-east-1.amazonaws.com/ContratoUsuario.pdf",
    };
  },
  methods: {
    handleCompanySelected() {
      localStorage.setItem("token", this.tokenCompanySelected);
      window.location.reload();
    },
    storeLabelName(labelName) {
      this.selectedLabel = labelName;
    },
    redirectServiceAddWhatsApp() {
      const serviceMessage = encodeURIComponent(
        `Quero contratar o serviço: ${this.selectedLabel}`
      );
      const phoneNumber = "3138919414";

      const url = `https://wa.me/${phoneNumber}?text=${serviceMessage}`;
      window.open(url, "_blank");
    },
    openContract() {
      window.open(this.pdfLink, "_blank");
    },
    sidBarExpand() {
      this.disable = this.disable !== "disable" ? "disable" : "";
      this.colapse = !this.colapse;
      this.sizeText = this.colapse ? "link_name" : "link_nameTwo";
      this.iconsCenter = this.colapse ? "btnsGerais" : "btnsGeraisTwo";
      this.btnSair = this.colapse ? "btnSair" : "btnSairTwo";
      if (this.colapse) this.$store.commit("REGISTER_WIDTH", "80%");
      else this.$store.commit("REGISTER_WIDTH", "90%");
    },
    sidBarMobileExpand() {
      this.collapsedMobile = !this.collapsedMobile;

      this.alternClassMobile =
        this.alternClassMobile !== "sidebarMobile"
          ? "sidebarMobile"
          : "disableMobile";
      this.sizeText = this.collapsedMobile ? "link_name" : "link_nameTwo";
      this.iconsCenter = this.collapsedMobile ? "btnsGerais" : "btnsGeraisTwo";
      this.btnSair = this.collapsedMobile ? "btnSair" : "btnSairTwo";
    },
    sideOpenCompany() {
      this.collapsed = !this.collapsed;
      this.sideOCompany = this.collapsed ? "sideCompany" : "sideCompanyDisable";
      this.contentSideOCompany = this.collapsed ? "" : "disabledContent";
      this.isSideCommonOpen = !this.isSideCommonOpen;
    },

    handleClickOutside(event) {
      const sideCommonElement = this.$el.querySelector(".sideCompany");

      if (
        sideCommonElement &&
        !sideCommonElement.contains(event.target) &&
        !event.target.classList.contains("buttonToOpenSideCommon")
      ) {
        this.isSideCommonOpen = false;
        this.collapsed = false;
        this.sideOCompany = "sideCompanyDisable";
        this.contentSideOCompany = "";
      }
    },

    sideOpenAdnFindUser() {
      this.sideOpenCompany();
      this.handleFindUser();
    },
    exit() {
      clearTokens();
    },
    redirectWhatsApp() {
      const phoneNumber = "3138919414";
      const whatsappUrl = `https://wa.me/${phoneNumber.replace(/\D/g, "")}`;
      window.open(whatsappUrl, "_blank");
    },
    redirectNewPlanWhatsApp() {
      const serviceMessage = encodeURIComponent(
        "Olá , tenho interesse em trocar meu plano no Gestão Web!"
      );
      const phoneNumber = "3138919414";

      const url = `https://wa.me/${phoneNumber}?text=${serviceMessage}`;
      window.open(url, "_blank");
    },

    async findPlan() {
      const data = await api.get("/plans/verify");
      this.objectPlan = data.data;
    },

    async handleFindUser() {
      try {
        this.spinGeneral = true;
        this.user = await serviceUser.findUnserInGestao();
      } catch (error) {
        console.log(error);
      } finally {
        this.spinGeneral = false;
      }
    },

    handleTokenList() {
      const tokens = JSON.parse(localStorage.getItem("tokenList"));
      const token = localStorage.getItem("token");
      if (tokens && tokens.length) {
        this.companies = tokens;
        const companySelected = tokens.find((item) => item.token === token);
        this.$nextTick(() => {
          this.tokenCompanySelected = companySelected.token;
          this.$refs.companies.valueItems = companySelected.token;
        });
      }
    },
  },
  computed: {
    ...mapState([
      "navItemSelected",
      "dataCompany",
      "numberOfNewMessages",
      "dataPlan",
      "enableMtq",
    ]),
    handleVerifyUserIsAccountant() {
      const userLevel = localStorage.getItem("userLevel");
      if (userLevel === "accountant") {
        return true;
      } else {
        return false;
      }
    },
    userIsAdm() {
      const userLevel = localStorage.getItem("userLevel");
      if (userLevel === "adm") {
        return true;
      } else {
        return false;
      }
    },
    pointRecordsIsActive() {
      const permissions = localStorage.getItem("permissions");
      const userLevel = localStorage.getItem("userLevel");
      const registerIsActive =
        JSON.parse(permissions)?.configuracoes?.registroPonto?.painelPrincipal;

      if (permissions && registerIsActive && userLevel !== "adm") return false;

      return true;
    },
    enableSelectCompany() {
      const tokens = localStorage.getItem("tokenList");
      if (tokens && tokens.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.sidBarExpand();
    this.handleTokenList();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:500,500,500");

.sidebar {
  position: fixed !important;
  z-index: 1000;
  top: 50px;
  height: 100vh;
  max-height: calc(100vh - 50px);
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #3a4750;
  transition: all 0.5s ease;
  /* overflow-y: overlay;
  overflow-x: hidden; */
}

.disable {
  position: fixed !important;
  z-index: 1000;
  top: 50px;
  height: calc(100vh - 50px);
  width: 60px;
  background: #3a4750;
  transition: all 0.5s ease;
}

.disableMobile {
  display: none;
}

.sideMobile {
  display: none;
}

.sidebarMobile {
  display: none;
}

.nav-links {
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-links::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.nav-links::-webkit-scrollbar-track {
  background: #3a4750;
}

.nav-links::-webkit-scrollbar-thumb {
  background-color: #687e8f;
  border-radius: 0;
}

.sidebar .nav-links li {
  height: 40px;
  width: 100%;
  list-style: none;
}

.sidebar .nav-links li .contentLink {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .contentLink:hover {
  background: #768593;
}

.sidebar .nav-links li .contentLink i {
  min-width: 55px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.sidebar .nav-links li .contentLink .link_name {
  color: #fff;
  font-size: 13px;
  transition: all 0.4s ease;
  overflow: hidden;
}

.sidebar .nav-links li .contentLink .link_nameTwo {
  color: #fff;
  font-size: 0px;
  display: none;
  transition: all 0.4s ease;
  overflow: hidden;
}

.sidebar .nav-links li .contentLink .droperText {
  color: #fff;
  font-size: 12px;
  transition: all 0.4s ease;
}

li a {
  margin-top: 5px;
  text-decoration: none !important;
}

ul {
  margin-bottom: 0 !important;
}

#logoEmpresaTwo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid white;
  object-fit: contain;
  transition: all 0.4s ease;
}

.nomeEmpresaText {
  font-size: 28px;
  transition: all 0.4s ease;
}

.nomeEmpresaTextTwo {
  font-size: 0px;
  transition: all 0.4s ease;
}

.containerBtnSair {
  /* position: absolute; */
  /* bottom: 70px; */
  width: 100%;
  background: #435561;
}

.btnSair {
  bottom: 0;
  color: white;
  font-size: 14px;
  transition: all 0.4s ease;
}

.btnSairTwo {
  display: none;
  bottom: 0;
  color: white;
  font-size: 0px;
  transition: all 0.4s ease;
}

span {
  cursor: pointer;
}

.iconEffect {
  display: none !important;
}

.iconEffect:focus i {
  background-color: #dcdcdc50 !important;
  transform: rotate(180deg) !important;
}

.spin {
  height: 20px;
  width: 20px;
  color: gray;
}

.userContent {
  display: flex;
  text-transform: capitalize;
}

.activeNavItems {
  background-color: #344048 !important;
}

.btnsGeraisTwo {
  justify-content: center !important;
  outline: none !important;
  border: none !important;
  color: white;
}

.btnsGerais {
  outline: none !important;
  border: none !important;
}

button {
  background-color: transparent;
  padding: 5px;
}

.navBar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0px 0px 5px 0px rgb(106, 102, 102);
}

.contentLogoAndTitle {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 100px;
  font-size: 22px;
}

.contentHelp {
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
  padding: 2px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  z-index: 2;
}

.contentHelp:hover {
  background-color: #c9c9c9;
}

.contentHelp div {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.iconHelper {
  width: 70px;
  height: 29px;
  color: #313b42;
  z-index: 1000;
  padding: 0.3rem 20px;
}

.sideCompany {
  display: flex;
  position: absolute;
  width: 280px;
  height: 65vh;
  background: white;
  right: 10px;
  top: 60px;
  border: none;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  gap: 15px;
  box-shadow: 0px 1px 4px 1px rgba(142, 142, 142, 0.78);
  /* animation: example 3s; */
}

.sideCommon {
  transition: ease-in all 0.3s;
  /* animation: example 3s; */
}

.sideCompanyDisable {
  height: 0;
  visibility: hidden;
  display: none;
}

.disabledContent {
  height: 0;
  visibility: hidden;
  /* animation: example 3s; */
}

@keyframes example {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

.closeSize {
  color: rgb(134, 134, 134);
  display: flex;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  position: absolute;
  left: 10px;
  top: 5px;
}

.logoSide {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.btnModal-contract {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentPlan {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
  padding: 5px 20px;
}

.contentContract {
  height: 85vh;
  position: relative;
}

.titleContract {
  font-size: 23px;
  text-align: center;
}

.textContract {
  text-align: justify;
  font-size: 12px;
  height: 70vh;
  max-height: 70vh;
  overflow: auto;
  padding: 0 50px;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.contentContractPlan {
  height: 60vh;
}

.bodyPlan {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.myPlan {
  display: flex;
  border: 2px solid #c7c7c7;
  border-radius: 6px;
  padding: 13px;
  justify-content: space-between;
}

.myPlanTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myPlanTitle span {
  font-size: 14px;
  font-weight: bold;
}

.imgMyPlan {
  width: 120px;
  height: 120px;
}

.myPlanDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
}

.myPlanDescriptionItem {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color: rgb(137 137 137);
  gap: 8px;
  display: flex;
  align-items: center;
}

.myPlanDescriptionItemIcon {
  color: #3e66ae;
}

.myPlanAlter {
  display: flex;
  align-items: flex-end;
}

.addServicePlan {
  display: flex;
  height: 170px;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #c7c7c7;
  border-radius: 6px;
  padding: 5px 13px;
}

.addServicePlanTitle {
  font-size: 14px;
  font-weight: bold;
}

.addServicePlanContent {
  font-size: 11px;
  color: rgb(165, 165, 165);
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 0 0;
}

.addServiceBtnPlan {
  display: flex;
  justify-content: flex-end;
}

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
}

.containerRadio label input:checked + span {
  background-color: #f3f2f2;
  color: black;
  font-weight: bold;
}

.containerRadio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00486e;
}

.containerRadio label span {
  display: flex;
  align-items: center;
  color: black;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 2px;
  transition: 0.25s ease;
}

.containerRadio label span:hover {
  background-color: #e6e6e6;
}

.containerRadio label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.1em #00486e;
}

.headerSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px 0 10px 0;
  gap: 6px;
  border-bottom: 2px solid #c3c3c3;
}

.contentSide {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.contentSideDown {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 10px;
  gap: 5px;
}

.contentSideBtn {
  border: none;
  border-radius: 3px;
  font-size: 13px;
  color: #403b74;
  padding: 5px 20px;
  width: 100%;
}

.contentSideBtn:hover {
  background: rgb(219, 219, 219);
}

.btnExitIsAccountant {
  color: white;
  padding: 7px;
  background-color: rgb(62, 102, 174);
  width: 70px;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.1rem;
  transition: ease-in all 0.1s;
  font-weight: 500 !important;
}

.btnExitIsAccountant:hover {
  background-color: rgb(52, 85, 145);
  transition: ease-in all 0.1s;
}

.nameCompany {
  color: #313b42;
  font-size: 16px;
}

.nameCompany span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentSelectCompany {
  width: 25%;
  color: #313b42;
  font-size: 16px;
}

.contentSelectCompany span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.textAccountant {
  color: #313b42;
  font-size: 18px;
}

.textAccountant span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentHelpAndNotificationAccounting {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.contentHelpAndNotificationAccounting a {
  text-decoration: none;
  outline: none;
  color: #313b42;
}

.contentHelpAndNotificationAccounting a:hover {
  text-decoration: none;
  outline: none;
  color: black;
}

.contentNotificationAccounting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: #eeeeee;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
  padding: 0.3rem;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.contentNotificationAccounting:hover {
  background-color: #c9c9c9;
}

.scrollTrib {
  height: 60vh;
}

.containerYt {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentYt {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #f1f1f1;
  padding: 5px 10px 0 10px;
}

.itemYt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  height: 80px;
  gap: 20px;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 8px;
}

.imgYt {
  width: 45px;
  height: 25px;
}

.descriptionYt {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contentLogosocialMedia {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.socialMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.numberMessages {
  width: 18px;
  height: 18px;
  position: absolute;
  top: -4px;
  right: -5px;
  background-color: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.357);
}

p {
  margin: 0 !important;
}

.warningExpiredPlan {
  position: absolute;
  top: -7px;
  right: -3px;
}

.contentBtnMyPlan {
  position: relative;
}

.warningExpiredPlanToModal {
  position: absolute;
  right: 60px;
}

.containerMobile {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .sidebar .nav-links li {
    height: 35px;
  }

  .sidebar {
    justify-content: start;
  }

  .disable {
    width: 50px;
  }

  .contentLogoAndTitle {
    gap: 30px;
    font-size: 20px;
  }

  .nameCompany {
    font-size: 14px;
  }

  .contentNotificationAccounting {
    font-size: 11px;
  }

  .contentHelpAndNotificationAccounting {
    gap: 1rem;
  }

  .contentHelp {
    padding: 0px;
  }
}

@media screen and (max-width: 700px) {
  .navBar {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .containerMobile {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
  }

  .sideMobile {
    display: flex;
    position: absolute;
    right: 0;
    top: 80px;
  }

  .sidebarMobile {
    position: fixed !important;
    z-index: 1000;
    top: 55px;
    right: 20px;
    height: 100vh;
    max-height: calc(100vh - 80px);
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #3a4750ed;
    border-radius: 10px;
    transition: all 0.5s ease;
    overflow-y: overlay;
    overflow-x: hidden;
  }

  .disableMobile {
    position: fixed !important;
    z-index: 1000;
    top: 50px;
    height: calc(100vh - 50px);
    width: 0px;
    visibility: hidden;
    background: #3a4750;
    transition: all 0.5s ease;
  }

  .sidebarMobile .nav-links li {
    height: 40px;
    width: 100%;
    list-style: none;
  }

  .sidebarMobile .nav-links li .contentLink {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
  }

  .sidebarMobile .nav-links li .contentLink:hover {
    background: #768593b5;
  }

  .sidebarMobile .nav-links li .contentLink i {
    min-width: 55px;
    text-align: center;
    color: #fff;
    font-size: 1rem;
  }

  .sidebarMobile .nav-links li .contentLink .link_name {
    color: #fff;
    font-size: 1rem;
    transition: all 0.4s ease;
    overflow: hidden;
  }

  .sidebarMobile .nav-links li .contentLink .droperText {
    color: #fff;
    font-size: 1rem;
    transition: all 0.4s ease;
  }
}
</style>