import { TypeSale } from "../utils/enums/SalesType"

export const formatValuesFromSales = (typeDocument, data) => {
  if (typeDocument === TypeSale.frenteDeCaixa) {
    return boxFront(data)
  } else if (typeDocument === TypeSale.venda) {
    return sale(data)
  } else if (typeDocument === TypeSale.orcamento) {
    return budget(data)
  } 
}

const boxFront = (data) => {
  const newDataBoxfront = {
    ...data
  }

  Reflect.deleteProperty(newDataBoxfront, "idEmpresa")
  Reflect.deleteProperty(newDataBoxfront, "createdAt")
  Reflect.deleteProperty(newDataBoxfront, "updatedAt")
  Reflect.deleteProperty(newDataBoxfront, "numero")
  Reflect.deleteProperty(newDataBoxfront, "observacoesNf")

  if (newDataBoxfront.products && newDataBoxfront.products.length) {
    newDataBoxfront.products.forEach((dataBox) => {
      Reflect.deleteProperty(dataBox, "metragem")
    })
  }

  return newDataBoxfront
}


const sale = (data) => {
  const newDataSale = { ...data }

  Reflect.deleteProperty(newDataSale, 'idEmpresa')
  Reflect.deleteProperty(newDataSale, 'createdAt')
  Reflect.deleteProperty(newDataSale, 'updatedAt')

  if (newDataSale.bills && newDataSale.bills.length) {
    newDataSale.bills.forEach((bill) => {
      Reflect.deleteProperty(bill, 'idEmpresa')
      Reflect.deleteProperty(bill, 'idFornecedor')
      Reflect.deleteProperty(bill, 'idNota')
    })
  }

  if (newDataSale.products && newDataSale.products.length) {
    newDataSale.products.forEach((product) => {
      Reflect.deleteProperty(product, 'idEmpresa')
      Reflect.deleteProperty(product, 'nome')
      Reflect.deleteProperty(product, 'idFornecedor')
      Reflect.deleteProperty(product, 'idNota')
      Reflect.deleteProperty(product, 'tipo')
    })
  }

  return newDataSale
}

const budget = (data) => {
  const newDataBudget = { ...data }
  Reflect.deleteProperty(newDataBudget, 'idEmpresa')
  Reflect.deleteProperty(newDataBudget, 'createdAt')
  Reflect.deleteProperty(newDataBudget, 'updatedAt')

  if (newDataBudget.products && newDataBudget.products.length) {
    newDataBudget.products.forEach((product) => {
      Reflect.deleteProperty(product, 'idEmpresa')
      Reflect.deleteProperty(product, 'nome')
      Reflect.deleteProperty(product, 'idFornecedor')
      Reflect.deleteProperty(product, 'idNota')
      Reflect.deleteProperty(product, 'tipo')
    })
  }

  return newDataBudget
}