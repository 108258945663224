import Vue from "vue";
import Router from "vue-router";
import Home from "../src/pages/Home/Page-Home.vue";
import Schedule from "../src/pages/Schedule/Page-Schedule.vue";
import Cashier from "../src/pages/Cashier/Page-Cashier.vue";
import Purchase from "../src/pages/Purchase/Page-Purchase.vue"
import Login from "../src/pages/Login/Page-Login.vue"
import Settings from "./pages/Settings/Page-Settings.vue"
import Budget from "./pages/Budget/Page-Budget.vue"
import GeneralSalesScreen from "../src/pages/GeneralSalesScreen/Page-GeneralSalesScreen.vue";
import ScreenMeters from "../src/pages/ScreenMeters/Page-ScreenMeters.vue"
import Reports from "../src/pages/ReportsGeneral/Page-Reports.vue";
import Stock from "../src/pages/Stock/Page-Stock.vue";
import FinancialMovements from "../src/pages/FinancialMovements/Page-FinancialMovements.vue";
import Registers from "../src/pages/Registers/Page-Registers.vue";
import FiscalIssuance from "../src/pages/FiscalIssuance/Page-FiscalIssuance.vue";
import OrderOfService from "../src/pages/OrderOfService/Page-OrderOfService.vue";
import Accounting from "../src/pages/Accounting/Page-Accounting.vue"
import PageNotFoundPlan from "../src/pages/Plan/NotFoundPlan/Page-Not-Found-Plan.vue"
import PointRecords from "../src/pages/PointRecords/Page-PointRecords.vue"
import PointControl from "../src/pages/PointControl/Page-PointControl.vue"

import store from "./store"

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    name: "home",
    meta: {
      auth: true,
    },
  },
  {
    path: "/agenda",
    component: Schedule,
    name: "agenda",
    meta: {
      auth: true
    }
  },
  {
    path: "/cadastros",
    component: Registers,
    name: "cadastros",
    meta: {
      auth: true
    }
  },
  {
    path: "/fiscal",
    component: FiscalIssuance,
    name: "fiscal",
    meta: {
      auth: true
    }
  },
  {
    path: "/orcamento",
    component: Budget,
    name: "orcamento",
    meta: {
      auth: true
    }
  },
  {
    path: "/vendas",
    component: GeneralSalesScreen,
    name: "vendas",
    meta: {
      auth: true
    }
  },
  {
    path: "/vendas-mt",
    component: ScreenMeters,
    name: "vendasMt",
    meta: {
      auth: true
    }
  },
  {
    path: "/ordem",
    component: OrderOfService,
    name: "ordem",
    meta: {
      auth: true
    }
  },
  {
    path: "/financeiro",
    component: FinancialMovements,
    name: "financeiro",
    meta: {
      auth: true
    }
  },
  {
    path: "/financeiro/:id",
    component: FinancialMovements,
    meta: {
      auth: true
    }
  },
  {
    path: "/caixa",
    component: Cashier,
    name: "caixa",
    meta: {
      auth: true
    }
  },
  {
    path: "/compras",
    component: Purchase,
    name: "compras",
    meta: {
      auth: true
    }
  },
  {
    path: "/configuracoes",
    component: Settings,
    name: "configuracoes",
    meta: {
      auth: true
    }
  },
  {
    path: "/relatorios",
    component: Reports,
    name: "relatorios",
    meta: {
      auth: true
    }
  },
  {
    path: "/estoque",
    component: Stock,
    name: "estoque",
    meta: {
      auth: true
    }
  },
  {
    path: "/area-contador",
    component: Accounting,
    name: "Accounting",
    meta: {
      auth: true
    }
  },
  {
    path: "/plan-not-found",
    component: PageNotFoundPlan,
    name: "PageNotFoundPlan",
    meta: {
      auth: true
    }
  },
  {
    path: "/registro-ponto",
    component: PointRecords,
    name: "registro-ponto",
    meta: {
      auth: true
    }
  },
  {
    path: "/controle-ponto",
    component: PointControl,
    name: "controle-ponto",
    meta: {
      auth: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "home" },
  },
];

const router = new Router({
  mode: "history",
  routes,
});


router.beforeEach((to, from, next) => {
  store.commit("SET_NAV_ITEM_SELECTED", to.matched[0].name)

  handlePermissions(to, next)
  handleRoutesRedirection(to, next)
});


function handlePermissions(to, next) {
  const permissions = JSON.parse(localStorage.getItem('permissions') || null)
  const key = to.path.replace('/', '')

  if (permissions && permissions[key] && key !== "configuracoes" && key !== "agenda") {
    permissions[key].painelPrincipal ? next() : next({ path: "/home" });
  } else {
    handlePermissionsOrderOrFiscal(next, key, permissions)
  }
}

function handlePermissionsOrderOrFiscal(next, key, permissions) {
  if (key === "ordem" && permissions)
    permissions.ordensDeServico.painelPrincipal ? next() : next({ path: "/home" });
  else if (key === "fiscal" && permissions)
    permissions.emissaoFiscal.painelPrincipal ? next() : next({ path: "/home" })
  else
    return next()
}

function handleRoutesRedirection(to, next) {
  if (to.path == "/") {
    return next();
  }

  if (to.matched[0].meta.auth) {
    !localStorage.getItem("token") ? next({ path: "/" }) : next()
  }

  if (to.matched[0].meta.auth) {
    if (localStorage.getItem("userLevel") === "accountant")
      next({ path: "/area-contador" })
    else if (to.path === "/controle-ponto" && localStorage.getItem("userLevel") !== "adm") {
      next({ path: "/home" })
    }

    next()
  }


  if (store.state.dataPlan.ativo && store.state.dataPlan.tipoPlano !== "gestao_simples" && to.path === "/plan-not-found") {
    next({ path: "/home" })
  }

  if (!store.state.dataPlan.ativo && to.path !== "/cadastros" && to.path !== "/home" && to.path !== "/") {
    next({ path: "/plan-not-found" })
  }

  if ((store.state.dataPlan.ativo && store.state.dataPlan.tipoPlano === "gestao_simples") && to.path === "/area-contador") {
    next({ path: "/plan-not-found" })
  }
}

export default router;
