<template>
  <div class="containerGeralNfe">
    <div class="title">
      <b-icon-file-earmark-medical></b-icon-file-earmark-medical>
      <span> Nota Fiscal</span>
    </div>
    <div class="rowContent" style="margin-bottom: 20px">
      <div class="btnsContainer">
        <b-button class="btnsSave" @click="openModals('modalTaxInformation')">
          + Nova Ref. Fiscal
        </b-button>
      </div>
      <div class="additionalButtonContent">
        <div class="containerCheckBox">
          <b-form-group
            style="margin-bottom: 1rem"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox
              :aria-describedby="ariaDescribedby"
              value="0"
              unchecked-value="1"
              class="chkSaidaEntrada"
              v-model="dadosNfe.operacao"
              size="sm"
              switch
            >
              <div style="width: 90px; font-size: 12px">
                {{ textoTipoEmissao }}
              </div>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="rowContainer">
      <span>
        <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
        Gerais</span
      >
      <div class="rowContent">
        <b-form-group hidden>
          <b-form-input
            hidden
            type="text"
            size="sm"
            v-model="dadosNfe.id"
          ></b-form-input>
        </b-form-group>

        <InputSelect
          label="Cliente"
          :items="cliente"
          :dataSelect.sync="dadosNfe.idCliente"
          :openCreateModal="() => openModals('modalCreateCustomer')"
          ref="refCliente"
          valueField="id"
          textField="nome"
        />

        <div class="input-group" style="width: 15%">
          <input
            type="datetime-local"
            class="input borderInput"
            v-model="dadosNfe.data_nfe"
          />
          <label class="user-label"> Data de Emissão </label>
        </div>
        <div class="input-group">
          <input
            required
            class="input borderInput"
            v-model="dadosNfe.natureza_operacao"
          />
          <label class="user-label"> Natureza operação </label>
        </div>
        <div class="selectPlusSm">
          <label class="user-label-disabled">Finalidade</label>
          <b-form-select
            class="borderInput shadow-none"
            value-field="value"
            text-field="text"
            v-model="dadosNfe.finalidade"
            :options="finalidade"
          >
          </b-form-select>
        </div>
      </div>
      <div class="rowContent">
        <div class="input-group">
          <input
            class="input borderInput"
            disabled
            v-model="responseNfeWebMania.nfe"
          />
          <label class="user-label-disabled"> Nº </label>
        </div>
        <div class="input-group">
          <input
            class="input borderInput"
            disabled
            v-model="responseNfeWebMania.serie"
          />
          <label class="user-label-disabled"> Chave </label>
        </div>
        <div class="input-group">
          <input
            class="input borderInput"
            disabled
            v-model="responseNfeWebMania.chave"
          />
          <label class="user-label-disabled"> Serie </label>
        </div>
        <div class="input-group">
          <input class="input borderInput" disabled v-model="dadosNfe.status" />
          <label class="user-label-disabled"> Status </label>
        </div>
        <div class="selectPlusSm">
          <label class="user-label-disabled">Ambiente</label>
          <b-form-select
            class="borderInput shadow-none"
            value-field="value"
            text-field="text"
            v-model="dadosNfe.ambiente"
            :options="ambiente"
          >
          </b-form-select>
        </div>
      </div>
      <div class="rowContent" v-if="dadosNfe.finalidade == '4'">
        <div class="input-group">
          <input
            class="input borderInput"
            v-model="dadosNfe.nfe_referenciada"
          />
          <label class="user-label"> Chave de Referência </label>
        </div>
      </div>
    </div>
    <b-row
      class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex mt-3 contentDataNfeRow"
    >
      <b-form-group
        label="REF Fiscal"
        class="col-sm-6 col-md-6 col-lg-6 col-xl-6"
        v-if="(dadosNfe.finalidade == '4') & (dadosNfe.operacao == '0')"
      >
        <b-form-select
          size="sm"
          value-field="ref"
          text-field="descricao"
          v-model="classeImpostoDevolucao"
          :options="allRefsFiscaisFromSelectBox"
        ></b-form-select>
      </b-form-group>
    </b-row>

    <b-card-text
      class="mt-3"
      :hidden="
        (dadosNfe.finalidade == '4') & (dadosNfe.operacao == '0') ? true : false
      "
    >
      <div>
        <b-navbar toggleable class="cardDadosNfe">
          <b-navbar-toggle
            target="navbar-toggle-collapse-produtos"
            id="dadosNfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div @click="clickProductAndSave" class="tamanhoBotaoOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 13px">
                  Adicionar Produto
                </div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-produtos"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <b-card class="mt-2 cardDadosNfeBorda">
                <div class="rowContent">
                  <InputSelect
                    label="Produtos"
                    :items="produtos"
                    :dataSelect.sync="produtosNotaFiscal.idProduto"
                    valueField="id"
                    textField="nome"
                    ref="refProductsManage"
                    @changed="assignValuesToTheSelectedProduct"
                    :openCreateModal="
                      () =>
                        handleModalCreateProduct(
                          'modalCreateProduct',
                          produtosNotaFiscal.idProduto
                        )
                    "
                  />

                  <!-- <div class="selectPlus">
                    <label class="user-label-disabled">Produtos</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="id"
                      text-field="nome"
                      v-model="produtosNotaFiscal.idProduto"
                      :options="produtos"
                      @change="assignValuesToTheSelectedProduct"
                    >
                    </b-form-select>
                    <div class="iconSelectBarCode" v-b-modal.modal-1>
                      <div role="button">
                        <b-icon-plus scale="2"></b-icon-plus>
                      </div>
                    </div>
                  </div> -->
                  <div class="selectPlus">
                    <label class="user-label-disabled">REF Fiscal</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="ref"
                      text-field="descricao"
                      v-model="produtosNotaFiscal.classe_imposto"
                      :options="allRefsFiscaisFromSelectBox"
                    >
                    </b-form-select>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="produtosNotaFiscal.codigo"
                      disabled
                    />
                    <label class="user-label-disabled"> Cod. Ref </label>
                  </div>
                  <div class="input-group">
                    <input
                      disabled
                      class="input borderInput"
                      v-model="produtosNotaFiscal.unidade"
                    />
                    <label class="user-label-disabled"> Unidade </label>
                  </div>
                </div>
                <div class="rowContent">
                  <InputQuant
                    label="Quant."
                    type="text"
                    :dataInput.sync="produtosNotaFiscal.quantidade"
                    @changed="formatTotalProductValue"
                    :decimalPlaces="decimalPlaces"
                    ref="quantidade"
                  />
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      size="sm"
                      :value="maskCurrency(produtosNotaFiscal.subtotal)"
                      @input="handleSubtotalMonetaryValue"
                      @keyup="formatTotalProductValue"
                    />
                    <label class="user-label"> Valor Unitário </label>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      @change="calculateDiscountProdutos"
                      :value="maskCurrency(produtosNotaFiscal.desconto)"
                      @input="handleDiscountProductsValue"
                    />
                    <label class="user-label"> Desconto </label>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      :value="
                        handleTotalproductsValue(produtosNotaFiscal.total)
                      "
                    />
                    <label class="user-label"> Valor Total </label>
                  </div>
                </div>
                <div class="rowContent">
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="produtosNotaFiscal.informacoes_adicionais"
                    />
                    <label class="user-label"> Dados Adicionais </label>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled">Origem</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      v-model="produtosNotaFiscal.origem"
                      :options="origem"
                    >
                    </b-form-select>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="produtosNotaFiscal.peso"
                    />
                    <label class="user-label"> Peso (KG) </label>
                  </div>
                  <b-button class="btnsSave" @click="saveOrUpdateNotaItems"
                    >Adicionar</b-button
                  >
                </div>

                <div class="ContentTable">
                  <table style="width: 100%">
                    <thead
                      style="
                        background-color: #3a4750 !important;
                        color: whitesmoke;
                        text-align: center;
                        height: 27px;
                      "
                    >
                      <tr>
                        <th>Nome Produto</th>
                        <th>Quantidade</th>
                        <th>Vl. unitario</th>
                        <th>Vl. total</th>
                        <th>Desconto</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody style="color: #3a4750; width: 100%">
                      <tr
                        v-for="notaItem in produtosForTable"
                        :key="notaItem.id"
                        style="text-align: center; height: 27px"
                      >
                        <td>{{ notaItem.nome }}</td>
                        <td>{{ notaItem.quantidade }}</td>
                        <td>
                          {{
                            notaItem.subtotal.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                        </td>
                        <td>
                          {{
                            notaItem.total.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                        </td>
                        <td>
                          {{
                            notaItem.desconto
                              ? notaItem.desconto.toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : "0,00"
                          }}
                        </td>
                        <td>
                          <b-button
                            size="sm"
                            variant="light"
                            :disabled="
                              responseNfeWebMania.status === 'aprovado' ||
                              responseNfeWebMania.status === 'processamento'
                                ? true
                                : false
                            "
                            @click="findItemById(notaItem.id)"
                            v-b-popover.hover.right="{
                              variant: 'secondary',
                              content: 'Editar',
                            }"
                          >
                            <b-icon-pencil-fill></b-icon-pencil-fill
                          ></b-button>
                          <b-button
                            size="sm"
                            variant="light"
                            style="color: red"
                            :disabled="
                              responseNfeWebMania.status === 'aprovado' ||
                              responseNfeWebMania.status === 'processamento'
                                ? true
                                : false
                            "
                            @click="deleteProductFromNote(notaItem.id)"
                            v-b-popover.hover.right="{
                              variant: 'secondary',
                              content: 'Excluir',
                            }"
                          >
                            <b-icon-x-circle></b-icon-x-circle
                          ></b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>

    <b-card-text
      class="mt-3"
      :hidden="
        (dadosNfe.finalidade == '4') & (dadosNfe.operacao == '0') ? true : false
      "
    >
      <div>
        <b-navbar toggleable class="cardDadosNfe">
          <b-navbar-toggle
            target="navbar-toggle-collapse-pedido"
            id="dadosNfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div class="tamanhoBotaoOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 13px">
                  Dados Pedido
                </div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-pedido"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <b-card class="mt-2 cardDadosNfeBorda">
                <div class="rowContent">
                  <div class="selectPlus">
                    <label class="user-label-disabled">Frete</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      @change="enableFreightage"
                      v-model="dadosNfe.modalidade_frete"
                      :options="modalidade_frete"
                    >
                    </b-form-select>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled"
                      >Condição de Pagamento</label
                    >
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      v-model="dadosNfe.pagamento"
                      :options="pagamento"
                    >
                    </b-form-select>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled"
                      >Forma de Pagamento</label
                    >
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      v-model="dadosNfe.forma_pagamento"
                      :options="forma_pagamento"
                    >
                    </b-form-select>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled">Presença</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      v-model="dadosNfe.presenca"
                      :options="presenca"
                    >
                    </b-form-select>
                  </div>
                </div>
                <div class="rowContent">
                  <div class="input-group">
                    <b-form-input
                      class="input borderInput"
                      :value="maskCurrency(dadosNfe.frete)"
                      @input="handleFreteMonetaryValue($event)"
                      @change="calculateTotalAndTotalDiscount"
                    />
                    <label class="user-label"> Valor do Frete </label>
                  </div>
                  <div class="input-group">
                    <b-form-input
                      class="input borderInput"
                      :value="maskCurrency(dadosNfe.despesas_acessorias)"
                      @input="handleExpensesMonetaryValue($event)"
                      @change="calculateTotalAndTotalDiscount"
                    />
                    <label class="user-label"> Outras Despesas </label>
                  </div>
                  <div class="input-group">
                    <b-form-input
                      class="input borderInput"
                      disabled
                      @change="calculateTotalAndTotalDiscount"
                      @keypress="defineMaxLengthDiscount"
                      :value="maskCurrency(dadosNfe.desconto)"
                      @input="handleTotalDiscount"
                    />
                    <label class="user-label-disabled"> Desconto </label>
                  </div>
                  <div class="input-group">
                    <b-form-input
                      class="input borderInput"
                      disabled
                      :value="handleTotalValue(dadosNfe.total)"
                    />
                    <label class="user-label-disabled"> Valor Total </label>
                  </div>
                </div>

                <div class="mt-3">
                  <p class="descriptionObs">Informações complementares</p>

                  <textarea
                    class="inputTextArea"
                    rows="3"
                    max-rows="6"
                    v-model="dadosNfe.informacoes_complementares"
                  />
                </div>

                <div class="rowContent" v-if="exibirDadosIntermediador">
                  <div class="selectPlus">
                    <label class="user-label-disabled">Intermediador</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      v-model="dadosNfe.intermediador"
                      :options="intermediador"
                    >
                    </b-form-select>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="dadosNfe.id_intermediador"
                    />
                    <label class="user-label"> Nome do Intermediador </label>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="dadosNfe.cnpj_intermediador"
                    />
                    <label class="user-label"> CNPJ do Intermediador </label>
                  </div>
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>

    <b-card-text class="mt-3" :hidden="isDisabled">
      <div>
        <b-navbar toggleable class="cardDadosNfe">
          <b-navbar-toggle
            target="navbar-toggle-collapse-transportadora"
            id="dadosNfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div class="tamanhoBotaoOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 12px">
                  Transportadora
                </div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-transportadora"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <b-card class="mt-2 cardDadosNfeBorda">
                <div class="rowContent rowContentShippingCompany">
                  <InputSelect
                    label="Nome da Transportadora"
                    valueField="id"
                    textField="nome"
                    :items="shippingCompanys"
                    :dataSelect.sync="dadosNfe.idTransportadora"
                    labelFixed="true"
                    :openCreateModal="() => openModals('modalShippingCompany')"
                    ref="refShippingCompapy"
                    class="inputNameShippingCompany"
                  />

                  <div class="input-group inputShippingCompanyMd">
                    <b-form-input
                      class="input borderInput"
                      v-model="dadosNfe.volume"
                    />
                    <label class="user-label"> Volume </label>
                  </div>

                  <div class="input-group inputShippingCompanyMd">
                    <b-form-input
                      class="input borderInput"
                      v-model="dadosNfe.especie"
                    />
                    <label class="user-label"> Especie </label>
                  </div>

                  <div class="input-group inputShippingCompanyMd">
                    <b-form-input
                      class="input borderInput"
                      :value="maskToKg(dadosNfe.peso_bruto)"
                      @input="handleGrossWeight($event)"
                    />
                    <label class="user-label"> Peso Bruto </label>
                  </div>

                  <div class="input-group inputShippingCompanyMd">
                    <b-form-input
                      class="input borderInput"
                      :value="maskToKg(dadosNfe.peso_liquido)"
                      @input="handleNetWeight($event)"
                    />
                    <label class="user-label"> Peso Liquido </label>
                  </div>
                </div>

                <div class="rowContent rowContentShippingCompany">
                  <div class="input-group inputShippingCompanySm">
                    <b-form-input
                      class="input borderInput"
                      v-model="dadosNfe.marca"
                    />
                    <label class="user-label"> Marca </label>
                  </div>

                  <div class="input-group inputShippingCompanySm">
                    <b-form-input
                      class="input borderInput"
                      v-model="dadosNfe.numeracao"
                    />
                    <label class="user-label"> Numeração </label>
                  </div>

                  <div class="input-group inputShippingCompanySm">
                    <b-form-input
                      class="input borderInput"
                      v-model="dadosNfe.lacres"
                    />
                    <label class="user-label"> lacres </label>
                  </div>
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>

    <b-card-text
      class="mt-3"
      :hidden="
        (dadosNfe.finalidade == '4') & (dadosNfe.operacao == '0') ? true : false
      "
    >
      <div>
        <b-navbar toggleable class="cardDadosNfe" style="margin-bottom: 100px">
          <b-navbar-toggle
            target="navbar-toggle-collapse-duplicatas"
            id="dadosNfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div class="tamanhoBotaoOpenCardFinaly">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 13px">Duplicatas</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-duplicatas"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav>
              <b-card class="mt-2 cardDadosNfeBordaDupli">
                <div class="rowContent" v-if="!dadosNfe.idVenda">
                  <div class="input-group">
                    <input
                      type="date"
                      class="input borderInput"
                      v-model="duplicates.fistExpiration"
                    />
                    <label class="user-label"> Data do 1º Vencimento </label>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled">Tipo de Pagamento</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="id"
                      text-field="tipo"
                      :options="typePayments"
                      v-model="duplicates.idTypePayment"
                    >
                    </b-form-select>
                  </div>
                  <div class="selectPlus">
                    <label class="user-label-disabled"
                      >Conta de recebimento</label
                    >
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="id"
                      text-field="nome"
                      :options="receiptAccounts"
                      v-model="duplicates.idReceiptAccount"
                    >
                    </b-form-select>
                  </div>
                  <div class="input-group">
                    <input
                      class="input borderInput"
                      v-model="duplicates.dateInterval"
                    />
                    <label class="user-label"> Intervalo de Vencimento </label>
                  </div>
                  <div class="input-group">
                    <input
                      class="input borderInput"
                      v-model="duplicates.installments"
                    />
                    <label class="user-label"> Quantidade de Parcelas </label>
                  </div>
                </div>

                <div class="ContentTable">
                  <table style="width: 100%">
                    <thead
                      style="
                        color: whitesmoke;
                        background: #3a4750;
                        text-align: center;
                        height: 27px;
                      "
                    >
                      <tr>
                        <th>Data vencimento</th>
                        <th>Valor da parcela</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody style="color: #3a4750; width: 100%">
                      <tr
                        style="text-align: center; height: 27px"
                        v-for="bill in billsFromTable"
                        :key="bill.id"
                      >
                        <td>{{ bill.data | formatDate }}</td>
                        <td>{{ maskCurrency(bill.valorTotal) }}</td>
                        <td v-if="!dadosNfe.idVenda">
                          <b-button
                            size="sm"
                            variant="light"
                            style="color: red"
                            :disabled="
                              bill.idVenda || bill.idVendaMtq ? true : false
                            "
                            v-b-popover.hover.right="{
                              variant: 'secondary',
                              content: 'Excluir',
                            }"
                            @click="deleteBill(bill.id)"
                          >
                            <b-icon-x-circle></b-icon-x-circle
                          ></b-button>
                        </td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="rowContent"
                  style="margin-top: 30px"
                  v-if="!dadosNfe.idVenda"
                >
                  <div class="btnsContainer">
                    <b-button
                      style="padding: 5px"
                      class="btnsSaveNeutral"
                      @click="getAllBillsByIdNota"
                      >Fechar</b-button
                    >
                    <b-button
                      class="btnsSave"
                      style="padding: 5px"
                      @click="makingThePayment"
                      >Lançar Pagamento</b-button
                    >
                  </div>
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>
    <div class="rowContentFooter">
      <div class="btnsContainerFooterInit">
        <b-button class="btnsSaveNeutral" @click="clearInputs"
          >Nova Emissão fiscal</b-button
        >
        <b-button
          v-if="this.dadosNfe.id"
          class="btnsSave"
          @click="updateOrSaveNotaFiscal"
          >Atualizar</b-button
        >
        <b-button
          v-if="!this.dadosNfe.id"
          class="btnsSave"
          @click="updateOrSaveNotaFiscal"
          >Salvar</b-button
        >
      </div>
      <div class="btnsContainerFooter">
        <b-button
          class="btnsSave"
          v-if="
            responseNfeWebMania.status === 'aprovado' ||
            responseNfeWebMania.status === 'processamento'
          "
          @click="visualizarNfe"
          >Visualizar</b-button
        >
        <b-button
          class="btnsSave"
          :disabled="handleButtonEmitirNfe"
          @click="handleEmitOrReturnNota"
          >Emitir</b-button
        >
        <b-dropdown
          text="Ações"
          class="btnsAction"
          variant="light"
          style="color: white"
        >
          <template #button-content>
            <b-icon-gear></b-icon-gear>
          </template>
          <b-dropdown-item
            :disabled="
              dadosNfe.status === 'Cancelada' || dadosNfe.status === ''
                ? true
                : false
            "
            @click="openModals('modalCancelNota')"
            >Cancelar
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            :disabled="
              responseNfeWebMania.status === 'aprovado' ||
              responseNfeWebMania.status === 'processamento' ||
              responseNfeWebMania.status === 'cancelado'
                ? true
                : false
            "
            >Inutilizar
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            :disabled="
              dadosNfe.status === 'Não Enviada' ||
              dadosNfe.status === 'Cancelada' ||
              dadosNfe.status === ''
                ? true
                : false
            "
            @click="openModalCorrectionLetter"
            >Carta de correção</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <div>
      <b-modal
        id="modalReturnNota"
        size="lg"
        title="Selecione os produtos que deseja devolver!"
        centered
      >
        <template #modal-footer>
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between"
          >
            <div>
              <b-button
                variant="info"
                :disabled="handleReturnedAmmoutAndAmmount()"
                size="sm"
                @click="sendEntryNotaDevolution"
                >Prosseguir</b-button
              >
            </div>

            <div v-if="spinLoadingDevolucao">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
              ></b-spinner>
            </div>
          </div>
        </template>
        <b-row class="col-sm-12 tableReturnProducts">
          <table class="table table-sm">
            <thead>
              <tr style="background-color: #3a4750; color: white">
                <th>Produto</th>
                <th>Quantidade</th>
                <th>Qnt. Devolvida</th>
                <th>Vl. und</th>
                <th>Vl. total</th>
                <th>Selecionar</th>
              </tr>
              <tr
                v-for="(products, index) in producsReferencedNotaFinal"
                :key="products.id"
              >
                <td class="tdReturnProducts">{{ products.nome }}</td>
                <td class="tdReturnProducts">{{ products.quantidade }}</td>
                <td class="tdReturnProducts">
                  <div
                    class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center"
                  >
                    <b-form-input
                      size="sm"
                      type="number"
                      v-model="producsReferencedNotaFinal[index].qtdDevolvida"
                      class="col-sm-12 col-md-12 col-lg-5 col-xl-5"
                    />
                  </div>
                </td>
                <td class="tdReturnProducts">{{ products.subtotal }}</td>
                <td class="tdReturnProducts">{{ products.total }}</td>
                <td class="tdReturnProducts">
                  <b-form-checkbox
                    name="checkbox-1"
                    v-model="producsReferencedNotaFinal[index].isDevolucao"
                    size="lg"
                    @change="chekedProductsReturnNota(index)"
                    id=""
                  />
                </td>
              </tr>
            </thead>
          </table>
        </b-row>
      </b-modal>
    </div>
    <b-modal id="modal-1" hide-footer hide-header size="lg">
      <div>
        <button class="closeModal" @click="$bvModal.hide('modal-1')">
          <b-icon-x scale="1.3"></b-icon-x>
        </button>
        <Products
          :readOrEditProducts="dataProducts"
          @getAllProducts="getProductsForSelectBox"
        />
      </div>
    </b-modal>
    <ModalCreateCustomer
      :idCustomerFromSearch="dadosNfe.idCliente"
      @getAllNewCustomer="getCliente"
    />
    <ModalCreateProduct
      :idProductFromSearch="idProductFromSearch"
      @getAllNewProduct="getProductsForSelectBox"
    />
    <ModalShippingCompany />
    <ModalCancelNota
      :idNota="{
        uuidNotaWebMania: responseNfeWebMania.uuid,
        idNota: dadosNfe.id,
      }"
      @getNotaAfterCanceled="getNotaAfterModifyNota = $event"
    />
    <ModalCorrectionLetter
      :idNota="dadosNfe.id"
      @getNotaAfterCorrection="getNotaAfterModifyNota = $event"
    />
    <ModalTaxInformation @createdNewRef="createdNewRef = $event" />
  </div>
</template>

<script>
import mixinNotaFiscal from "../../mixins/NotaFiscal/NotaFiscal";

export default {
  mixins: [mixinNotaFiscal],
};
</script>

<style scoped>
.containerGeralNfe {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  margin-bottom: 100px;
}

.conteudoBotaoEmail {
  height: 35px;
  display: flex;
  justify-content: space-between;
}

.conteudoBotaoEmail button:hover {
  background-color: #025f6b !important;
  transition: all 0.3s linear;
}

.conteudoBotaoEmail button {
  margin-left: 16px;
}

.conteudoDadosNfe {
  border-radius: 5px;
  padding: 0 !important;
  margin-top: 10px;
}

.cardDadosNfe {
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(232, 231, 231, 0.788);
  background-color: #f9f9f94d !important;
  padding: 0px 0px 0px 0px !important;
  border-radius: 5px !important;
  width: 100%;
}

.cardDadosNfeBorda {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
}

.iconSelectBarCode {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: black;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}
.cardDadosNfeBordaDupli {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 80px;
}

.tamanhoCards {
  width: 100%;
  display: flex;
}

.dropdown-menu {
  display: none;
  font-size: 12px;
  color: #212529;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
  height: 20px;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

td {
  text-transform: capitalize;
}

.tamanhoBotaoOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}

.tamanhoBotaoOpenCardFinaly {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}

.chkSaidaEntrada {
  margin-top: 18px;
}

.containerCheckBox {
  display: flex;
  height: 30.1px !important;
  width: 125px !important;
  align-items: center !important;
  border: solid 1px rgb(216, 215, 215);
  border-radius: 5px;
  padding-left: 15px !important;
  margin-right: 16px;
}

.iconCadTransportadora {
  display: flex;
  justify-content: space-between;
}

.btnCadTransportadora {
  cursor: pointer;
}

.tableReturnProducts {
  margin-top: 31px;
  overflow: auto !important;
  max-height: 300px;
  text-align: center;
}

.tdReturnProducts {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

#dadosNfeStyle {
  border: none !important;
}

.btnFormaPagamento {
  cursor: pointer;
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

@media screen and (max-width: 438px) {
  .contentDataNfeRow {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 388px) {
  .additionalButtonContent {
    display: flex;
    flex-direction: column;
  }
  .conteudoBotaoEmail {
    height: 30px !important;
  }
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 10;
}

.user-label-sm {
  position: absolute;
  left: 130px;
  font-size: 11px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.selectPlusSm {
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
}

.user-label-disabled {
  position: absolute;
  left: 10px;
  font-size: 9px;
  bottom: 22px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: #ffffff;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  box-shadow: none !important;
  border: 1px solid #505050;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentFooter {
  display: flex;
  width: 80vw;
  padding: 15px 10px 30px 10px;
  justify-content: space-between;
  gap: 10px;
  height: 60px;
  bottom: 0;
  background: #f9f9f9;
  position: fixed;
  z-index: 8000;
}

.rowObservation {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  height: 40px;
}

.btnsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.btnsContainerFooter {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.btnsContainerFooterInit {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  color: white;
  font-size: 12px;
}

.btnsAction {
  border-radius: 4px;
  width: 65px;
  border: 1px solid rgb(222, 221, 221);
  color: white;
  height: 30px;
  display: flex;
  font-size: 12px !important;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #ececec;
  width: 165px;
  border: 1px solid rgb(222, 221, 221);
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.input-group {
  width: 30%;
}

.input-group-time {
  width: 15%;
}

.input-groupSm {
  width: 20%;
}

.input-group-ob {
  width: 100%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.descriptionObs {
  margin: 0px;
  color: #666360;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 11px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.card {
  border: none !important;
}

.inputNameShippingCompany {
  width: 70%;
}

@media screen and (max-width: 800px) {
  .rowContentShippingCompany {
    flex-wrap: wrap;
    justify-content: start;
  }

  .inputNameShippingCompany {
    width: 100%;
  }

  .inputShippingCompanyMd {
    width: 48.82%;
    margin-top: 10px;
  }

  .inputShippingCompanySm {
    width: 32%;
  }
}

@media screen and (max-width: 700px) {
  .rowContent {
    flex-direction: column;
  }
  .selectPlus {
    width: 100%;
  }
  .input-group {
    width: 100% !important;
  }
  .selectPlusSm {
    width: 100%;
  }
  .input-groupSm {
    width: 100%;
  }
  .user-label-sm {
    left: 30px !important;
  }
  .rowContentFooter {
    display: flex;
    width: 100vw !important;
    padding: 5px;
    gap: 5px;
    height: 60px;
    bottom: 0;
  }
  .btnsSave {
    width: 100px;
    font-size: 11px;
  }
  .btnsSaveNeutral {
    width: 100px;
    font-size: 11px;
  }
}
</style>