import moment from "moment";
import { mapMutations, mapState } from "vuex";

import ModalCreateCustomer from "../../components/Modals/ModalCreateCustomer.vue";
import ModalCreateProduct from "../../components/Modals/ModalCreateProduct.vue";
import ModalShippingCompany from "../../components/Modals/ModalShippingCompany.vue";
import ModalCancelNota from "../../components/NotaFiscal/ModalCancelNota.vue";
import ModalCorrectionLetter from "../../components/NotaFiscal/ModalCorrectionLetter.vue";
import ModalTaxInformation from "../../components/ModalTaxInformation/Index-TaxInformation.vue";
import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue";
import Products from "../../components/Products/Products.vue";
import InputQuant from "../../components/UI/CustomInputs/InputQuant.vue";

import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceNotaItem from "../../services/serviceNotaItem";
import ServiceTax from "../../services/serviceTax";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceBill from "../../services/serviceBill";
import ServiceShippingCompany from "../../services/serviceShippingCompany";
import ServiceSettings from "../../services/serviceSettings";

import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import { mascaraMoeda } from "../../utils/maskMoney";

const mixinNotaFiscal = {
  components: {
    ModalShippingCompany,
    ModalCreateCustomer,
    ModalCreateProduct,
    ModalCancelNota,
    ModalCorrectionLetter,
    ModalTaxInformation,
    InputSelect,
    Products,
    InputQuant,
  },
  props: {
    dataNFeAfterTransformSaleToNfe: {
      type: Object,
    },
  },
  data() {
    return {
      dadosNfe: {
        id: "",
        ID: "", // Controle das solicitações de emissão por pedido ou ID de processamento.
        operacao: "1",
        idVenda: "",
        natureza_operacao: "Venda de mercadoria",
        modelo: 1, //2 para NFC-e
        ambiente: "2", //2 para Homologação 1 para Produção
        idCliente: "",
        idTransportadora: "",
        finalidade: 1,
        data_nfe: moment().format("YYYY-MM-DD HH:mm"),
        id_webmania: "",
        response: "",
        response_cancelamento: "",
        status: "",
        nfe_referenciada: "",
        pagamento: 0,
        forma_pagamento: "01",
        presenca: 1,
        modalidade_frete: 9,
        frete: 0.0,
        despesas_acessorias: 0.0,
        desconto: 0.0,
        total: "",
        intermediador: "",
        cnpj_intermediador: "",
        id_intermediador: "",
        volume: "",
        especie: "",
        peso_bruto: "",
        peso_liquido: "",
        marca: "",
        numeracao: "",
        lacres: "",
        informacoes_complementares: "",
      },
      responseNfeWebMania: {
        chave: "",
        danfe: "",
        danfe_etiqueta: "",
        danfe_simples: "",
        log: "",
        modelo: "",
        motivo: "",
        nfe: "",
        serie: "",
        status: "",
        uuid: "",
        xml: "",
      },
      responseNfeWebManiaCancelamento: {
        log: {},
        status: "",
        xml: "",
      },
      produtosNotaFiscal: {
        id: "",
        idProduto: "",
        idNota: "",
        codigo: "",
        ncm: "",
        cest: "",
        quantidade: 1,
        unidade: "",
        peso: "",
        origem: 0,
        desconto: "",
        subtotal: "",
        total: "",
        classe_imposto: "",
        informacoes_adicionais: "",
      },
      cliente: [],
      produtos: [],
      dataProducts: {},
      getAllProducts: null,
      produtosForTable: [],
      getNotaAfterModifyNota: false,
      producsReferencedNota: [],
      producsReferencedNotaFinal: [],
      dadosNotaDevolucao: {
        produtos: [],
        quantidade: [],
        uuidItem: [],
      },
      pagamento: [
        { value: 0, text: "Pagamento à vista" },
        { value: 1, text: "Pagamento à prazo" },
      ],
      forma_pagamento: [
        { value: "01", text: "Dinheiro" },
        { value: "02", text: "Cheque" },
        { value: "03", text: "Cartão de Crédito" },
        { value: "04", text: "Cartão de Débito" },
        { value: "05", text: "Crédito Loja" },
        { value: "10", text: "Vale Alimentação" },
        { value: "11", text: "Vale Refeição" },
        { value: "12", text: "Vale Presente" },
        { value: "13", text: "Vale Combustível" },
        { value: "14", text: "Duplicata Mercantil" },
        { value: "15", text: "Boleto Bancário" },
        { value: "16", text: "Depósito Bancário" },
        { value: "17", text: "Pagamento Instantâneo (PIX)" },
        { value: "18", text: "Transferência bancária, Carteira Digital" },
        {
          value: "19",
          text: "Programa de fidelidade, Cashback, Crédito Virtual",
        },
        { value: "90", text: "Sem pagamento" },
      ],
      presenca: [
        {
          value: 0,
          text: "0 - Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste)",
        },
        { value: 1, text: "1 - Operação presencial" },
        { value: 2, text: "2 - Operação não presencial, pela Internet" },
        { value: 3, text: "3 - Operação não presencial, Teleatendimento" },
        { value: 4, text: "4 - NFC-e em operação com entrega a domicílio" },
        { value: 5, text: "5 - Operação presencial, fora do estabelecimento" },
        { value: 9, text: "9 - Operação não presencial, outros" },
      ],
      modelo: [
        { value: 1, text: "NFE" },
        { value: 2, text: "NFCE" },
      ],
      finalidade: [
        { value: 1, text: "Normal" },
        { value: 4, text: "Devolução" },
      ],
      ambiente: [
        { value: 1, text: "Produção" },
        { value: 2, text: "Homologação" },
      ],
      origem: [
        {
          value: 0,
          text: "0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8",
        },
        {
          value: 1,
          text: "1 - Estrangeira - Importação direta, exceto a indicada no código 6",
        },
        {
          value: 2,
          text: "2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
        },
        {
          value: 3,
          text: "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%",
        },
        {
          value: 4,
          text: "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
        },
        {
          value: 5,
          text: "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
        },
        {
          value: 6,
          text: "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural",
        },
        {
          value: 7,
          text: "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural",
        },
        {
          value: 8,
          text: "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
        },
      ],
      modalidade_frete: [
        { value: 0, text: "Por conta do Remetente" },
        { value: 1, text: "Por conta do Destinatário" },
        { value: 2, text: "Por conta de Terceiros" },
        { value: 3, text: "Transporte Próprio do Remetente" },
        { value: 4, text: "Transporte Próprio do Destinatário" },
        { value: 9, text: "Sem Frete" },
      ],
      shippingCompanys: [{ id: null, nome: "Selecione uma transportadora" }],
      isDisabled: true,
      textoTipoEmissao: "Saída",
      intermediador: [
        {
          value: 0,
          text: "0 - Operação sem intermediador (em site ou plataforma própria)",
        },
        {
          value: 1,
          text: "1 - Operação em site ou plataforma de terceiros (intermediadores/marketplace)",
        },
      ],
      spinLoading: false,
      spinLoadingDevolucao: false,
      allRefsFiscaisFromSelectBox: [],
      classeImpostoDevolucao: "",
      createdNewRef: false,
      duplicates: {
        dateInterval: "",
        installments: "",
        fistExpiration: "",
        idTypePayment: "",
        idReceiptAccount: "",
      },
      typePayments: [],
      receiptAccounts: [],
      billsFromTable: [],
      getNewFormPayment: false,
      numberBudget: "",
      idProductFromSearch: "",
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_NFE"]),
    clearInputs() {
      this.dadosNfe.id = "";
      this.dadosNfe.ID = "";
      this.dadosNfe.natureza_operacao = "Venda de mercadoria";
      this.dadosNfe.ambiente = "2";
      this.dadosNfe.idCliente = "";
      this.dadosNfe.data_nfe = moment().format("YYYY-MM-DD HH:mm");
      this.dadosNfe.status = "";
      this.dadosNfe.informacoes_complementares = "";
      this.dadosNfe.despesas_acessorias = "";

      this.produtosNotaFiscal.idProduto = "";
      this.produtosNotaFiscal.codigo = "";
      this.produtosNotaFiscal.quantidade = 1;
      this.produtosNotaFiscal.informacoes_adicionais = "";
      this.produtosNotaFiscal.subtotal = 0.0;
      this.produtosNotaFiscal.total = 0.0;
      this.produtosNotaFiscal.unidade = "";
      this.produtosNotaFiscal.peso = "";
      this.produtosNotaFiscal.origem = 0;
      this.produtosNotaFiscal.desconto = "";
      this.produtosNotaFiscal.classe_imposto = "";

      this.dadosNfe.modalidade_frete = 9;
      this.dadosNfe.frete = 0.0;
      this.dadosNfe.pagamento = 0;
      this.dadosNfe.forma_pagamento = "01";
      this.dadosNfe.presenca = 1;
      this.dadosNfe.desconto = 0.0;
      this.dadosNfe.total = 0.0;
      this.dadosNfe.intermediador = "";
      this.dadosNfe.id_intermediador = "";
      this.dadosNfe.cnpj_intermediador = "";

      this.dadosNfe.idTransportadora = "";
      this.dadosNfe.volume = "";
      this.dadosNfe.especie = 0;
      this.dadosNfe.peso_bruto = 0;
      this.dadosNfe.peso_liquido = 0;
      this.dadosNfe.marca = 0;
      this.dadosNfe.numeracao = 0;
      this.dadosNfe.lacres = 0;

      this.produtosForTable = [];
      this.billsFromTable = [];

      this.responseNfeWebMania.chave = "";
      this.responseNfeWebMania.danfe = "";
      this.responseNfeWebMania.danfe_etiqueta = "";
      this.responseNfeWebMania.danfe_simples = "";
      this.responseNfeWebMania.log = "";
      this.responseNfeWebMania.modelo = "";
      this.responseNfeWebMania.motivo = "";
      this.responseNfeWebMania.nfe = "";
      this.responseNfeWebMania.serie = "";
      this.responseNfeWebMania.status = "";
      this.responseNfeWebMania.uuid = "";
      this.responseNfeWebMania.xml = "";

      this.$refs.quantidade.valueInput = 1;
      this.$refs.refCliente.valueItems = "";

      this.SET_DATA_NFE({});
    },

    changeEmissionTypeText() {
      if (this.dadosNfe.operacao == "1") {
        this.textoTipoEmissao = "Saída";
      } else {
        this.textoTipoEmissao = "Entrada";
      }
    },

    enableFreightage() {
      this.dadosNfe.modalidade_frete == 9
        ? (this.isDisabled = true)
        : (this.isDisabled = false);
    },

    handleTotalValue(value) {
      this.dadosNfe.total = value;
      return this.maskCurrency(this.dadosNfe.total);
    },

    handleTotalproductsValue(value) {
      this.produtosNotaFiscal.total = value;
      return this.maskCurrency(this.produtosNotaFiscal.total);
    },

    formatTotalProductValue() {
      if (this.produtosNotaFiscal.subtotal !== "") {
        this.produtosNotaFiscal.total =
          parseFloat(this.produtosNotaFiscal.quantidade) *
          parseFloat(this.produtosNotaFiscal.subtotal);
      }
    },

    calculateTotalAndTotalDiscount() {
      if (this.produtosForTable.length > 0) {
        const valorTotalNota = this.produtosForTable
          .map((produto) => parseFloat(produto.total))
          .reduce((total, preco) => total + preco, 0);

        this.dadosNfe.total =
          parseFloat(valorTotalNota) +
          parseFloat(this.dadosNfe.frete) +
          this.dadosNfe.despesas_acessorias;

        this.dadosNfe.desconto = this.produtosForTable
          .map((produto) => parseFloat(produto.desconto))
          .reduce((total, desconto) => total + desconto, 0);
      } else {
        this.dadosNfe.total = 0;
        this.dadosNfe.desconto = 0;
      }
    },

    defineMaxLengthDiscount() {
      if (this.dadosNfe.desconto?.length > 4) {
        this.dadosNfe.desconto = this.dadosNfe.desconto.slice(0, 4);
      }
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    calculateDiscountProdutos() {
      const valorTotalProdutos =
        parseFloat(this.produtosNotaFiscal.quantidade) *
        parseFloat(this.produtosNotaFiscal.subtotal);

      const desconto = parseFloat(this.produtosNotaFiscal.desconto);

      const valorDesconto = valorTotalProdutos - desconto;
      this.produtosNotaFiscal.total = valorDesconto;
    },

    chekedProductsReturnNota(index) {
      if (this.producsReferencedNotaFinal[index].isDevolucao) {
        if (!this.producsReferencedNotaFinal[index].qtdDevolvida)
          this.producsReferencedNotaFinal[index].qtdDevolvida = 1;

        this.producsReferencedNotaFinal[index].index = ++index;
        return;
      }

      this.producsReferencedNotaFinal[index].qtdDevolvida = 1;
      this.producsReferencedNotaFinal[index].index = null;
    },

    handleReturnedAmmoutAndAmmount() {
      for (let i = 0; i < this.producsReferencedNotaFinal.length; i++) {
        const result = this.veirfyAmount(i);
        if (result) {
          this.$toast.open({
            message:
              "Quantidade DEVOLVIDA não pode ser maior nem menor que a QUANTIDADE!",
            type: "warning",
          });
          return result;
        }
      }
    },

    veirfyAmount(index) {
      return (
        this.producsReferencedNotaFinal[index].qtdDevolvida < 1 ||
        this.producsReferencedNotaFinal[index].qtdDevolvida === "" ||
        parseInt(this.producsReferencedNotaFinal[index].qtdDevolvida) >
        this.producsReferencedNotaFinal[index].quantidade
      );
    },

    maskMoney(value) {
      if (value?.length === 3) {
        return "#,##";
      }
      if (value?.length === 5) {
        return "##,##";
      }
      if (value?.length === 6) {
        return "###,##";
      }
      if (value?.length === 7) {
        return "#.###,##";
      }
      if (value?.length === 9) {
        return "##.###,##";
      }
      if (value?.length === 10) {
        return "###.###,##";
      }
      if (value?.length === 11) {
        return "#.###.###,##";
      }
      if (value?.length === 12) {
        return "##.###.###,##";
      } else {
        return "";
      }
    },

    maskDiscount(value) {
      if (value?.length === 2) {
        return "#.#";
      } else if (value?.length === 4) {
        return "##.#";
      } else if (value?.length === 5) {
        return "##.##";
      } else if (value?.length === 6) {
        return "###.##";
      } else {
        return "";
      }
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    sendNfeByEmail() {
      console.log("Enviado email");
    },

    visualizarNfe() {
      window.open(
        this.responseNfeWebMania.danfe,
        "_blank",
        "width=700, height=800, top=500, left=500"
      );
    },

    async sendNota() {
      if (this.dadosNfe.operacao == "1" && this.dadosNfe.finalidade == "4") {
        await this.sendExitNotaDevolution();
      } else {
        await this.sendExitNota();
      }
    },

    async sendExitNota() {
      try {
        await ServiceNotaFiscal.updateNota({
          ...this.dadosNfe,
          frete: this.dadosNfe.frete === "" ? 0 : this.dadosNfe.frete,
          total: this.dadosNfe.total,
          desconto: this.dadosNfe.desconto,
        });

        this.spinLoading = true;

        const result = await ServiceNotaFiscal.sendExitNota(this.dadosNfe.id);
        Object.assign(this.responseNfeWebMania, result.dataWebMania);
        this.dadosNfe.status = result.status;

        if (result.status === "Contingência") {
          return this.$toast.open({
            message:
              "Nota em contingência, deve-se aguardar, a nota será emitida em breve!",
            type: "info",
          });
        } else {
          return this.$toast.open({
            message: "Nota emitida com sucesso!",
            type: "success",
          });
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) ||
          typeError?.nameError === TypeErrors.NOT_FOUND_SOFTWARE_PLAN ||
          typeError?.nameError === TypeErrors.NOT_FOUND_FISCAL_DOCUMENTS
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message: error?.response?.data?.message,
          type: "info",
        });
      } finally {
        this.spinLoading = false;
      }
    },

    async sendExitNotaDevolution() {
      try {
        await ServiceNotaFiscal.updateNota({
          ...this.dadosNfe,
          frete: this.dadosNfe.frete === "" ? 0 : this.dadosNfe.frete,
          total: this.dadosNfe.total,
          desconto: this.dadosNfe.desconto,
        });

        this.spinLoading = true;
        await ServiceNotaFiscal.sendExitNotaDevolution(this.dadosNfe.id);

        await this.findNotaById();

        return this.$toast.open({
          message: "Nota emitida com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) ||
          typeError?.nameError === TypeErrors.NOT_FOUND_SOFTWARE_PLAN ||
          typeError?.nameError === TypeErrors.NOT_FOUND_FISCAL_DOCUMENTS
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message: error.response.data.message,
          type: "error",
        });
      } finally {
        this.spinLoading = false;
      }
    },

    async sendEntryNotaDevolution() {
      try {
        this.spinLoadingDevolucao = true;

        this.dadosNotaDevolucao = {
          produtos: [],
          quantidade: [],
          uuidItem: [],
        };

        this.handleValuesDataDevolutionNota();

        await ServiceNotaFiscal.sendEntryNotaDevolution(
          {
            ...this.dadosNotaDevolucao,
            ambiente: this.dadosNfe.ambiente,
            natureza_operacao:
              "Devolução de venda de produção do estabelecimento",
            classe_imposto: this.classeImpostoDevolucao,
          },
          this.dadosNfe.id
        );

        await this.findNotaById();
        await this.findProductsByIdNota();

        this.$bvModal.hide("modalReturnNota");
        this.producsReferencedNotaFinal = [];
        return this.$toast.open({
          message: "Nota devolvida com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: error.response.data.message,
          type: "error",
        });
      } finally {
        this.spinLoadingDevolucao = false;
      }
    },

    saveOrUpdateNotaItems() {
      !this.produtosNotaFiscal.id
        ? this.saveProductInNote()
        : this.updateNotaItems();
    },

    async updateNotaItems() {
      try {
        const formatedSubTotal = this.produtosNotaFiscal.subtotal;
        const formatedTotal = parseFloat(this.produtosNotaFiscal.total);
        const formatedDesconto =
          this.produtosNotaFiscal.desconto === ""
            ? "0"
            : this.produtosNotaFiscal.desconto;
        await ServiceNotaItem.updateNotaItems({
          ...this.produtosNotaFiscal,
          idNota: this.dadosNfe.id,
          subtotal: formatedSubTotal,
          total: formatedTotal,
          desconto: formatedDesconto,
        });
        await this.findProductsByIdNota();
        await this.updateNotaFiscal();
        this.clearProducts();

        this.$emit("getAllInvoices", this.getInvoices);
        this.getInvoices = !this.getInvoices;

        return this.$toast.open({
          message: "Produto atualizado!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async saveProductInNote() {
      try {
        if (this.dadosNfe.id === "") {
          return this.$toast.open({
            message: "É necessário salvar a nota para adicionar o produto!",
            type: "warning",
          });
        } else {
          const formatedSubTotal = this.produtosNotaFiscal.subtotal;
          const formatedTotal = parseFloat(this.produtosNotaFiscal.total);
          const formatedDesconto =
            this.produtosNotaFiscal.desconto === ""
              ? "0"
              : this.produtosNotaFiscal.desconto;

          await ServiceNotaItem.save({
            ...this.produtosNotaFiscal,
            idNota: this.dadosNfe.id,
            subtotal: formatedSubTotal,
            total: formatedTotal,
            desconto: formatedDesconto,
          });

          await this.findProductsByIdNota();
          await this.updateNotaFiscal();
          this.clearProducts();
        }
      } catch (error) {
        console.log(error);
        return toastAlertErros.validateErrorRemoveUnwantedCharacters(
          error,
          this.$toast
        );
      }
    },

    clearProducts() {
      this.produtosNotaFiscal.id = "";
      this.produtosNotaFiscal.idProduto = "";
      this.produtosNotaFiscal.codigo = "";
      this.produtosNotaFiscal.quantidade = 1.0;
      this.produtosNotaFiscal.informacoes_adicionais = "";
      this.produtosNotaFiscal.subtotal = "";
      this.produtosNotaFiscal.total = "";
      this.produtosNotaFiscal.unidade = "";
      this.produtosNotaFiscal.peso = "";
      this.produtosNotaFiscal.origem = 0;
      this.produtosNotaFiscal.desconto = "";
      this.produtosNotaFiscal.classe_imposto = "";

      this.$refs.refProductsManage.valueItems = "";
      this.$refs.quantidade.valueInput = "1";
    },

    async deleteProductFromNote(id) {
      try {
        await ServiceNotaItem.deleteFromNote(id);
        await this.findProductsByIdNota();
      } catch (error) {
        return this.$toast.open({
          message:
            error.response.data.message || "Não foi possível deletar o produto",
          type: "warning",
        });
      }
    },

    async findItemById(id) {
      try {
        const result = await ServiceNotaItem.findById(id);
        Object.assign(this.produtosNotaFiscal, result.noteItem);
        this.$refs.refProductsManage.valueItems = result.noteItem.idProduto;
      } catch (error) {
        return this.$toast.open({
          message:
            error.response.data.message || "Não foi possível Editar o produto",
          type: "warning",
        });
      }
    },

    async getfindItemById(id) {
      const result = await ServiceNotaItem.findById(id);
      Object.assign(this.dataCategory, result);
      this.$refs.categoryName.valueInput = result.nome;
    },

    async findProductsByIdNota() {
      const result = await ServiceNotaItem.findByIdNota(this.dadosNfe.id);

      this.produtosForTable = result.noteItem;

      this.calculateTotalAndTotalDiscount();
    },

    async assignValuesToTheSelectedProduct() {
      const result = this.produtos.filter(
        (idProduto) => idProduto.id == this.produtosNotaFiscal.idProduto
      );

      this.produtosNotaFiscal.unidade = result[0].unidade;
      this.produtosNotaFiscal.codigo = result[0].codReferencia;
      this.produtosNotaFiscal.classe_imposto = result[0].refFiscal;
      this.produtosNotaFiscal.ncm = result[0].ncm;
      this.produtosNotaFiscal.cest = result[0].cest;
      this.produtosNotaFiscal.subtotal = result[0].valorVenda;
      this.formatTotalProductValue();
    },

    async getCliente() {
      try {
        const data = await ServiceCustomer.getCustomersForSelectBox();
        this.cliente = data.data;
      } catch (error) {
        return this.$toast.open({
          message: "Não foi possível listar os clientes",
          type: "warning",
        });
      }
    },

    async getShippingCompanys() {
      try {
        const result = await ServiceShippingCompany.fillCombobox();
        this.shippingCompanys = result;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao listar as transportadoras",
        });
      }
    },

    async getProductsForSelectBox() {
      try {
        const data = await ServiceProducts.getProductsForSelectBox();
        this.produtos = data.data;
      } catch (error) {
        return this.$toast.open({
          message: "Não foi possível listar os produtos",
          type: "warning",
        });
      }
    },

    async getProductsById() {
      try {
        const data = await ServiceProducts.getProductById(
          this.produtosNotaFiscal.idProduto
        );
        return data;
      } catch (error) {
        return this.$toast.open({
          message: "Não foi possível buscar o produto",
          type: "warning",
        });
      }
    },

    async saveNotaFiscal() {
      try {
        const result = await ServiceNotaFiscal.saveNota({
          ...this.dadosNfe,
          frete: this.dadosNfe.frete,
          total: this.dadosNfe.total,
        });

        this.dadosNfe.id = result.id;
        this.$emit("getAllInvoices", this.getInvoices);
        this.getInvoices = !this.getInvoices;

        return this.$toast.open({
          message: "Nota Fiscal salva!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          toastAlertErros.validateErrorRemoveUnwantedCharacters(
            error,
            this.$toast
          );
      }
    },

    async updateNotaFiscal() {
      try {
        await ServiceNotaFiscal.updateNota({
          ...this.dadosNfe,
          frete: this.dadosNfe.frete,
          total: this.dadosNfe.total,
          desconto: this.dadosNfe.desconto,
        });

        this.$emit("getAllInvoices", this.getInvoices);
        this.getInvoices = !this.getInvoices;

        return this.$toast.open({
          message: "Nota Fiscal atualizada!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    clickProductAndSave() {
      if (this.dadosNfe.id === "" && this.dadosNfe.idCliente !== "") {
        this.updateOrSaveNotaFiscal();
      }
    },

    async updateOrSaveNotaFiscal() {
      this.dadosNfe.id !== "" ? this.updateNotaFiscal() : this.saveNotaFiscal();
    },

    async handleEmitOrReturnNota() {
      this.dadosNfe.operacao == "1"
        ? await this.sendNota()
        : await this.findNotaByChaveReferenciada();
    },

    async findNotaById() {
      const result = await ServiceNotaFiscal.findNotaById(this.dadosNfe.id);
      Reflect.deleteProperty(result, "idEmpresa");

      Object.assign(this.dadosNfe, result, {
        total: String(result?.total)?.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        }),
      });

      Object.assign(this.responseNfeWebMania, result.response);
      Object.assign(
        this.responseNfeWebManiaCancelamento,
        result.response_cancelamento
      );
    },

    async findNotaByChaveReferenciada() {
      try {
        const result = await ServiceNotaFiscal.findNotaByChaveReferenciada(
          this.dadosNfe.nfe_referenciada
        );
        this.producsReferencedNota = result;
        this.openModalReturnNota();
        this.handleDataProducsReferencedNota();
      } catch (error) {
        return this.$toast.open({
          message: `${error.response.data.message}`,
          type: "error",
        });
      }
    },

    async findAllRefsFromSelectBox() {
      try {
        const result = await ServiceTax.findAllRefs();
        this.allRefsFiscaisFromSelectBox = result.data.map((e) => {
          return {
            id: e.id,
            descricao: `${e.ref} - ${e.descricao}`,
            ref: e.ref,
            refObject: e.refObject,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    async makingThePayment() {
      try {
        if (this.dadosNfe.id === "") {
          return this.$toast.open({
            message: "Selecione uma nota para lançar as duplicatas",
            type: "warning",
          });
        }

        const bill = [];

        const totalValue = this.dadosNfe.total;
        const valuePerDuplicates = totalValue / this.duplicates.installments;
        for (let i = 0; i < this.duplicates.installments; i++) {
          const expirationDate =
            i == 0
              ? this.duplicates.fistExpiration
              : moment(expirationDate)
                .add(this.duplicates.dateInterval, "days")
                .format("YYYY-MM-DD");
          bill.push({
            tipo: "entrada",
            idCliente: this.dadosNfe.idCliente,
            idFormaPagamento: this.duplicates.idTypePayment,
            idContaRecebimento: this.duplicates.idReceiptAccount,
            idNota: this.dadosNfe.id,
            valorTotal: valuePerDuplicates,
            valorPago: 0.0,
            valorRestante: 0.0,
            data: expirationDate,
            dataCompetencia: moment().format("YYYY-MM-DD"),
          });
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(bill));
        await ServiceBill.save(formData);

        this.getAllBillsByIdNota();

        return this.$toast.open({
          message: "Parcela Registrada!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            error.response.data.message ||
            "Verifique se os campos estão preenchidos corretamente!",
          type: "warning",
        });
      }
    },

    async getAllBillsByIdNota() {
      try {
        const data = await ServiceBill.getAllBillsByParamsId(
          this.dadosNfe.id,
          BillColumnsToSearchById.idNota
        );
        this.billsFromTable = data;
      } catch (error) {
        return this.$toast.open({
          message: "Ocorreu um erro ao listas as duplicatas",
          type: "error",
        });
      }
    },

    async deleteBill(id) {
      try {
        await ServiceBill.delete(id);
        await this.getAllBillsByIdNota();

        return this.$toast.open({
          message: "Duplicata deletada com sucesso",
          type: "success",
        });
      } catch (error) {
        console.log(error.response);
        return this.$toast.open({
          message: "Ocorreu um erro ao deletar as duplicatas",
          type: "error",
        });
      }
    },

    async getPaymentsFromSelectBox() {
      const result = await ServicePaymentForm.getAllPayment();
      this.typePayments = result;
    },

    async getReceiptBillForSelectBox() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.receiptAccounts = result;
    },

    openModalReturnNota() {
      this.$bvModal.show("modalReturnNota");
      this.producsReferencedNotaFinal = [];
    },

    openModalCorrectionLetter() {
      this.$bvModal.show("modalCorrectionLetter");
    },

    handleDataProducsReferencedNota() {
      this.producsReferencedNota.forEach((prod) =>
        this.producsReferencedNotaFinal.push({
          ...prod,
          qtdDevolvida: 1,
          index: null,
          isDevolucao: false,
        })
      );
    },

    handleValuesDataDevolutionNota() {
      this.producsReferencedNotaFinal.forEach((element, index) => {
        if (this.producsReferencedNotaFinal[index].isDevolucao)
          this.dadosNotaDevolucao.quantidade.push(element.qtdDevolvida);
      });
      this.producsReferencedNotaFinal.forEach((element, index) => {
        if (this.producsReferencedNotaFinal[index].isDevolucao)
          this.dadosNotaDevolucao.produtos.push(element.index);
      });
      this.producsReferencedNotaFinal.forEach((element, index) => {
        if (this.producsReferencedNotaFinal[index].isDevolucao)
          this.dadosNotaDevolucao.uuidItem.push(element.id);
      });
    },

    assignSaleToNfe() {
      const data = this.assignMonetaryValueSaleToNfe();
      Object.assign(this.dadosNfe, data);

      this.produtosForTable = this.dataNFeAfterTransformSaleToNfe.nfeItens;

      if (this.dataNFeAfterTransformSaleToNfe.reflectSaleBillsInNfe.length) {
        this.billsFromTable =
          this.dataNFeAfterTransformSaleToNfe.reflectSaleBillsInNfe;
      }

      if (this.$refs.refCliente)
        this.$refs.refCliente.valueItems = this.dadosNfe.idCliente;
    },

    assignMonetaryValueSaleToNfe() {
      const totalNFe = this.dataNFeAfterTransformSaleToNfe.nfe.total;
      const totalDiscount = this.dataNFeAfterTransformSaleToNfe.nfe.desconto;

      const data = {
        ...this.dataNFeAfterTransformSaleToNfe.nfe,
        data_nfe: moment().format("YYYY-MM-DD HH:mm"),
        total: totalNFe,
        desconto: totalDiscount,
      };

      return data;
    },

    handleValuesAfterSaleTransformInNfe() {
      if ((Object.keys(this.dataNFeAfterTransformSaleToNfe).length &&
        this.dataNFeAfterTransformSaleToNfe.nfe.idVenda &&
        this.dataNFeAfterTransformSaleToNfe.nfeItens) || this.dataNFeAfterTransformSaleToNfe?.nfe?.idVendaMtq) {

        this.assignSaleToNfe()
      }
    },

    maskToKg(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return `KG ${parseFloat(valor).toLocaleString(locale, {
        minimumFractionDigits: 2,
      })}`;
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    handleFreteMonetaryValue(event) {
      this.dadosNfe.frete = mascaraMoeda(event);
    },

    handleExpensesMonetaryValue(event) {
      this.dadosNfe.despesas_acessorias = mascaraMoeda(event);
    },

    handleSubtotalMonetaryValue(event) {
      this.produtosNotaFiscal.subtotal = mascaraMoeda(event.target.value);
    },

    handleDiscountProductsValue(event) {
      this.produtosNotaFiscal.desconto = mascaraMoeda(event.target.value);
    },

    handleTotalDiscount(event) {
      this.dadosNfe.desconto = mascaraMoeda(event.target.value);
    },

    handleNetWeight(event) {
      this.dadosNfe.peso_liquido = mascaraMoeda(event);
    },

    handleGrossWeight(event) {
      this.dadosNfe.peso_bruto = mascaraMoeda(event);
    },

    handleEditNFe() {
      if (Object.keys(this.dataNFe).length) {
        Object.assign(this.dadosNfe, this.dataNFe);

        this.produtosForTable = this.dataNFe.products;
        this.billsFromTable = this.dataNFe.bills;

        if (this.dataNFe.response) {
          this.responseNfeWebMania = { ...this.dataNFe.response };
        }

        this.$refs.refShippingCompapy.valueItems =
          this.dadosNfe.idTransportadora;
        this.$refs.refCliente.valueItems = this.dadosNfe.idCliente;
      }
    },

    async getConfig() {
      const result = await ServiceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.dadosNfe.ambiente = result.emissao_fiscal.ambiente;
      }
    },
  },
  computed: {
    ...mapState(["dataNFe", "dataConfig"]),
    exibirDadosIntermediador() {
      return (
        this.retornaTipoOperacao &&
        this.retornaTipoFinalizade &&
        this.retorntaTipoPresenca
      );
    },
    retornaTipoOperacao() {
      return this.dadosNfe.operacao == 1;
    },
    retornaTipoFinalizade() {
      return this.dadosNfe.finalidade == 1;
    },
    retorntaTipoPresenca() {
      return (
        this.dadosNfe.presenca == 2 ||
        this.dadosNfe.presenca == 3 ||
        this.dadosNfe.presenca == 4 ||
        this.dadosNfe.presenca == 9
      );
    },
    handleButtonEmitirNfe() {
      if (
        (this.dadosNfe.finalidade == "4" &&
          this.dadosNfe.nfe_referenciada !== "" &&
          this.dadosNfe.id !== "" &&
          this.responseNfeWebMania?.chave === "" &&
          this.dadosNfe.operacao == "0") ||
        this.responseNfeWebMania?.status === "reprovado" ||
        this.responseNfeWebMania.status === "contingencia"
      ) {
        return false;
      } else {
        if (
          this.produtosForTable.length < 1 ||
          this.responseNfeWebMania?.chave !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    handleOperacao() {
      return this.dadosNfe.operacao;
    },

    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais);
      }

      return 2;
    },
  },
  filters: {
    formatDate: function (value) {
      return moment(value).format("DD/MM/YYYY")
    }
  },
  mounted() {
    this.handleValuesAfterSaleTransformInNfe();
    this.handleEditNFe();
    this.enableFreightage();
    this.getCliente();
    this.getProductsForSelectBox();
    this.findAllRefsFromSelectBox();
    this.getPaymentsFromSelectBox();
    this.getReceiptBillForSelectBox();
    this.getShippingCompanys();
    this.getConfig();
  },
  beforeDestroy() {
    this.clearInputs();
  },
  watch: {
    getNotaAfterModifyNota() {
      this.findNotaById();
    },
    handleOperacao() {
      this.changeEmissionTypeText();
    },
    createdNewRef() {
      this.findAllRefsFromSelectBox();
    },
  },
};

export default mixinNotaFiscal;
