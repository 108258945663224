<template>
  <div class="provisionService">
    <div class="contentFront">
      <div class="contentTitleAndStatus">
        <p
          style="
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: bold;
            color: #575757;
          "
        >
          ORDEM DE SERVIÇO
        </p>

        <div class="selectPlus" style="width: 200px">
          <label class="fixedSelect" style="bottom: 25px">Status</label>
          <b-form-select
            :class="`borderInput shadow-none ${isInvalidInput}`"
            v-model="orderService.status"
            :options="nfseSituationsNfse"
            value-field="value"
            text-field="text"
          ></b-form-select>
        </div>
      </div>

      <div class="formFront">
        <div class="rowContent">
          <div class="input-group-btn">
            <b-form-input
              class="input borderInput shadow-none"
              v-model="orderService.referencia"
            />
            <label class="user-label"> N° Referência </label>
          </div>
        </div>
        <div class="rowContent">
          <div class="rowDates">
            <div style="width: 100%" class="input-group-btn">
              <b-form-input
                type="date"
                :class="`input borderInput shadow-none  ${isInvalidInput}`"
                v-model="orderService.data_nfse"
                ref="data_nfse"
                @blur.native="clearInvalidInput"
              />
              <label class="user-label"> Data Mov </label>
            </div>

            <div style="width: 100%" class="input-group-btn">
              <b-form-input
                type="date"
                :class="`input borderInput shadow-none  ${isInvalidInput}`"
                v-model="orderService.data_execucao"
                ref="data_execucao"
                @blur.native="clearInvalidInput"
              />
              <label class="user-label"> Data Execução </label>
            </div>
          </div>

          <div class="selectPlus">
            <label class="fixedSelect">Ambiente</label>
            <b-form-select
              class="borderInput shadow-none"
              v-model="orderService.ambiente"
              :options="ambiente"
            ></b-form-select>
          </div>
        </div>

        <div class="rowContent">
          <div class="selectPlus">
            <label class="fixedSelect">Cliente</label>
            <b-form-select
              :class="`borderInput shadow-none ${isInvalidInput}`"
              v-model="orderService.idCliente"
              :options="dataCustomers"
              value-field="id"
              text-field="nome"
              ref="idCliente"
              @blur.native="clearInvalidInput"
            ></b-form-select>
            <div class="iconSelect" v-b-modal.modalCreateCustomer>
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div>

          <div class="selectPlus">
            <label class="fixedSelect">Funcionário</label>
            <b-form-select
              class="borderInput shadow-none"
              v-model="orderService.idFuncionario"
              :options="employees"
              value-field="id"
              text-field="nomeFuncionario"
              @blur.native="clearInvalidInput"
            ></b-form-select>
            <div class="iconSelect" v-b-modal.modalCreateEmployee>
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div>
        </div>

        <div class="rowContent">
          <div class="contentDescription">
            <label class="description"> Descrição </label>

            <textarea
              class="inputTextArea"
              rows="2"
              max-rows="4"
              v-model="orderService.observacoes"
              @blur="clearInvalidInput"
            />
          </div>
        </div>

        <div class="rowContent">
          <div class="selectPlus selectService">
            <label class="fixedSelect">Serviços</label>
            <b-form-select
              :class="`borderInput shadow-none ${isInvalidInput}`"
              v-model="orderItem.idServico"
              :options="services"
              @change="selectProductSetDefaultValue"
              value-field="id"
              text-field="nome"
              ref="idServico"
              @blur.native="clearInvalidInput"
            ></b-form-select>
            <div class="iconSelect" v-b-modal.modal-2>
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div>
        </div>

        <div class="rowContentServiceAndDiscount">
          <div class="rowContentValueAndDiscount">
            <div class="input-group-btn" style="width: 100%">
              <b-form-input
                :class="`input borderInput shadow-none ${isInvalidInput}`"
                :value="maskCurrency(orderItem.valor)"
                @input="calculateValue($event, 'valor')"
                ref="valor"
                @blur.native="clearInvalidInput"
              />
              <label class="user-label"> Valor </label>
            </div>
            <div class="input-group-btn" style="width: 100%">
              <!-- <InputQuant
                label="Quant."
                type="text"
                style="width: 100% !important"
                :dataInput.sync="orderItem.quantidade"
                ref="quantidade"
              /> -->
              <b-form-input
                :class="`input borderInput shadow-none ${isInvalidInput}`"
                v-model="orderItem.quantidade"
              />
              <label class="user-label"> Quant. </label>
            </div>

            <div class="input-group-btn" style="width: 100%">
              <b-form-input
                class="input borderInput shadow-none"
                :value="handleMasksDiscount(orderItem.desconto)"
                @input="calculateValue($event, 'desconto')"
                @blur.native="clearInvalidInput"
              />
              <label class="user-label"> Desconto </label>
            </div>

            <div class="content-switch">
              <label class="switch">
                <input
                  type="checkbox"
                  :disabled="handleDiscountIsEnable"
                  v-model="discountIsChecked"
                  @change="alterTypeDiscount"
                />
                <div class="slider">
                  <span>R$</span>
                  <span>%</span>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="rowContent">
          <b-button class="add-btn" @click="addService">Adicionar</b-button>
        </div>

        <div class="content-clear-btn">
          <b-button
            @click="clear"
            class="sketch-btn"
            style="background: #dbdbdb; color: black; border: 1px solid gray"
          >
            Nova Ordem</b-button
          >
          <b-button
            v-if="orderService.id"
            class="sketch-btn btn-send-default-resolution"
            style="background: #dbdbdb; color: black; border: 1px solid gray"
            @click="openModals('modal-confirmed-service')"
            >{{ billsSaved.length ? "Ver Lançamentos" : "Receber" }}</b-button
          >
          <b-button class="sketch-btn" @click="saveOrUpdate">
            SALVAR OS</b-button
          >
        </div>
      </div>
    </div>
    <div class="contentSketch">
      <p
        style="
          margin-top: 1rem;
          font-size: 1rem;
          font-weight: bold;
          padding: 0 25px;
          color: #575757;
        "
      >
        SERVIÇOS ADICIONADOS
      </p>
      <div class="tableProducts">
        <table style="width: 100%">
          <thead
            style="
              border-bottom: 2px solid #d5d3d3;
              font-size: 12px;
              color: #575757;
            "
          >
            <tr>
              <th>Serviço Prestado</th>
              <th style="width: 10%">Quant</th>
              <th>Vl. Unitário</th>
              <th>Vl. Total</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(productAdd, index) in productsAdd" :key="index">
              <td>{{ productAdd.nome }}</td>
              <td style="width: 10%">{{ productAdd.quantidade }}</td>
              <td>
                {{
                  productAdd.valor.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </td>
              <td>
                {{
                  productAdd.valorTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </td>
              <td @click="deleteProductTable(productAdd.id)">
                <b-icon-x-circle
                  scale="1.3"
                  class="trashDelete"
                ></b-icon-x-circle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="styleTotal">
        <span>VALOR TOTAL</span>
        <span> {{ this.amount }} </span>
      </div>
    </div>

    <ModalCreateCustomer @getAllNewCustomer="getCustomersForSelectBox" />
    <ModalCreateEmployees @getAllNewEmployee="getEmployeeForSelectBox" />
    <b-modal id="modal-2" hide-footer hide-header centered size="lg">
      <ModalServiceProvision
        @provisionServiceFromCombobox="provisionServiceCombobox = $event"
      />
    </b-modal>
    <ModalEndService
      :totals="handleValuesToModalReceive"
      :employees="employees"
      :idCliente="orderService.idCliente"
      :idNfse="orderService.id"
      :billsSaved="billsSaved"
      :newOrder="newOrder"
      :referenceNumber="handleReferenceNumber"
      @newOrderService="clear"
      @billsConfirmed="billsSaved = $event"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";

import ModalServiceProvision from "./Service.vue";
import ModalEndService from "./ModalEndService.vue";
import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ServiceToBeProvided from "../../services/serviceToBeProvided";
import ServiceNfse from "../../services/serviceNfse";
import ServiceNfseItem from "../../services/serviceNfseItem";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceEmployees from "../../services/serviceEmployees";
import ServiceBill from "../../services/serviceBill";
import { mascaraMoeda } from "../../utils/maskMoney";
import { TypeDiscount } from "../../utils/enums/TypeDiscount";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeSituationNfse } from "../../utils/enums/TypeSituationNfse";
import { NfseModel } from "../../utils/enums/NfseModel";
import { nfseSituationsNfse } from "../../common/index";
// import InputQuant from "../UI/CustomInputs/InputQuant.vue";

import {
  ValidadeProvisionService,
  ValidadeItemService,
} from "../../validators/service";
import serviceSettings from "../../services/serviceSettings";

export default {
  props: {
    model: String,
    default: NfseModel.OS,
  },
  components: {
    ModalServiceProvision,
    ModalEndService,
    ModalCreateCustomer,
    ModalCreateEmployees,
    // InputQuant,
  },
  data() {
    return {
      orderService: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        ambiente: "2",
        data_nfse: moment().format("YYYY-MM-DD"),
        data_execucao: "",
        status: "Pendente",
        modelo: this.model,
        observacoes: "",
        referencia: "",
        response: null,
      },
      orderItem: {
        id: "",
        idNfse: "",
        idServico: "",
        valor: 0,
        valorTotal: 0,
        quantidade: 1,
        desconto: 0,
        tipoDesconto: TypeDiscount.valorMonetario,
      },
      provisionServiceCombobox: "",
      dataCustomers: [],
      employees: [],
      services: [],
      ambiente: [
        { text: "Produção", value: "1" },
        { text: "Homologação", value: "2" },
      ],
      productsAdd: [],
      amount: "R$ 0,00",
      discountIsChecked: false,
      isInvalidInput: "",
      billsSaved: [],
      newOrder: false,
      nfseSituationsNfse: nfseSituationsNfse,
    };
  },

  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_PROVISION_SERVICE"]),
    async save(enableMessage = true) {
      try {
        if (
          this.orderService.status === TypeSituationNfse.faturado &&
          !this.orderService.response
        ) {
          return this.$toast.open({
            message:
              "Não é possível selecionar este status, pois ele só é informado somente quando a NFS-e estiver emitida!",
            type: "info",
          });
        }

        const isValid = ValidadeProvisionService(this.orderService);
        if (isValid && Object.keys(isValid).length) {
          this.handleFocus(isValid.keyError);
          return this.$toast.open({
            message: isValid.message,
            type: "info",
          });
        }

        const data = await ServiceNfse.save({
          ...this.orderService,
          response: this.orderService.response
            ? JSON.stringify(this.orderService.response)
            : null,
        });
        this.orderItem.idNfse = data;
        this.orderService.id = data;
        this.orderService.status = TypeSituationNfse.pendente;

        if (enableMessage)
          return this.$toast.open({
            message: "Movimentação Salva!",
            type: "success",
          });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message: "Ocorreu um erro!",
          type: "info",
        });
      }
    },

    async updated() {
      try {
        if (
          this.orderService.status === TypeSituationNfse.faturado &&
          !this.orderService.response
        ) {
          return this.$toast.open({
            message:
              "Não é possível atualizar selecionar esse status, pois ele só é informado quando a NFS-e estiver emitida",
            type: "info",
          });
        }

        if (this.orderService.response) {
          this.orderService.status = TypeSituationNfse.faturado;

          return this.$toast.open({
            message: "Não é possível atualizar, pois essa NFS-e ja foi emitida",
            type: "info",
          });
        }

        const isValid = ValidadeProvisionService(this.orderService);
        if (isValid && Object.keys(isValid).length) {
          this.handleFocus(isValid.keyError);
          return this.$toast.open({
            message: isValid.message,
            type: "info",
          });
        }

        await ServiceNfse.update({
          ...this.orderService,
          response: this.orderService.response
            ? JSON.stringify(this.orderService.response)
            : null,
        });

        return this.$toast.open({
          message: "Movimentação Atualizada!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message: "Ocorreu um erro ao atualizar informações",
          type: "info",
        });
      }
    },

    async getCustomersForSelectBox() {
      try {
        const data = await ServiceCustomer.getCustomersForSelectBox();
        this.dataCustomers = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async getEmployeeForSelectBox() {
      try {
        const data = await ServiceEmployees.getEmployeesForSelectBox();
        this.employees = data.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao listar os funcionários",
          type: "info",
        });
      }
    },

    async saveOrUpdate() {
      if (!this.orderService.id) {
        await this.save();
      } else {
        await this.updated();
      }
    },

    async addService() {
      try {
        const isNotValid = ValidadeItemService(this.orderItem);
        if (this.discountIsChecked) {
          const totalValue = this.orderItem.valor * this.orderItem.quantidade;
          const descontoTotal = (this.orderItem.desconto / 100) * totalValue;
          this.orderItem.valorTotal = totalValue - descontoTotal;
        } else if (!this.discountIsChecked) {
          this.orderItem.valorTotal =
            this.orderItem.valor * this.orderItem.quantidade -
            this.orderItem.desconto;
        }

        if (isNotValid && Object.keys(isNotValid).length) {
          this.handleFocus(isNotValid.keyError);
          return this.$toast.open({
            message: isNotValid.message,
            type: "info",
          });
        } else {
          if (!this.orderService.id) {
            if (
              !this.orderService.idCliente ||
              !this.orderService.data_nfse ||
              !this.orderService.data_execucao
            ) {
              return await this.save(false);
            } else {
              await this.save(false);
            }
          }

          await ServiceNfseItem.save(this.orderItem);
          await this.getItensForGrid();
          this.clearServiceToBeProvided();
        }
      } catch (error) {
        console.log(error);
        return this.$toast.open({
          message: "Opa, deu erro!",
          type: "info",
        });
      }
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    async getBills() {
      try {
        const result = await ServiceBill.getAllBillsByParamsId(
          this.orderService.id,
          BillColumnsToSearchById.idNfse
        );

        this.billsSaved = result;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao buscar as parcelas",
          type: "info",
        });
      }
    },

    async deleteProductTable(id) {
      try {
        await ServiceNfseItem.delete(id, this.orderItem.idNfse);
        this.getItensForGrid();

        return this.$toast.open({
          message: "Serviço removido",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Não foi possível remover o item!",
          type: "error",
        });
      }
    },

    async getAllServices() {
      const data = await ServiceToBeProvided.getServicesFromSelectBox();
      this.services = data;
    },

    async getItensForGrid() {
      const dataCompra = await ServiceNfseItem.getItemForGrid(
        this.orderService.id
      );
      dataCompra.map((item) => {
        if (item.valorTotal == null) {
          item.valorTotal = item.valor;
        }
      });
      this.productsAdd = dataCompra;
      this.sumOfTotalValue();
    },

    async selectProductSetDefaultValue() {
      const currencyValueProduct = this.orderItem.idServico;
      const data = await ServiceToBeProvided.getItemForGrid(
        currencyValueProduct
      );
      this.orderItem.valor = data.valor;
      this.$refs.valor.valueInput = currencyValueProduct;
    },

    handleFocus(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
        this.isInvalidInput = "isInvalidInput";
      }
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    handleMasksDiscount(discount) {
      return this.discountIsChecked
        ? this.maskPercentage(discount)
        : this.maskCurrency(discount);
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    maskPercentage(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return `% ${parseFloat(valor)
        .toLocaleString(locale, {
          minimumFractionDigits: 2,
        })
        .replace(",", ".")}`;
    },

    calculateValue(event, keyName) {
      this.orderItem[keyName] = mascaraMoeda(event);
    },

    clearServiceToBeProvided() {
      this.orderItem.idServico = "";
      this.orderItem.valor = 0;
      this.orderItem.desconto = 0;
    },

    clear() {
      this.clearServiceToBeProvided();
      this.orderService.id = "";
      this.orderService.idCliente = "";
      this.orderService.idFuncionario = "";
      this.orderService.data_nfse = moment().format("YYYY-MM-DD");
      this.orderService.data_execucao = "";
      this.orderService.ambiente = "2";
      this.orderService.observacoes = "";
      this.orderService.referencia = "";
      this.orderService.status = TypeSituationNfse.pendente;
      this.orderService.response = null;

      this.productsAdd = [];
      this.billsSaved = [];
      this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE({});
      this.newOrder = !this.newOrder;
    },

    sumOfTotalValue() {
      const totalofValor = this.productsAdd.reduce(
        (oldValue, newValue) => oldValue + newValue.valorTotal,
        0
      );
      this.amount = parseFloat(totalofValor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    alterTypeDiscount() {
      this.orderItem.tipoDesconto = this.discountIsChecked
        ? TypeDiscount.porcentagem
        : TypeDiscount.valorMonetario;
    },

    async getConfig() {
      const result = await serviceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.orderService.ambiente = result.emissao_fiscal.ambiente;
      }
    },
  },
  watch: {
    provisionServiceCombobox() {
      this.getAllServices();
    },
  },
  computed: {
    ...mapState(["dataSearchFromProvisionService"]),
    handleDiscountIsEnable() {
      return this.productsAdd.length ? true : false;
    },
    handleValuesToModalReceive() {
      const totals = this.productsAdd.reduce(
        (prev, product) => {
          prev.subtotal = prev.subtotal + product.valor * product.quantidade;

          if (product.tipoDesconto === "Porcentagem") {
            const desc =
              ((product.valor * product.desconto) / 100) * product.quantidade;
            prev.desconto = prev.desconto + desc;
            prev.total = prev.total + product.valor * product.quantidade - desc;
          } else {
            prev.desconto = product.desconto + prev.desconto;
            prev.total =
              prev.total +
              product.valor * product.quantidade -
              product.desconto;
          }

          return prev;
        },
        {
          subtotal: 0,
          total: 0,
          desconto: 0,
        }
      );

      return totals;
    },
    handleEnableReceiveBtn() {
      return !this.orderService.id || !this.productsAdd.length ? true : false;
    },
    handleReferenceNumber() {
      return this.orderService.referencia?.trim();
    },
  },
  mounted() {
    this.getCustomersForSelectBox();
    this.getEmployeeForSelectBox();
    this.getAllServices();
    this.getConfig();

    if (
      this.dataSearchFromProvisionService &&
      Object.keys(this.dataSearchFromProvisionService).length
    ) {
      this.orderItem.idNfse = this.dataSearchFromProvisionService.id;
      Object.assign(this.orderService, this.dataSearchFromProvisionService);
      this.getItensForGrid();
    }

    if (this.orderService.id) {
      this.getBills();
    }
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE({});
  },
};
</script>

<style scoped >
.provisionService {
  display: flex;
  width: calc(100% - 30px);
  height: 100vh;
  font-size: 12px;
}

.contentFront {
  width: 45%;
  height: auto;
  margin-left: 40px;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.iconSelect {
  position: absolute;
  right: 0.9px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 28px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.screenProducts {
  align-items: center;
  background: white;
  cursor: pointer;
  display: flex;
  width: 2em;
  height: 2em;
  justify-content: center;
}

.fixedSelect {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 12px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 10;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.input-group {
  width: 100%;
}

.input-group-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContent {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentServiceAndDiscount {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentValueAndDiscount {
  width: 100%;
  display: flex;
  gap: 10px;
}

.rowObservation {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  height: 40px;
  position: relative;
}

.input-group-ob {
  width: 100%;
}

.input-group {
  width: 33.33%;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.user-label {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 12px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 10;
}

.infoValue {
  display: flex;
  align-items: center;
}

.formFront {
  width: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 10px;
  gap: 8px;
}

.content-form-btn {
  display: flex;
  width: 94%;
  justify-content: flex-end;
}

.form-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #fbfbfb;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 35%;
  height: 28px;
  margin-top: 90px;
  box-shadow: 0px 1px 1px 0px black;
}

.form-btn:hover {
  border: 1px solid green;
  color: green;
  background: whitesmoke;
}

.contentSketch {
  width: 50%;
  border-left: 2px solid #d5d3d3;
  height: auto;
  position: relative;
}

.tableProducts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 0 0 0;
  font-size: 13px;
  width: 100%;
  height: 55vh;
  max-height: 55vh;
  overflow: scroll;
  text-align: center;
  background: #fffcfc;
}

.trashDelete {
  color: red;
  cursor: pointer;
}

.selectService {
  width: 100%;
}

.styleTotal {
  display: flex;
  width: 100%;
  padding: 0 100px;
  background: #e3e9e6;
  font-size: 12px;
  height: 30px;
  gap: 2vw;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
}

tbody {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: scroll;
}

tr:nth-child(even) {
  background: white;
}

tr {
  background: #fffcfc;
  height: 30px;
}

.rowProduct {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f1efef;
}

.sketch-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #48b865;
  border: none;
  border-radius: 3px;
  width: 180px;
  height: 28px;
  gap: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.152);
}
.add-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 100, 250);
  color: rgb(2, 66, 241);
  border-radius: 3px;
  width: 180px;
  height: 28px;
  gap: 10px;
  box-shadow: 0px 1px 1px 0px black;
}

.content-sketch-btn {
  bottom: 50px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.content-clear-btn {
  width: 100%;
  padding: 0.7% 0px;
  left: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffff;
  z-index: 10;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.102);
}

.content-alert {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 0.5rem;
  color: red;
}

.btn-send-minimum-resolution {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  bottom: 0.25rem;
  cursor: pointer;
  background-color: #e7e6e6;
  font-weight: bold;
  color: #000000;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 60px !important;
  height: 70%;
}

.slider:before {
  position: absolute;
  content: "R$";
  height: 80%;
  width: 45%;
  left: 5%;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
  font-weight: bold;
}

.switch input:checked + .slider {
  background-color: #e7e6e6;
  color: rgb(0, 0, 0);
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
  font-weight: bold;
}

.switch input {
  display: none;
}

.content-switch {
  width: 35%;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

.contentDescription {
  width: 100%;
  position: relative;
}

.description {
  position: absolute;
  top: -2px;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 10;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 40px;
  padding: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
  font-size: 12px;
}

.contentTitleAndStatus {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.rowDates {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 460px) {
  .rowContent {
    flex-direction: column;
    gap: 1rem;
  }

  .formFront {
    gap: 0;
  }
}

@media screen and (max-width: 600px) {
  .rowContentServiceAndDiscount {
    flex-direction: column;
    gap: 20px;
  }

  .rowContentValueAndDiscount {
    width: 100% !important;
  }

  .selectService {
    width: 100%;
  }

  .content-switch {
    width: 45%;
  }

  .slider {
    height: 33%;
  }
}

@media screen and (max-width: 950px) {
  .provisionService {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .contentFront {
    width: 100%;
    margin: 0;
  }

  .contentSketch {
    width: 95%;
    border-left: none;
    border: 2px solid #d5d3d3;
    margin-top: 2rem;
    border-radius: 10px 10px 0 0;
    padding-top: 1rem;
  }

  .sketch-btn {
    width: 130px;
    margin: 0;
  }

  .sketch-btn:last-child {
    margin-right: 60px;
  }

  .btn-send-minimum-resolution {
    display: flex;
  }

  .contentFront {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formFront {
    margin-top: 10px;
    gap: 10px;
  }

  .add-btn {
    font-size: 12px;
    width: 120px;
    height: 26px;
  }
  .tableProducts {
    padding: 20px 0 0 0;
    font-size: 11px;
  }
}

@media screen and (max-width: 700px) {
  .content-clear-btn {
    width: auto;
    padding: 0.7% 0px;
    left: inherit;
    gap: 10px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>