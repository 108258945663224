<template>
  <div class="GeneralSales">
    <div class="ContainerBtnSales">
      <b-button
        class="BtnSales"
        style="background: #403b74"
        @click="handleModals('frenteDeCaixa')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Frente de Caixa</b-button
      >
      <b-button
        class="BtnSales"
        style="background: #3e66ae"
        @click="handleModals('orcamento')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Orçamento</b-button
      >

      <b-button
        class="BtnSales"
        style="background: #48b865"
        @click="handleModals('venda')"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova Venda</b-button
      >
    </div>
    <div class="lineSearch">
      <div class="searchByMonth">
        <div class="summaryMonthText" style="cursor: context-menu">
          Resumo Do Mês
        </div>
        <div class="pagMonth">
          <b-icon-chevron-left
            class="Arrows"
            id="btn-month-prev"
            @click="updateMonth('prev')"
          ></b-icon-chevron-left>
          <b-button
            class="month"
            size="sm"
            style="
              font-size: 11px;
              text-transform: capitalize;
              font-weight: bold;
              background: #f3f3f3;
            "
            variant="none"
            @click="filterSalePerMonth"
          >
            {{ monthName }} - {{ year }}
          </b-button>
          <b-icon-chevron-right
            class="Arrows"
            id="btn-month-next"
            @click="updateMonth('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="SummarySales">
      <button
        class="ItemSummarySales"
        @click="handleTypeSale(typeSale.frenteDeCaixa)"
        style="border: 3px ridge #403b74"
      >
        Frente de Caixa (Finalizadas) <br />
        {{ monthName }} - {{ year }}
        <br />
        {{
          totalMonth.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        }}
      </button>

      <button
        @click="handleTypeSale(typeSale.orcamento)"
        class="ItemSummarySales"
        style="border: 3px ridge #3e66ae"
      >
        Orçamentos (Em Aberto) <br />
        {{ monthName }} - {{ year }}
        <br />
        {{
          totalMonthBudget.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        }}
      </button>

      <button
        @click="handleTypeSale(typeSale.venda)"
        class="ItemSummarySales"
        style="border: 3px ridge #48b865"
      >
        Vendas (Aprovadas e Faturadas) <br />
        {{ monthName }} - {{ year }}
        <br />
        {{
          totalMonthSale.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        }}
      </button>
    </div>
    <div class="searchContainer">
      <div class="searchInputDate">
        <button class="btnPeriod" size="sm">Período</button>
        <div class="dateInput">
          <input
            type="date"
            id="date"
            v-model="startDate"
            style="
              padding: 0.25rem 0.25rem 0.25rem 0.5rem;
              cursor: pointer;
              border-left: none;
              border-radius: 0px 5px 5px 0px;
            "
          />
          <input
            type="date"
            id="date"
            v-model="endDate"
            style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer"
          />
        </div>
      </div>
      <div class="searchInput">
        <b-form-select
          class="searchFilter"
          size="sm"
          value-field="value"
          text-field="text"
          v-model="type"
          :options="filterCombobox"
        ></b-form-select>
        <div v-if="type === 'situacao'">
          <b-form-select
            class="searchFilterSituation custom-select2"
            value-field="value"
            text-field="text"
            v-model="textPesquisa"
            :options="filterSituationCombobox"
          ></b-form-select>
        </div>
        <div v-else-if="type === 'tipo'">
          <b-form-select
            class="searchFilterSituation custom-select2"
            value-field="value"
            text-field="text"
            v-model="textPesquisa"
            :options="filterSalePerType"
          ></b-form-select>
        </div>
        <div v-else>
          <b-input
            class="inputSearch"
            placeholder="Pesquisar por..."
            v-model="textPesquisa"
            @keyup.enter="filterSale(page)"
          ></b-input>
        </div>
        <b-button size="sm" @click="filterSale(page)" class="searchBtn">
          <b-icon-search></b-icon-search>
        </b-button>
      </div>
      <div class="searchInputAll" style="justify-content: flex-end">
        <button class="searchAll" @click="clearSearch()">ver tudo</button>
      </div>
    </div>
    <div class="ContentTable">
      <table>
        <thead class="headTable">
          <tr>
            <th>Data</th>
            <th style="width: 10%">Nº Operação</th>
            <th>Cliente</th>
            <th>Funcionário</th>
            <th>Status</th>
            <th>Situação</th>
            <th>Total</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody v-if="this.dataLength > 0" style="color: #3a4750">
          <tr
            style="text-align: center; height: 30px; cursor: context-menu"
            v-for="dataSearchSale in dataSale"
            :key="dataSearchSale.id"
          >
            <td>
              {{ dataSearchSale.data.split("-").reverse().join("/") }}
            </td>
            <td style="width: 10%">{{ dataSearchSale.numero }}</td>
            <td>
              {{ dataSearchSale.nomeCliente || "Consumidor Final" }}
            </td>
            <td>
              {{ dataSearchSale.nomeFuncionario || "--" }}
            </td>
            <td
              v-if="dataSearchSale.tipo === typeSale.frenteDeCaixa"
              class="typeSale"
            >
              {{ dataSearchSale.tipo }}
            </td>
            <td
              v-else-if="dataSearchSale.tipo === typeSale.venda"
              class="typeSale2"
            >
              {{ dataSearchSale.tipo }}
            </td>
            <td
              class="typeSale3"
              v-else-if="dataSearchSale.tipo === typeSale.orcamento"
            >
              {{ dataSearchSale.tipo }}
            </td>
            <td class="situationContainer">
              <div
                class="situationContent"
                v-if="dataSearchSale.situacao === 'Faturado(a)'"
              >
                <div class="situationBall" style="background: #3e66ae"></div>
                <span>
                  {{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}
                </span>
              </div>
              <div
                class="situationContent"
                v-if="dataSearchSale.situacao === 'Finalizado(a)'"
              >
                <div class="situationBall" style="background: #48b865"></div>
                <span>{{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span>
              </div>
              <div
                class="situationContent"
                v-if="dataSearchSale.situacao === 'Cancelado(a)'"
              >
                <div class="situationBall" style="background: #9f1313"></div>
                <span>
                  {{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span
                >
              </div>
              <div
                class="situationContent"
                v-if="dataSearchSale.situacao === 'Em aberto'"
              >
                <div class="situationBall" style="background: #c5c5c5"></div>
                <span>{{ dataSearchSale.situacao }}</span>
              </div>
              <div
                class="situationContent"
                v-if="dataSearchSale.situacao === 'Aprovado(a)'"
              >
                <div class="situationBall" style="background: orange"></div>
                <span>{{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span>
              </div>
            </td>

            <td>
              {{
                parseFloat(dataSearchSale.valorTotal).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>

            <td>
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  class="btnActions1"
                  variant="none"
                  size="sm"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item
                    v-if="dataSearchSale.situacao !== 'Faturado(a)'"
                    @click="editSales(dataSearchSale)"
                  >
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="
                      printCouponOrA4(dataSearchSale.tipo, dataSearchSale.id)
                    "
                  >
                    Imprimir
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="getIdSale(dataSearchSale.id)"
                    v-b-modal.modal-center3
                  >
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="sendCoupon(dataSearchSale.id)"
                    v-if="
                      dataSearchSale.tipo === typeSale.frenteDeCaixa &&
                      dataSearchSale.idNota === null &&
                      dataSearchSale.situacao === 'Finalizado(a)' &&
                      isStoneIntegration
                    "
                  >
                    Emitir NFC-e
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="findAndViewNote(dataSearchSale.idNota)"
                    v-if="
                      dataSearchSale.idNota !== null &&
                      dataSearchSale.situacao === 'Faturado(a)'
                    "
                  >
                    {{
                      dataSearchSale.tipo === typeSale.frenteDeCaixa
                        ? "Visualizar NFC-e"
                        : "Visualizar NF-e"
                    }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="
                      handleOpenModalAndFindFiscalDocument(
                        'modal-cancel',
                        dataSearchSale
                      )
                    "
                    v-if="
                      dataSearchSale.idNota !== null &&
                      dataSearchSale.situacao === 'Faturado(a)'
                    "
                  >
                    {{
                      dataSearchSale.tipo === typeSale.frenteDeCaixa
                        ? "Cancelar NFC-e"
                        : "Cancelar NF-e"
                    }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="findBillReportTicket(dataSearchSale.id)"
                    v-if="
                      dataSearchSale.tipo !== typeSale.orcamento &&
                      dataSearchSale.situacao === 'Finalizado(a)'
                    "
                  >
                    Gerar Carnê
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="
                      transformToNfe(dataSearchSale.id, dataSearchSale.tipo)
                    "
                    v-if="dataSearchSale.tipo === typeSale.venda"
                  >
                    Emitir NF-e
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="handleGenerateSale(dataSearchSale.id)"
                    v-if="dataSearchSale.tipo === typeSale.orcamento"
                  >
                    Gerar Venda
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="dataSearchSale.tipo !== typeSale.frenteDeCaixa"
                    @click="handleDuplicateSale(dataSearchSale)"
                  >
                    Clonar
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-center4
                    @click="getIdSaleDetails(dataSearchSale.id)"
                    v-if="
                      dataSearchSale.tipo === typeSale.venda ||
                      dataSearchSale.tipo === typeSale.frenteDeCaixa ||
                      dataSearchSale.tipo === typeSale.orcamento
                    "
                  >
                    Ver detalhes
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="7">
            <div class="defaultSearch">
              <!-- <img style="width: 25%" loading="lazy" :src="Search" /> -->
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <b-modal
      id="modal-center3"
      hide-footer
      hide-header
      centered
      title="Excluir"
    >
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal">
          <button class="btnContent" @click="deleteSale">Deletar</button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center3')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-cancel" hide-footer hide-header centered title="Excluir">
      <div>
        <h4 class="titleModal" style="text-align: center">
          Deseja cancelar essa {{ nameFiscalDocument }} ?
        </h4>
        <div class="btnModalCancel">
          <div class="contentMotivo">
            <label>Descreva o motivo do cancelamento:</label> <span>*</span>
            <b-form-textarea
              placeholder="Min: 15 caracteres"
              style="color: black; width: 100% !important"
              v-model="motivo"
            ></b-form-textarea>
          </div>
          <div class="contentBtns">
            <button class="btnContent" @click="cancelNota()">Cancelar</button>
            <button
              class="btnContentReceive"
              @click="$bvModal.hide('modal-cancel')"
            >
              Não, Obrigado!
            </button>
          </div>
        </div>
        <span class="alertCancel">
          O prazo máximo para cancelamento de uma {{ nameFiscalDocument }} é de
          até {{ textTimeToCancel }} após a concessão da autorização de uso.
        </span>
      </div>
    </b-modal>
    <b-modal
      id="modal-center4"
      hide-footer
      hide-header
      centered
      size="lg"
      title="Detalhes"
    >
      <div class="closeModalDetails" @click="$bvModal.hide('modal-center4')">
        X
      </div>
      <div class="contentModal">
        <span><b-icon-file-text></b-icon-file-text> Informações Básicas</span>
        <div class="rowModal">
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="this.detailsSales.data"
            />
            <label class="user-label"> Data</label>
          </div>
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="detailsSales.numero"
            />
            <label class="user-label"> Nº Operação </label>
          </div>
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="detailsSales.status"
            />
            <label class="user-label"> Status </label>
          </div>
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="detailsSales.nomeCliente"
            />
            <label class="user-label"> Cliente. </label>
          </div>
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="detailsSales.cpfCnpj"
            />
            <label class="user-label"> CPF/CNPJ. </label>
          </div>
          <div class="input-group-supplier">
            <input
              disabled
              class="input borderInput"
              v-model="detailsSales.nomeFuncionario"
            />
            <label class="user-label"> Funcionario. </label>
          </div>
        </div>
      </div>
      <div class="contentModal">
        <span><b-icon-box-seam></b-icon-box-seam>Produtos</span>
        <div class="rowModal">
          <table style="border-spacing: 1px 0; width: 100%">
            <thead
              style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
              "
            >
              <tr>
                <th style="width: 10%">Nome</th>
                <th style="width: 10%">Tipo</th>
                <th style="width: 10%">Observação</th>
                <th style="width: 10%">Qtd</th>
                <th style="width: 10%">Valor Unitário</th>
                <th style="width: 10%">Desconto</th>
                <th style="width: 10%">Valor Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="detailsSale in this.detailsSales.products"
                :key="detailsSale.id"
                style="
                  text-align: center;
                  height: 60px;
                  border-bottom: 1px solid rgb(218, 218, 218);
                "
              >
                <td>
                  {{ detailsSale.nome }}
                </td>
                <td>
                  {{ detailsSale.tipoProduto }}
                </td>
                <td>{{ detailsSale.dadosAdicionais || "--" }}</td>
                <td style="width: 10%">
                  {{ detailsSale.quantidade }}
                </td>
                <td>
                  {{ detailsSale.valorUnitario }}
                </td>
                <td>
                  {{ detailsSale.desconto || "--" }}
                </td>
                <td>
                  {{ detailsSale.valorTotal }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="contentModal" v-if="this.detailsSales.status === 'Venda'">
        <span
          ><b-icon-credit-card-fill></b-icon-credit-card-fill> Duplicatas</span
        >
        <div class="ContentTableBill">
          <div class="noContentTable" v-if="this.detailsSales.bills == ''">
            Não há duplicatas pagas registradas!
          </div>
          <table style="border-spacing: 1px 0; width: 100%" v-else>
            <thead
              style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
              "
            >
              <tr>
                <th style="width: 10%">Data</th>
                <th style="width: 10%">Data Pagamento</th>
                <th style="width: 10%">Conta</th>
                <th style="width: 10%">Tipo</th>
                <th style="width: 10%">Status</th>
                <th style="width: 10%">Forma de Pagamento</th>
                <th style="width: 10%">Vl Total</th>
                <th style="width: 10%">Vl Pago</th>
                <th style="width: 6%">Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="dataBill in this.detailsSales.bills"
                :key="dataBill.id"
                style="
                  text-align: center;
                  height: 60px;
                  border-bottom: 1px solid rgb(218, 218, 218);
                "
              >
                <td>
                  {{
                    dataBill.data.split(" ")[0].split("-").reverse().join("/")
                  }}
                </td>
                <td v-if="dataBill.dataPagamento">
                  {{
                    dataBill.dataPagamento
                      .split(" ")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  }}
                </td>
                <td v-else>--</td>
                <td>{{ dataBill.nomeConta }}</td>
                <td>{{ dataBill.tipo }}</td>
                <td class="contentStatus" v-if="!dataBill.dataPagamento">
                  <b-icon-x-circle-fill
                    class="statusPaymentOpen"
                  ></b-icon-x-circle-fill>
                  <span style="width: 50%">Em aberto</span>
                </td>
                <td class="contentStatus" v-else>
                  <b-icon-check-circle-fill
                    class="statusPayment"
                  ></b-icon-check-circle-fill>
                  <span style="width: 50%">Pago</span>
                </td>
                <td v-if="dataBill.dataPagamento">
                  {{ dataBill.tipoFormaPagamento }}
                </td>
                <td v-else>--</td>
                <td>
                  {{
                    dataBill.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  {{
                    dataBill.valorPago.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  <b-icon-x-circle
                    scale="1.5"
                    class="delBill"
                    @click="deleteBill(dataBill.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <div class="setBtn">
      <button class="itemBtn" :disabled="page == 1" @click="previousPage(page)">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        class="itemBtn"
        :disabled="this.dataLength < 9"
        @click="nextPage(page)"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
    <div :class="sizeModal">
      <div :class="modalContainer" v-if="colapseModal">
        <b-button class="closeModalSales" @click="modalHeight"> X </b-button>
        <BoxFront
          v-if="this.typeDocument.frenteDeCaixa"
          style="margin-top: 30px"
        />

        <Sale v-if="this.typeDocument.venda" />

        <Budget v-if="this.typeDocument.orcamento" />
      </div>
    </div>
    <Loading v-if="spinLoading" :text="textToModalLoading" />
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { mapMutations, mapState } from "vuex";
import api from "../../services/axios";

import Sale from "../Sale/Index-Sale.vue";
import BoxFront from "../../components/BoxFront/Index-BoxFront.vue";
import Budget from "../Budget/Index-Budget.vue";
import Loading from "../Loading/Loading.vue";

import ServiceSale from "../../services/serviceSale";
import ServiceBill from "../../services/serviceBill";
import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceSituation from "../../services/serviceSituation";

import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { TypeSale } from "../../utils/enums/SalesType";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { TypesOfSales } from "../../utils/enums/TypesOfSales";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
import printReport from "../../reports/sale";
import { formatValuesFromSales } from "../../helpers/generalSales";

export default {
  components: {
    BoxFront,
    Sale,
    Budget,
    Loading,
  },
  props: {
    initialMonth: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      year: DateTime.local().year,
      month: DateTime.local(),
      typeSale: TypeSale,
      spinLoading: false,
      spinGeneral: false,
      textPesquisa: "",
      type: "",
      typeOfSales: TypesOfSales.normal,
      typeSituation: "",
      startDate: "",
      endDate: "",
      colapseModal: false,
      modalContainer: "contentModalDisable",
      dataSale: [],
      totalMonth: 0,
      totalMonthBudget: 0,
      totalMonthSale: 0,
      dataBills: "",
      page: 1,
      dataLength: 0,
      filterCombobox: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "nomeFuncionario", text: "Funcionário" },
        { value: "numero", text: "Nº Operação" },
        { value: "tipo", text: "Status" },
        { value: "situacao", text: "Situação" },
        { value: "", text: "Selecione..." },
      ],
      filterSituationCombobox: [
        { value: "Cancelado(a)", text: "Cancelado" },
        { value: "Em aberto", text: "Em aberto" },
        { value: "Aprovado(a)", text: "Aprovado" },
        { value: "Finalizado(a)", text: "Finalizado" },
        { value: "Faturado(a)", text: "Faturado" },
        { value: "", text: " Clique para selecionar a situação " },
      ],
      filterSalePerType: [
        { value: "Frente De Caixa", text: "Frente De Caixa" },
        { value: "Venda", text: "Venda" },
        { value: "Orçamento", text: "Orçamento" },
        { value: "", text: " Clique para selecionar o Status " },
      ],
      dataSaleUnique: "",
      detailsSales: "",
      returnDataNote: null,
      motivo: "",
      responseNfeWebMania: {
        chave: "",
        danfe: "",
        danfe_etiqueta: "",
        danfe_simples: "",
        log: "",
        modelo: "",
        motivo: "",
        nfe: "",
        serie: "",
        status: "",
        uuid: "",
        xml: "",
      },
      itemSelectedInTableSearch: null,
      textToModalLoading: "",
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "ALTER_TYPE_DOCUMENT",
      "SET_DATA_SEARCH_FROM_BOX_FRONT",
      "SET_DATA_SEARCH_FROM_SALE",
      "SET_DATA_NFE",
      "DATA_BUDGET_FOR_SALE",
    ]),
    getIdSale(idSale) {
      this.dataSaleUnique = idSale;
    },

    async getIdSaleDetails(idSale) {
      this.detailsSales = await this.findCompletedSale(idSale);
      this.dataSaleUnique = idSale;

      const dataSalesFormated = this.detailsSales.data
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("/");

      this.detailsSales.data = dataSalesFormated;
    },

    async findBill() {
      this.detailsSales.bills = await ServiceBill.getAllBillsByParamsId(
        this.dataSaleUnique,
        BillColumnsToSearchById.idVenda
      );
    },

    async findBillReportTicket(idSale) {
      const token = localStorage.getItem("token");

      this.dataSaleUnique = idSale;
      window.open(
        `${process.env.VUE_APP_URL_BACKEND}/bills/print/report/ticket/all/bills/and/format/carne/completed/with/pix/one/${this.dataSaleUnique}?auth=${token}`
      );
    },

    async deleteBill(id) {
      await ServiceBill.deleteAllRelationshipsFromDuplicate(id);

      this.findBill();
    },

    async deleteSale() {
      try {
        this.spinGeneral = true;
        await ServiceSale.deleteAll(this.dataSaleUnique);

        await this.filterSale(1);
        await this.filterSalePerMonth();
        this.$bvModal.hide("modal-center3");
        return this.$toast.open({
          message: "Venda removida com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateMessage(error, this.$toast);
      } finally {
        this.spinGeneral = false;
      }
    },

    async filterSale(page) {
      try {
        this.spinGeneral = true;
        const { data } = await api.get(
          `/sales?q=${this.textPesquisa}&type=${this.type}&page=${page}&startDate=${this.startDate}&endDate=${this.endDate}`
        );

        if (!Object.keys(data).length) {
          return;
        }

        this.dataLength = data.data.length;
        this.dataSale = data.data;
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar informações",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },

    handleTypeSale(name) {
      this.type = "tipo";
      this.textPesquisa = name;

      this.filterSale(1);
    },

    async filterSalePerMonth() {
      try {
        const result = await ServiceSale.filterSalePerMonth(
          this.month.month,
          this.year
        );

        if (result && Object.keys(result).length) {
          this.totalMonth = result.totalBoxFront;
          this.totalMonthSale = result.totalSale;
          this.totalMonthBudget = result.totalBudget;
        }
      } catch (error) {
        console.log(error);
      }
    },

    nextPage() {
      this.filterSale((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.filterSale((this.page -= 1));
      }
    },

    async updateMonth(direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.month.year;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.month.year;
      }

      this.clearSumMonth();
      await this.filterSalePerMonth();
    },

    async modalHeight() {
      this.colapseModal = !this.colapseModal;
      this.colapseModal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");
      this.modalContainer = this.modalContainer
        ? "modalContainer"
        : "contentModalDisable";

      if (!this.colapseModal && this.sizeModal === "disable") {
        this.SET_DATA_SEARCH_FROM_BOX_FRONT({});
        this.SET_DATA_SEARCH_FROM_SALE({});
      }
      await this.filterSalePerMonth();
      this.filterSale(1);
    },

    async editSales(tableRowData) {
      const data = await this.searchTableDataForSale(tableRowData.id);

      if (data && Object.keys(data).length) {
        if (tableRowData.tipo === this.typeSale.frenteDeCaixa) {
          this.handleEditBoxFront(tableRowData.tipo, data);
        } else if (tableRowData.tipo === this.typeSale.venda) {
          this.handleEditSale(tableRowData.tipo, data);
        } else if (tableRowData.tipo === this.typeSale.orcamento) {
          this.handleEditBudget(tableRowData.tipo, data);
        }
      }
    },

    async searchTableDataForSale(idVenda) {
      try {
        const { data } = await api.get(`/sales/${idVenda}`);
        return data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao editar",
          type: "info",
        });
      }
    },

    async findCompletedSale(idVenda) {
      try {
        const { data } = await api.get(`/sales/details/${idVenda}`);
        return data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao editar",
          type: "info",
        });
      }
    },

    handleModals(name) {
      this.ALTER_TYPE_DOCUMENT(name);

      this.modalHeight();
    },

    handleEditBoxFront(typeDocument, data) {
      const formatedBoxFront = formatValuesFromSales(typeDocument, data);
      this.SET_DATA_SEARCH_FROM_BOX_FRONT(formatedBoxFront);

      const nameDocument = "frenteDeCaixa";
      this.ALTER_TYPE_DOCUMENT(nameDocument);
      this.modalHeight();
    },

    handleEditSale(typeDocument, data) {
      const formatedSale = formatValuesFromSales(typeDocument, data);

      this.SET_DATA_SEARCH_FROM_SALE(formatedSale);

      const nameDocument = "venda";
      this.ALTER_TYPE_DOCUMENT(nameDocument);
      this.modalHeight();
    },

    handleEditBudget(typeDocument, data) {
      const formatedSale = formatValuesFromSales(typeDocument, data);
      this.SET_DATA_SEARCH_FROM_SALE(formatedSale);

      const nameDocument = "orcamento";
      this.ALTER_TYPE_DOCUMENT(nameDocument);
      this.modalHeight();
    },

    clearSumMonth() {
      this.totalMonth = "R$ 0,00";
      this.totalMonthSale = "R$ 0,00";
      this.totalMonthBudget = "R$ 0,00";
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
      this.page = 1;
      this.filterSale();
    },

    async transformToNfe(idSale, typeSale) {
      try {
        let dataNFe;
        if (typeSale === this.typeSale.venda) {
          dataNFe = await ServiceSale.transformWithNota(idSale);

          this.SET_DATA_NFE(dataNFe);

          this.$toast.open({
            message: "Venda transformada em nota fiscal!",
            type: "success",
          });

          this.$router.push({
            path: `/fiscal`,
          });
        }
      } catch (error) {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message?.includes("Validation failed")
        ) {
          return toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
        }

        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async handleDuplicateSale(dataSearchSale) {
      const data = await this.formatValuesToDuplicate(dataSearchSale.id);

      if (Object.keys(data).length) {
        data.numero = "";

        if (dataSearchSale.tipo === this.typeSale.venda) {
          this.handleEditSale(dataSearchSale.tipo, data);
        } else if (dataSearchSale.tipo === this.typeSale.orcamento) {
          this.handleEditBudget(dataSearchSale.tipo, data);
        }
      }

      return;
    },

    async formatValuesToDuplicate(idSale) {
      const data = await this.searchTableDataForSale(idSale);

      const newProductsSale = data.products.map((prod) => {
        prod.id = "";
        prod.idVenda = "";

        return prod;
      });

      data.id = "";
      data.products = newProductsSale;
      data.bills = [];

      return data;
    },

    async sendCoupon(idSale) {
      try {
        this.spinLoading = true;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo emitido !";

        const data = await ServiceNotaFiscal.sendCoupon(idSale);
        this.responseNfeWebMania.danfe = await data.dataWebMania.danfe;
        this.visualizarNfe(this.responseNfeWebMania.danfe);

        await this.filterSale(1);
        return this.$toast.open({
          message: "Cupom Fiscal emitido com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ops! Houve algum erro. Verifique os dados e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "";
      }
    },

    visualizarNfe(danfe) {
      window.open(danfe, "_blank", "width=700, height=800, top=500, left=500");
    },

    async findNotaById(idNota) {
      this.returnDataNote = await ServiceNotaFiscal.findNotaById(idNota);
    },

    async handleGenerateSale(idBudget) {
      const budget = await this.searchTableDataForSale(idBudget);

      const situation = await ServiceSituation.findById(budget.idSituacao);

      if (situation.nome === SaleTypeSituation.cancelado) {
        return this.$toast.open({
          message:
            "Não é possível gerar uma venda a partir de um orçamento cancelado",
          type: "info",
        });
      }

      const newDataBudget = this.alterValuesBudgetBeforeTransformInSale(budget);

      this.generateSale(newDataBudget);
    },

    alterValuesBudgetBeforeTransformInSale(budget) {
      Reflect.deleteProperty(budget, "idEmpresa");
      Reflect.deleteProperty(budget, "bills");
      Reflect.deleteProperty(budget, "createdAt");
      Reflect.deleteProperty(budget, "updatedAt");

      if (budget.products.length) {
        const formattedProducts = budget.products.map((prod) => {
          Reflect.deleteProperty(prod, "idEmpresa");
          Reflect.deleteProperty(prod, "idFornecedor");
          Reflect.deleteProperty(prod, "nome");

          return {
            ...prod,
            idList: uuidv4(),
          };
        });

        budget.products = formattedProducts;
      }

      return budget;
    },

    generateSale(newDataBudget) {
      const nameDocument = "venda";
      this.DATA_BUDGET_FOR_SALE(newDataBudget);
      this.ALTER_TYPE_DOCUMENT(nameDocument);
      this.modalHeight();
    },

    async findAndViewNote(idNota) {
      const notaReturn = await ServiceNotaFiscal.findNotaById(idNota);
      if (notaReturn.status === "Não Enviada") {
        this.$toast.open({
          message:
            "Houve um erro! Verifique se o documento foi emitido e tente novamente",
          type: "info",
        });
      } else {
        this.visualizarNfe(notaReturn.response.danfe);
      }
    },

    async cancelNota() {
      try {
        const dataCancelamento = {
          uuid: this.returnDataNote.response.uuid,
          motivo: this.motivo,
        };
        this.spinLoading = true;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo cancelado !";

        await ServiceNotaFiscal.cancelNota(
          dataCancelamento,
          this.returnDataNote.id
        );

        this.$toast.open({
          message: "Nota Cancelada!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Houve um erro! Verifique e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "";
        this.motivo = "";
      }
    },

    printCouponOrA4(type, idSale) {
      if (type === this.typeSale.frenteDeCaixa) {
        this.printReportCoupon(idSale);
      } else {
        this.printReportVendaOrcamento(idSale);
      }
    },

    printReportCoupon(idSale) {
      printReport("reportCoupon", idSale);
    },

    printReportVendaOrcamento(idSale) {
      printReport("reportVendaOrcamento", idSale);
    },

    async handleOpenModalAndFindFiscalDocument(nameModal, dataSearchSale) {
      this.itemSelectedInTableSearch = dataSearchSale;
      this.findNotaById(dataSearchSale.idNota);
      this.openModal(nameModal);
    },

    openModal(nameModal) {
      this.$bvModal.show(nameModal);
    },
  },
  computed: {
    ...mapState({
      sizeModal: (state) => state.sizeModal,
      typeDocument: (state) => state.typeDocument,
    }),
    monthName() {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
    nameFiscalDocument() {
      if (
        this.itemSelectedInTableSearch &&
        this.itemSelectedInTableSearch?.tipo === this.typeSale.frenteDeCaixa
      ) {
        return "NFC-e";
      } else {
        return "NF-e";
      }
    },
    textTimeToCancel() {
      if (
        this.itemSelectedInTableSearch &&
        this.itemSelectedInTableSearch?.tipo === this.typeSale.frenteDeCaixa
      ) {
        return "30 MINUTOS";
      } else {
        return "24 HORAS";
      }
    },
    isStoneIntegration() {
      if (
        this.dataCompany?.liberars[0]?.integracoes &&
        Object.keys(this.dataCompany?.liberars[0]?.integracoes).length
      ) {
        const stone =
          this.dataCompany?.liberars[0]?.integracoes?.cardMachines?.find(
            (machine) => machine.text === "STONE"
          );

        return stone?.value;
      }

      return false;
    },
  },
  async created() {
    this.filterSale(1);
    await this.filterSalePerMonth();
    this.ALTER_SIZE_MODAL("disable");
  },
  beforeDestroy() {
    this.ALTER_SIZE_MODAL("disable");
  },
};
</script>
<style scoped>
.GeneralSales {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 12px;
}

.ContainerBtnSales {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  margin-top: 70px;
  width: 100%;
  gap: 1rem;
}

.BtnSales {
  width: 30%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.lineSearch {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.searchByMonth {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 20px;
  border-radius: 5px;
  font-size: 11px;
  justify-content: space-around;
  border: 1px solid #e3e0e0;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchFilterSituation {
  width: 230px;
  border-left: none !important;
  height: 30px;
  border-radius: 0px !important;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  position: relative !important;
}

.searchFilterSituation:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.disableInput {
  display: none;
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
  font-weight: 600;
}

.custom-select2 {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  width: 230px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.searchInput {
  width: 30%;
  display: flex;
}

.searchInputDate {
  width: 30%;
  display: flex;
}

.searchInputAll {
  width: 30%;
  display: flex;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.searchContainer {
  display: flex;
  height: 90px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  display: block;
  width: 230px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  height: 30px;
}

.dateInput {
  display: flex;
  gap: 10px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.Arrows {
  border-radius: 5px;
  width: 15px;
  height: 15px;
}

.Arrows:hover {
  background: whitesmoke;
}

.SummarySales {
  display: flex;
  height: 90px;
  width: 100%;
  gap: 0.3rem;
  margin-top: 10px;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e3e3e3;
}

.ItemSummarySales {
  width: 30%;
  height: 70px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.ItemSummarySales:hover {
  background: #f7f7f7;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px 0 20px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 30px;
}

.ContentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.noContentTable {
  background: #edebeb;
  padding: 10px;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.contentStatus span {
  cursor: context-menu;
}

.statusPayment {
  background: #eff0f0;
  color: green;
}

.statusPaymentOpen {
  background: #eff0f0;
  color: rgb(132, 132, 132);
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.titleModal {
  font-size: 1rem;
  border-bottom: 1px solid #e6e7e8;
  font-weight: bold;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.closeModalBill:hover {
  color: red;
}

.btnModal {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnModalCancel {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentBtns {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.contentMotivo {
  font-size: 13px;
  margin-bottom: 20px;
  padding: 10px;
}

.contentMotivo label {
  position: relative;
  top: 10px;
}

.contentMotivo textarea:focus {
  box-shadow: none;
  border: 1px solid rgb(97, 95, 95);
}

.contentMotivo span {
  position: relative;
  top: 10px;
  color: red;
  cursor: context-menu;
}

.contentMotivo ::placeholder {
  font-size: 12px;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.contentModal {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowModal {
  display: flex;
  width: 100%;
  padding: 20px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.input-group-supplier {
  width: 45%;
  position: relative;
}

.user-label {
  position: absolute;
  left: 15px;
  font-size: 11px;
  z-index: 30;
  bottom: 21px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.closeModalDetails {
  width: 22px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -2px;
  top: -1px;
  color: red;
  border-radius: 2px;
  cursor: pointer;
  transition: ease-in all 0.2s;
  font-size: 18px;
}

.alertCancel {
  font-size: 10px;
  color: rgb(121, 3, 3);
  text-align: center;
  display: flex;
  margin-bottom: -10px;
  cursor: context-menu;
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  border: none;
  cursor: pointer;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModalSales {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalSales:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnActions1:hover {
  background: rgb(215, 213, 213);
  color: white;
}

.btnActions1 {
  border-radius: 10px;
}

.contentActions {
  color: rgb(82, 78, 78);
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.typeSale {
  color: #403b74;
  font-weight: bold;
}

.typeSale2 {
  color: #48b865;
  font-weight: bold;
}

.typeSale3 {
  color: #3e66ae;
  font-weight: bold;
}

.typeSale4 {
  color: rgb(34, 33, 36);
  font-weight: bold;
}

.typeSale5 {
  color: #f2a83e;
  font-weight: bold;
}

.situationBall {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid rgb(31, 30, 30);
}

.situationContent {
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: flex-start;
  font-weight: bold;
}

.situationContent span {
  margin: 0 0 0 10px;

  font-weight: 600;
}

.situationContainer {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.summaryMonthText {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.month {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

@media screen and (min-width: 758px) and (max-width: 1200px) {
  .GeneralSales {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }

  .searchByMonth {
    width: 40%;
    margin: 10px 0px 0px 10px;
  }

  .form-control {
    width: 140px;
  }

  .ItemSummarySales {
    height: 60px;
    font-size: 11px;
  }

  .SummarySales {
    height: 100px;
    padding-bottom: 10px;
  }

  .BtnSales {
    height: 30px;
    font-size: 11px;
  }

  .searchAll {
    font-size: 11px;
    padding: 5px 9px;
  }

  .searchInputAll {
    width: 18%;
  }

  .searchInputDate {
    width: 45%;
  }

  .btnPeriod {
    width: 60px;
    font-size: 10px;
  }

  .searchFilter {
    width: 110px;
    font-size: 10px;
  }

  .searchContainer {
    height: 70px;
  }

  .setBtn {
    margin: 0 1rem 10px 0;
  }

  .itemBtn {
    width: 18px;
    height: 18px;
  }

  .centerItemBtn {
    width: 18px;
    height: 18px;
  }

  .headTable {
    height: 25px;
  }

  .ContentTable {
    padding: 0px 20px 0 20px;
  }
}

@media screen and (max-width: 700px) {
  .GeneralSales {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100vh;
    font-size: 10px;
  }

  .ContainerBtnSales {
    margin-top: 55px;
    flex-wrap: wrap;
  }

  .lineSearch {
    display: flex;
    align-items: center;
    margin-left: 0px;
    justify-content: center;
  }

  .searchContainer {
    display: none;
  }

  .BtnSales {
    height: 70px;
  }

  .searchByMonth {
    width: 80%;
    margin: 20px 0px 0px 0px;
  }

  .ItemSummarySales {
    height: 100px;
  }

  .ContentTable {
    height: auto;
    padding: 10px 10px 0 10px;
  }

  .ModalGeneral {
    position: fixed !important;
    z-index: 900;
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }
}
</style>