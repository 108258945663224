export default function printReport(name, id) {
  const token = localStorage.getItem("token");

  if (name === "reportVendaOrcamento") {
    reportVendaOrcamento(id, token)
  } else if (name === "reportCoupon") {
    reportCoupon(id, token)
  } else if (name === "reportVendaOrcamentoMtq") {
    reportVendaOrcamentoMtq(id, token)
  } else if (name === "reportVendaOrcamentoMtqAmbiente") {
    reportVendaOrcamentoMtqAmbiente(id, token)
  }
  else if (name === "proofOfAcquittance") {
    reportProofOfAcquittance(id, token)
  } else if (name === "acquittance") {
    printAcquittance(id, token)
  } else if (name === "reportOrder") {
    reportOrder(id, token)
  } else {
    console.log('invalid name')
  }
}

function reportVendaOrcamento(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/${id}?auth=${token}`
  );
}
function reportVendaOrcamentoMtq(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/${id}?auth=${token}`
  );
}
function reportVendaOrcamentoMtqAmbiente(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/ambiente/${id}?auth=${token}`
  );
}
function reportOrder(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/nfse/gerar/relatorio/${id}?auth=${token}`
  );
}
function reportCoupon(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/coupon/venda/${id}?auth=${token}`
  );
}
function reportProofOfAcquittance(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/bills/generate/print/proof/of/acquittance/A4/${id}?auth=${token}`
  );
}
function printAcquittance(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/safe/look/for/print/transaction/receipt/cashier/${id}?auth=${token}`
  );
}