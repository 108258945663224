import { mapState, mapMutations } from "vuex";
import Permission from "../../components/Permissions/Permissions.vue"
import ManagementCompany from "../../components/Settings/ManagementCompany.vue"
import Settings from "../../components/Settings/Settings.vue"
import LogsScreen from "../../components/Settings/LogsScreen.vue"
import InputPassword from "../../components/UI/CustomInputs/InputPassword.vue"
import InputMoney from "../../components/UI/CustomInputs/InputMoney.vue";
import InputPercentage from "../../components/UI/CustomInputs/InputPercentage.vue";

import ServiceCompany from "../../services/serviceCompany";

import { convertFileToBase64 } from "../../utils/convertFileToBase64"
import { resumeTypes } from "../../common/index"
import { typesKeyPix } from "../../common/index"

import alert from "../../assets/alert.svg";
import api from "../../services/axios";

const mixinSettingsCompany = {
  components: {
    Permission,
    ManagementCompany,
    Settings,
    LogsScreen,
    InputMoney,
    InputPercentage,
    InputPassword
  },
  data() {
    return {
      imgBase64: null,
      textLogo: "Click me",
      classImg: "logo_img",
      alert: alert,
      imgFromBackend: null,
      colapseModal: false,
      modalContainer: "contentModalDisable",
      ambiente: [
        { text: "Produção", value: "1" },
        { text: "Homologação", value: "2" },
      ],
      certificateExpirationTime: null,
      fileName: null,
      isEnableUpdateCertificate: false,
      invalidPayloadText: '',
      isLoading: false,
      objectPlan: {},
      resumeTypes: resumeTypes,
      typesKeyPix: typesKeyPix,
      isInvalidInput: "",
      spinGeneral: false
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "ALTER_TYPE_SETTINGS",
      "SET_DATA_CONFIG"
    ]),
    async selectImage(e) {
      this.$store.commit("DATA_COMPANY", { ...this.dataCompany, urlImage: "" })
      const base64 = await convertFileToBase64(e.target.files[0]);
      this.imgBase64 = base64;

      this.imgFromBackend = e.target.files[0];
      this.classImg = "logo_img_not";
    },

    async update() {
      try {
        if (!this.imgFromBackend) {
          await ServiceCompany.update(this.dataCompany)
          return this.$toast.open({
            message: "Dados atualizado com sucesso!",
            type: "success",
          });
        }

        const formData = new FormData();
        formData.append("image", this.imgFromBackend);

        const result = await ServiceCompany.uploadImage(formData);
        await ServiceCompany.update({ ...this.dataCompany, urlImage: result.urlImage })
        this.$store.commit("DATA_COMPANY", { ...this.dataCompany, urlImage: result.urlImage })

        return this.$toast.open({
          message: "Dados atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: error.response.data.message || "Ocorreu um erro ao salvar a imagem",
          type: "info",
        });
      }
    },

    async fetchCep(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const { data } = await api.get(`https://brasilapi.com.br/api/cep/v1/${formatedCep}`);

        this.company = { key: 'endereco', value: data.street }
        this.company = { key: 'bairro', value: data.neighborhood }
        this.company = { key: 'cidade', value: data.city }
        this.company = { key: 'uf', value: data.state }
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },

    handleFocus(refName) {
      this.$refs[refName].focus();

      this.isInvalidInput = "isInvalidInput";
    },

    cancelUpdateCertificate() {
      this.isEnableUpdateCertificate = false
      this.invalidPayloadText = ""
    },

    handleModals(name) {
      this.ALTER_TYPE_SETTINGS(name)

      this.modalHeight();
    },

    verifyPlanTester() {
      if (this.objectPlan.tipoPlano !== 'tester') {
        this.handleModals('accessControl')
      } else {
        this.$bvModal.show("modal-tester");
      }
    },

    redirectWhatsApp() {
      const phoneNumber = "3138919414";
      const whatsappUrl = `https://wa.me/${phoneNumber.replace(/\D/g, "")}`;
      window.open(whatsappUrl, "_blank");
    },

    async findPlan() {
      const data = await api.get("/plans/verify");
      this.objectPlan = data.data;
      this.verifyPlanTester();
    },

    modalHeight() {
      this.colapseModal = !this.colapseModal;
      this.colapseModal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");
      this.modalContainer = this.modalContainer
        ? "modalContainer"
        : "contentModalDisable";
    },

    openModals(name) {
      this.$bvModal.show(name)
    },


    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    enableSendCertificate() {
      this.isEnableUpdateCertificate = true
    },

    getNameFile() {
      if (this.fileName) {
        return this.fileName;
      }

      return "Ou arraste e solte aqui";
    },
  },
  computed: {
    ...mapState({
      dataCompany: (state) => state.dataCompany,
      sizeModal: (state) => state.sizeModal,
      enableModalsSettings: (state) => state.enableModalsSettings,
      dataConfig: (state) => state.dataConfig
    }),

    company: {
      get() {
        return this.dataCompany || {}
      },
      set(company) {
        return this.$store.commit("DATA_COMPANY", { ...this.dataCompany, [company.key]: company.value })
      }
    },
    handleVerifyUserLevel() {
      const userLevel = localStorage.getItem("userLevel");

      if (userLevel === "adm") {
        return true
      } else {
        return false
      }
    },
    maskCelular() {
      return "(##) #####-####";
    },
    maskTelefone() {
      return "(##) ####-####";
    },
    maskCep() {
      return "#####-###";
    },
  },
  mounted() {
    this.colapseModal = false;
    this.ALTER_SIZE_MODAL("disable")
  },
  beforeDestroy() {
    this.ALTER_SIZE_MODAL("disable");
  }
}

export default mixinSettingsCompany