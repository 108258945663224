import moment from "moment";
import { mapState, mapMutations } from "vuex";

import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue";
import InputMoney from "../../components/UI/CustomInputs/InputMoney.vue";
import ModalConductor from "../../components/Modals/ModalCreateConductor.vue";
import ModalVehicle from "../../components/Modals/ModalCreateVehicle.vue";

import { mascaraMoeda } from "../../utils/maskMoney";
import { ValidadeMdfe } from "../../validators/mdfe";
import { ufs } from "../../common/index";

import ServiceMdfe from "../../services/serviceMdfe";
import ServiceVehicle from "../../services/serviceVehicle";
import ServiceConductor from "../../services/serviceConductor";
import ServiceSettings from "../../services/serviceSettings";

const mixinMdfe = {
  components: {
    InputSelect,
    InputMoney,
    ModalConductor,
    ModalVehicle,
  },
  data() {
    return {
      cep_carregamento: "",
      cep_descarregamento: "",
      isInvalidInput: "",
      carregamento:
      {
        codigo_municipio: "",
        nome_municipio: "",
      },
      descarregamento:
      {
        codigo_municipio: "",
        nome_municipio: "",
        documentos_fiscais: [{ chave: "" }]
      },
      chave: "",
      ambiente: [
        { value: 1, text: "Produção" },
        { value: 2, text: "Homologação" },
      ],
      emitente: [
        { value: 1, text: "Prestador de serviço de transporte." },
        { value: 2, text: "Transportador de Carga Própria." },
        { value: 3, text: "Prestador de serviço de transporte que emitirá CT-e Globalizado." },
      ],
      transportador: [
        { value: 1, text: "ETC - Empresa de Transporte de Cargas." },
        { value: 2, text: "TAC - Transportador Autônomo de Cargas." },
        { value: 3, text: "CTC - Cooperativa de Transporte de Cargas." },
      ],
      tipo_proprietario: [
        { value: "0", text: "TAC – Agregado" },
        { value: "1", text: "TAC Independente" },
        { value: "2", text: "Outros" },
      ],
      unidade: [
        { value: "01", text: "KG" },
        { value: "02", text: "TON" },
      ],
      conductor: [],
      vehicle: [],
      dadosMdfe: {
        id: "",
        data_mdfe: moment().format('YYYY-MM-DD HH:mm'),
        ambiente: "",
        emitente: "",
        url_notificacao: "",
        response: "",
        status: "",
        unidade: "",
        modalidade: 1,
        uf_carregamento: "",
        uf_descarregamento: "",
        valor_carga: 0,
        peso_bruto: 0,
        carregamento: [],
        descarregamento: [],

        rodoviario: {
          rntrc: "",
          veiculo_tracao: {
            placa: "",
            tara: 0,
            uf_licenciamento: "",
            tipo_rodado: "",
            tipo_carroceria: "",
          },
          condutor: [],
        },
        idCondutor: "",
        percurso: [],
      },
      spinGeneral: false,
      ufs: ufs,
      uniqueRoute: "",
    }
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_MDFE"]),
    async updateOrSaveMdfe() {
      this.dadosMdfe.carregamento[0] = this.carregamento
      this.dadosMdfe.descarregamento[0] = this.descarregamento
      this.dadosMdfe.descarregamento[0].documentos_fiscais[0].chave = this.chave
      const isValid = ValidadeMdfe(this.dadosMdfe);
      if (isValid && Object.keys(isValid).length) {
        this.handleFocus(isValid.keyError);
        return this.$toast.open({
          message: isValid.message,
          type: "info",
        });
      }

      this.dadosMdfe.peso_bruto = this.dadosMdfe.peso_bruto.toString()
      if (this.dadosMdfe.id === "") return this.saveMdfe();
      this.updateMdfe();
    },

    handleFocus(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
      this.isInvalidInput = "isInvalidInput";
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    insertRoute() {
      if (!this.uniqueRoute) {
        return this.$toast.open({
          message: "Selecione uma UF para adicionar.",
          type: "info",
        });
      }
      const selectedUF = this.ufs.find(
        (uf) => uf.value === this.uniqueRoute
      );

      this.dadosMdfe.percurso.push(selectedUF.value);
      this.uniqueRoute = "";
    },

    removeItem(index) {
      this.dadosMdfe.percurso.splice(index, 1);
    },

    async saveMdfe() {
      try {
        const result = await ServiceMdfe.saveMdfe(this.dadosMdfe);
        console.logo(result)
        this.dadosMdfe.id = result;
        return this.$toast.open({
          message: "Manifesto Eletrônico salvo!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
      }
    },

    async updateMdfe() {
      if (Array.isArray(this.dadosMdfe.percurso) && this.dadosMdfe.percurso.length === 0) {
        this.dadosMdfe.percurso = "";
      }
      try {
        await ServiceMdfe.updateMdfe(this.dadosMdfe);

        return this.$toast.open({
          message: "Manifesto Eletrônico atualizado!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
      }
    },

    async sendMdfe() {
      try {
        const mdfe = await ServiceMdfe.findById(this.dadosMdfe.id);
        const data = await ServiceMdfe.sendMdfe(this.dadosMdfe.rodoviario.idVeiculo, mdfe.id);
        const response = JSON.parse(data.response);
        if (data.response) {
          window.open(response.damdfe, '_blank');
        }
        return this.$toast.open({
          message: "Manifesto Eletrônico emitido!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        if (message)
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
      }
    },
    async fetchCepLoading(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const data = await ServiceMdfe.fetchingCep(formatedCep)
        this.cep_carregamento = data.cep;
        this.dadosMdfe.uf_carregamento = data.uf;
        this.carregamento.codigo_municipio = data.ibge;
        this.carregamento.nome_municipio = data.localidade;
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },
    async fetchCepUnloading(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const data = await ServiceMdfe.fetchingCep(formatedCep)
        this.cep_descarregamento = data.cep;
        this.dadosMdfe.uf_descarregamento = data.uf;
        this.descarregamento.codigo_municipio = data.ibge;
        this.descarregamento.nome_municipio = data.localidade;
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },
    async getAllVehicle() {
      const result = await ServiceVehicle.fillCombobox();
      this.vehicle = result;
    },

    async getAllConductor() {
      const result = await ServiceConductor.fillCombobox();
      this.conductor = result;
    },
    clear() {
      this.dadosMdfe.id = "";
      this.dadosMdfe.data_mdfe = moment().format('YYYY-MM-DD HH =mm');
      this.dadosMdfe.ambiente = "";
      this.dadosMdfe.emitente = "";
      this.dadosMdfe.url_notificacao = "";
      this.dadosMdfe.response = "";
      this.dadosMdfe.status = "";
      this.dadosMdfe.unidade = "";
      this.dadosMdfe.modalidade = 1;
      this.dadosMdfe.uf_carregamento = "";
      this.dadosMdfe.uf_descarregamento = "";
      this.dadosMdfe.valor_carga = 0;
      this.dadosMdfe.peso_bruto = 0;
      this.dadosMdfe.carregamento = [];
      this.dadosMdfe.descarregamento = [];
      this.dadosMdfe.percurso = [];
      this.dadosMdfe.rodoviario = {
        rntrc: "",
        idVeiculo: "",
      },
        this.idCondutor = "";
      this.cep_carregamento = "";
      this.cep_descarregamento = "";
      this.chave = "";

      this.carregamento.nome_municipio = "";
      this.carregamento.codigo_municipio = "";
      this.descarregamento.nome_municipio = "";
      this.descarregamento.codigo_municipio = "";
      this.$refs.refMotorista.valueItems = "";
      this.$refs.refVeiculos.valueItems = "";
      this.$refs.valor.valueInput = "";
      this.SET_DATA_SEARCH_FROM_MDFE({});
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    maskToKg(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return `KG ${parseFloat(valor).toLocaleString(locale, { minimumFractionDigits: 2 })}`;
    },

    handleGrossWeight(event) {
      this.dadosMdfe.peso_bruto = mascaraMoeda(event);
    },

    assignEdit() {
      this.carregamento.nome_municipio = this.dataSearchFromMdfe.carregamento[0].nome_municipio;
      this.carregamento.codigo_municipio = this.dataSearchFromMdfe.carregamento[0].codigo_municipio;
      this.descarregamento.nome_municipio = this.dataSearchFromMdfe.descarregamento[0].nome_municipio;
      this.descarregamento.codigo_municipio = this.dataSearchFromMdfe.descarregamento[0].codigo_municipio;
      this.chave = this.dataSearchFromMdfe.descarregamento[0].documentos_fiscais[0].chave;
      this.dadosMdfe.idCondutor = this.dataSearchFromMdfe.idCondutor;
      this.dadosMdfe.idVeiculo = this.dataSearchFromMdfe.rodoviario.idVeiculo;
      this.$refs.refMotorista.valueItems = this.dataSearchFromMdfe.idCondutor;
      this.$refs.refVeiculos.valueItems = this.dataSearchFromMdfe.rodoviario.idVeiculo;
      this.$refs.valor.valueInput = this.dataSearchFromMdfe.valor_carga;
      this.dadosMdfe.valor_carga = this.dataSearchFromMdfe.valor_carga;
      if (typeof this.dataSearchFromMdfe.percurso === 'string') {
        this.dadosMdfe.percurso = [this.dataSearchFromMdfe.percurso];
      } else {
        this.dadosMdfe.percurso = this.dataSearchFromMdfe.percurso;
      }
    },

    async getConfig() {
      const result = await ServiceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.dadosMdfe.ambiente = result.emissao_fiscal.ambiente;
      }
    },
  },
  computed: {
    ...mapState(["dataSearchFromMdfe"])
  },
  mounted() {
    this.getAllConductor();
    this.getAllVehicle();
    this.getConfig();
    if (this.dataSearchFromMdfe && Object.keys(this.dataSearchFromMdfe).length) {
      Object.assign(this.dadosMdfe, this.dataSearchFromMdfe);
      this.assignEdit();
    }
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_MDFE({});
  },
}

export default mixinMdfe