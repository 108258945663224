<template>
  <div class="containerGeralPedidoVenda">
    <div>
      <!-- <b-toast
        class="stockToast"
        id="stock-toast"
        variant="primary"
        no-close-button
        static
        auto-hide-delay="5000"
      >
        <span> O Estoque está Zerado ou Negativo. </span>
      </b-toast> -->
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            NOVA VENDA Nº {{ numberSale || saleDataFromSaleModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(dataSale.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row class="mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
          <Input
            ref="data"
            label="Data"
            type="date"
            :dataInput.sync="dataSale.data"
            :labelFixed="true"
          />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-5 col-xl-6" size="sm">
          <InputSelect
            label="Cliente"
            :items="dataCustomers"
            valueField="id"
            textField="nome"
            :dataSelect.sync="dataSale.idCliente"
            :openCreateModal="() => openModals('modalCreateCustomer')"
            ref="refCliente"
          />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4" size="sm">
          <InputSelect
            label="Funcionário"
            ref="refResponsavel"
            :items="dataEmployee"
            valueField="id"
            textField="nomeFuncionario"
            :dataSelect.sync="dataSale.idFuncionario"
            :openCreateModal="() => openModals('modalCreateEmployee')"
          />
        </div>
      </b-row>
    </div>

    <hr />

    <div class="contentItens">
      <div class="responsiveItems">
        <div style="margin-top: 3rem">
          <b-row
            class="itemsSale"
            v-for="(prod, indice) in dataSale.products.filter(
              (item) => item.tipoProduto === 'normal'
            )"
            :key="prod.idList"
          >
            <div
              v-if="prod.tipoProduto === 'normal'"
              style="width: 30%; position: relative"
              size="sm"
            >
              <span
                style="position: absolute; top: -23px; right: 0; z-index: 9999"
              >
                <button
                  class="btnsComposition"
                  @click="
                    handleShowCompositionItem(
                      prod.idProduto,
                      prod.composicao,
                      indice
                    )
                  "
                >
                  Composições
                </button>
              </span>
              <InputSelect
                label="Itens"
                ref="refItens"
                :items="products"
                valueField="id"
                textField="nome"
                :dataSelect.sync="dataSale.products[indice].idProduto"
                :openCreateModal="
                  () =>
                    handleModalCreateProduct(
                      'modalCreateProduct',
                      dataSale.products[indice].idProduto
                    )
                "
                @selectedIten="selectProductSetDefaultValue($event, indice)"
              />
              <span v-if="prod.estoque >= 0" class="alertStock"
                >Estoque Atual : {{ prod.estoque }}</span
              >
              <span v-if="prod.estoque < 0" class="alertStockNegative"
                >Estoque Atual : {{ prod.estoque }}</span
              >
            </div>

            <div style="width: 20%">
              <Input
                label="Observação"
                :labelFixed="true"
                type="text"
                :dataInput.sync="dataSale.products[indice].dadosAdicionais"
              />
            </div>

            <div style="width: 5%">
              <InputQuant
                label="Qtd."
                :labelFixed="true"
                ref="refQtd"
                type="text"
                :dataInput.sync="dataSale.products[indice].quantidade"
                @calculateTotal="calculateTotalValue = { prod, indice }"
                :decimalPlaces="decimalPlaces"
              />
            </div>

            <div class="ajustMobile" style="width: 8%">
              <InputMoney
                label="Vl. Unit"
                labelFixed
                ref="refVlUnit"
                type="text"
                :dataInput.sync="dataSale.products[indice].valorUnitario"
                @calculateTotal="calculateTotalValue = { prod, indice }"
              />
            </div>

            <div class="ajustMobile" style="width: 8%">
              <InputMoney
                label="Vl. Total"
                labelFixed
                ref="valorTotal"
                type="text"
                isDisabled
                :dataInput.sync="calculateTotalValue"
              />
            </div>

            <div class="ajustMobile" style="width: 10%">
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  position: relative;
                "
              >
                <InputMoney
                  v-if="!discountIsCheked"
                  label="Desc"
                  :labelFixed="true"
                  type="text"
                  :dataInput.sync="dataSale.products[indice].desconto"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  ref="discount"
                />

                <InputPercentage
                  v-else
                  label="Desc"
                  :labelFixed="true"
                  type="text"
                  :dataInput.sync="dataSale.products[indice].desconto"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  ref="discount"
                />
                <label
                  ref="hideDiscount"
                  style="
                    position: absolute;
                    right: 0px;
                    top: -40px;
                    height: 100%;
                  "
                  class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2"
                >
                  <input
                    type="checkbox"
                    ref="typeDiscount"
                    @click="alterDiscountIsCheked"
                    @change="enableTooltipDiscount"
                    v-model="discountIsCheked"
                  />
                  <div class="slider">
                    <span>R$</span>
                    <span>%</span>
                  </div>
                </label>
                <div
                  style="display: none"
                  ref="enableLabelApplyAll"
                  class="tooltipDiscount"
                >
                  <div class="tooltipItems">
                    <div>
                      <label for="apllyAll">Aplicar em todos</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="apllyAll"
                        v-model="discountToAllItems"
                        @change="alterAllChecksDiscount"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="contentIconRemoveItems"
              @click="handleRemoveItens(prod.idList, prod.id)"
            >
              <div>
                <b-icon-x-circle role="button" scale="1.3" />
              </div>
            </div>
          </b-row>

          <b-row class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div
              class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
            >
              <button
                class="btnsSale"
                @click="handleAddItensAndHandleAssignValues"
              >
                Adicionar mais itens
              </button>
            </div>
          </b-row>
        </div>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataSale.frete)"
                @input="handleFreteMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals">
              <p>Total da Venda (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr />

      <div
        class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-if="dataSale.status === 'Venda'"
      >
        <b-row
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="align-items: center"
        >
          <div class="titleBillsPayments">
            <p class="title">Informações de Pagamento</p>
          </div>
        </b-row>

        <b-row class="contentBills mt-3" size="sm">
          <div class="selectPlus col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label class="fixedSelect">Forma de pagamento</label>
            <b-form-select
              value-field="id"
              text-field="tipo"
              class="borderInput shadow-none"
              :options="allPaymentsTypes"
              v-model="dataBillBySale.idFormaPagamento"
              size="sm"
              @change="selectPayment"
            >
            </b-form-select>
          </div>

          <div class="receiptAccount col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <InputSelect
              label="Conta de recebimento"
              valueField="id"
              textField="nome"
              :isDisabled="this.blocked"
              :items="allAccountReceiptBills"
              :dataSelect.sync="dataBillBySale.idContaRecebimento"
              :openCreateModal="() => openModals('modalContaRecebimento')"
              ref="refContaDeRecebimento"
            />
          </div>

          <div class="paymentInterval col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <Input
              label="Interval. de pagamento"
              :labelFixed="true"
              type="text"
              :dataInput.sync="intervaloDias"
              ref="refIntervaloDePagamento"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="fistBill col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <Input
              label="Venc. 1º parcela"
              :labelFixed="true"
              type="date"
              :dataInput.sync="dataBillBySale.data"
              ref="refVencimentoPrimeiraParcela"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="amountBills col-sm-6 col-md-3 col-lg-2 col-xl-1">
            <Input
              label="Parcelas"
              :labelFixed="true"
              type="text"
              :dataInput.sync="totalParcelas"
              ref="refTotalParcelas"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>
        </b-row>

        <b-row class="contentBills">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
          >
            <button
              class="btnsSale btnGenerateBills"
              @click="handleGenerateBillsAndOpenModalBills"
            >
              {{ verifyIfBillIsRegistred }}
            </button>
          </div>
        </b-row>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataSale.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataSale.observacoesNf"
          />
        </div>
      </b-row>

      <hr />

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                dataSale.id &&
                dataSale.idSituacao !==
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              class="btnsSale btnCancelSale"
              @click="cancelSale"
            >
              Cancelar Venda
            </button>

            <button
              class="btnsSale btnReopenSale"
              v-if="
                dataSale.id &&
                dataSale.idSituacao ===
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              @click="reOpenSale"
            >
              Reabrir Venda
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                class="btnsSale btnConfirm"
                @click="handleDataToSendModalSale"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />
    <ModalBills
      :saleIsCanceled="saleIsCanceled"
      :amountBills="billsFromModal"
      :totalValue="totalValue"
      :paymentSelected="paymentSelected"
      :receiptBillSelected="receiptBillSelected"
      :rangeOfDays="intervaloDias"
      :verifyBillIsPaid="verifyBillIsPaid"
      @isDropBills="isDropBills = $event"
      @confirmedBills="dataSale.bills = $event"
      @clearBillsDataAfterRemove="isRemovedBills = $event"
    />
    <ModalSale
      :paymentsDataInfo="paymentsDataInfo"
      :dataSale="dataSale"
      :dataSaleForModalSale="dataSaleForModalSale"
      :totals="objectTotal"
      :isBudgetForSale="isBudgetForSale"
      @isPaydBill="isPaydBill = $event"
      @saleSaved="handleSavedSale($event)"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="createdNewCustomer = $event"
      :idCustomerFromSearch="dataSale.idCliente"
    />
    <ModalCreateEmployees
      @getAllNewEmployee="createdNewEmployee = $event"
      :idEmployeeFromSearch="dataSale.idFuncionario"
    />
    <ModalCreateProducts
      @getAllNewProduct="createdNewProducts = $event"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalComposition
      :compositions="compositionItems"
      :prodIndice="prodIndice"
      :idProduct="idProduct"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
    />
  </div>
</template>

<script>
import ModalComposition from "./Modal-Composition.vue";
import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../Modals/ModalCreateProduct.vue";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import ModalBills from "./Modal-Bills.vue";
import ModalSale from "./Modal-Sale.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputQuant from "../UI/CustomInputs/InputQuant.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";

import { formatObjectToSaveAndUpdate } from "../../helpers/saleAndBudget";

import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceSale from "../../services/serviceSale";
import ServiceProductSale from "../../services/serviceProductSale";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceSituation from "../../services/serviceSituation";
import { ValidateBillsWhenGenerating } from "../../validators/bills";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/sale";
import toastAlertErros from "../../utils/toastAlertErros";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { mapState, mapMutations } from "vuex";
import serviceSettings from "../../services/serviceSettings";

export default {
  components: {
    ModalBills,
    ModalSale,
    InputSelect,
    Input,
    InputMoney,
    InputQuant,
    InputPercentage,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalCreateAccountReceiptBill,
    ModalComposition,
  },
  data() {
    return {
      dataSale: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dadosAdicionais: "",
        observacoesNf: "",
        status: "Venda",
        tipo: "Venda", //Venda, Frente de caixa, Venda MTQ
        frete: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        comissao: 0,
        products: [
          {
            id: "",
            idVenda: "",
            idFornecedor: "",
            idProduto: "",
            quantidade: 0,
            valorUnitario: 0,
            valorTotal: 0,
            desconto: 0,
            dadosAdicionais: "",
            idProdutoComposicao: "",
            tipoProduto: "normal", // ou composicao
            composicao: [],
            estoque: 0,
          },
        ],
        bills: [],
      },
      blocked: false,
      dataCustomers: [],
      dataEmployee: [],
      allSituations: [],
      products: [],
      providers: [],
      comissao: 0,
      productUnitaryValue: "",
      fields: ["Produto", "Quantidade", "Valor"],
      dataBillBySale: {
        tipo: "entrada",
        idCliente: "",
        idFuncionario: "",
        idFormaPagamento: "",
        idContaRecebimento: "",
        idVenda: "",
        valorUnitario: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: moment().format("YYYY-MM-DD"),
      },
      totalParcelas: 1,
      intervaloDias: "",
      allPaymentsTypes: [],
      total: "0,00",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      totalValue: 0,
      discountToAllItems: false,
      discountToIndividuallyItem: false,
      discountIsCheked: false,
      billsFromModal: [],
      allAccountReceiptBills: [],
      paymentSelected: "",
      receiptBillSelected: "",
      isPaydBill: "",
      isDropBills: false,
      saleDataFromSaleModal: {},
      dataSaleForModalSale: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsSale: [],
        data: "",
      },
      numberSale: "",
      verifyBillIsPaid: false,
      paymentMoneyIsSelected: false,
      paymentsDataInfo: {
        receiptAccount: "",
        typePayment: "",
        expiredFirst: "",
        installmentsNumber: 0,
        installmentsInterval: 0,
      },
      saleTypeSituation: SaleTypeSituation,
      saleIsCanceled: false,
      situationSelected: null,
      createdNewCustomer: null,
      createdNewEmployee: null,
      createdNewProducts: null,
      createdNewAccountReceiptBill: null,
      maskCurrency: maskCurrency,
      compositionItems: [],
      prodIndice: null,
      idProduct: "",
      toggleToUpdate: false,
      idProductFromSearch: "",
      isBlockStock: "",
      isBudgetForSale: false,
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE", "DATA_BUDGET_FOR_SALE"]),
    handleAddItensAndHandleAssignValues() {
      this.addMoreItens();
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    addMoreItens() {
      const idList = uuidv4();
      const newProducts = { ...INITIAL_STATE_PRODUCTS, idList };
      this.dataSale.products.push(newProducts);
      this.enableChangeDiscount();
      this.enableTooltipDiscount();

      this.cleanTotalValueLastProduct();
    },

    async cleanTotalValueLastProduct() {
      await this.$nextTick();
      this.$refs.valorTotal[this.dataSale.products.length - 1].valueInput = 0;
    },

    async calculateComissao() {
      this.readComissao();
      this.handleCalculateTotalsValues();
      if (this.comissao) {
        const total =
          parseFloat(this.comissao) * (mascaraMoeda(this.totalValue) / 100);
        this.dataSale.comissao = total.toFixed(2);
      }
    },

    assignTotalItensValues() {
      const valorTotal = this.dataSale.products.reduce(
        (oldValue, product) => oldValue + product.valorTotal,
        0
      );

      this.totalOfItens = parseFloat(valorTotal).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        if (this.discountToAllItems) {
          return (this.totalOfDiscount =
            this.calculateTotalDiscountWithApllyToAll());
        }

        if (!this.discountToAllItems && this.discountIsCheked) {
          return (this.totalOfDiscount = this.calculateTotalDiscount());
        }
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    assignTotalValue() {
      const formattedTotal = mascaraMoeda(this.totalOfItens);
      const formattedTotalDiscount = mascaraMoeda(this.totalOfDiscount);
      const frete = this.dataSale.frete;

      const newTotal =
        formattedTotal - formattedTotalDiscount + (parseFloat(frete) || 0);

      this.totalValue = newTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithApllyToAll() {
      for (let i = 0; i < this.dataSale.products.length; i++) {
        this.dataSale.products[i].desconto = this.dataSale.products[0].desconto;
      }

      const formattedTotalItens = mascaraMoeda(this.totalOfItens);

      const discountTotal =
        (formattedTotalItens * this.dataSale.products[0].desconto) / 100;

      return discountTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscount() {
      const discounts = this.dataSale.products.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.dataSale.products.map((prod) => {
        if (prod.desconto) {
          const desc = prod.desconto;

          return desc;
        }

        return 0;
      });

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async handleRemoveItens(idList, idItem) {
      if (idItem) {
        try {
          await this.removeItens(idItem);
        } catch (error) {
          return this.$toast.open({
            message:
              `${error.response.data.message}` ||
              "Ocorreu um erro ao remover o produto!",
            type: "info",
          });
        }
      }

      this.removeItensInArray(idList);
    },

    async cancelSale() {
      try {
        await ServiceSale.cancel(this.dataSale.id, this.dataSale.status);

        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.cancelado
        );

        this.saleIsCanceled = !this.saleIsCanceled;
        this.clearAllBills();
        return this.$toast.open({
          message: "Venda cancelada!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpenSale() {
      try {
        await ServiceSale.reOpen(this.dataSale.id);

        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.aprovado
        );

        return this.$toast.open({
          message: "Venda reaberta!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async removeItens(id) {
      await ServiceProductSale.delete(id);

      return this.$toast.open({
        message: "Produto removido",
        type: "success",
      });
    },

    removeItensInArray(idList) {
      if (this.dataSale.products.length > 1) {
        this.dataSale.products = this.dataSale.products.filter(
          (item) => item.idList !== idList
        );

        this.$nextTick(() => {
          this.$refs.hideDiscount[0].style.display = "block";
        });

        if (this.discountToAllItems) {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        }

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        return this.assignTotalValue();
      }

      const newIdList = uuidv4();
      this.dataSale.products = [{ ...INITIAL_STATE_PRODUCTS, newIdList }];
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
      this.cleanTotalValueLastProduct();
    },

    proximoCardDadosVenda() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse");
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse2");
    },

    proximoCardAdicionarProdutos() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse2");
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse3");
    },

    proximoCardFinanceiro() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse3");
    },

    clearDataPaymentsSale() {
      this.dataBillBySale.idCliente = "";
      this.dataBillBySale.idFuncionario = "";
      this.dataBillBySale.idFormaPagamento = "";
      this.dataBillBySale.idContaRecebimento = "";
      this.dataBillBySale.idVenda = "";
      this.dataBillBySale.valorUnitario = "";
      this.dataBillBySale.valorPago = "";
      this.dataBillBySale.valorRestante = "";
      this.dataBillBySale.data = moment().format("YYYY-MM-DD");
      this.totalParcelas = 1;
      this.intervaloDias = 30;
      this.dataSale.bills = [];
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async selectProductSetDefaultValue(idProduct, indice) {
      const product = await this.getProductById(idProduct);

      this.dataSale.products[indice].valorUnitario = product.valorVenda;
      this.dataSale.products[indice].estoque = product.estoque;
      // if (this.isBlockStock === "2") {
      //   if (product.estoque <= 0) {
      //     this.$bvToast.show("stock-toast");
      //   }
      // }
      this.dataSale.products[indice].composicao = product.composicao.map(
        (item) => {
          const newInitialState = {
            ...INITIAL_STATE_PRODUCTS,
            idList: uuidv4(),
            dadosAdicionais: item.dadosAdicionais,
            idProduto: item.idProduto,
            tipoProduto: "composicao",
            tipo: "Venda",
            quantidade: item.quantidade,
          };

          Reflect.deleteProperty(item, "idRefProduto");

          return {
            ...item,
            ...newInitialState,
          };
        }
      );

      this.$refs.refVlUnit[indice].valueInput = product.valorVenda;

      const amount = this.dataSale.products[indice].quantidade;
      const unitaryValue = this.dataSale.products[indice].valorUnitario;
      const total = amount * unitaryValue;
      this.dataSale.products[indice].valorTotal = total;
      this.$refs.valorTotal[indice].valueInput = total;

      this.$forceUpdate();

      this.assignTotalItensValues();
      this.assignTotalValue();
    },

    alterStatusSale() {
      this.dataSale.status = "Venda";
    },

    async getCustomersForSelectBox() {
      try {
        const data = await ServiceCustomer.getCustomersForSelectBox();
        this.dataCustomers = data.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    async updateSale() {
      try {
        await ServiceSale.update({
          ...this.dataSale,
          frete: this.dataSale.frete,
        });

        return this.$toast.open({
          message: "Pedido atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message =
          "Venda não pode ser alterada pois tem duplicatas já pagas vinculadas a essa venda";
        const status = 422;

        if (
          error.response.status === status &&
          error.response.data.message === message
        ) {
          this.dataSale.status = "Venda";
        }
        return this.$toast.open({
          message: `${error.response.data.message}`,
          type: "info",
        });
      }
    },

    async updateCompletedSale() {
      try {
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        await ServiceSale.updateCompletedSale(dataSaleFormatted);
      } catch (error) {
        if (error.response.status === 422) {
          return this.$toast.open({
            message: "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os funcionários",
          type: "info",
        });
      }
    },

    readComissao() {
      const employee = this.dataEmployee.find(
        (emp) => this.dataSale.idFuncionario === emp.id
      );
      if (employee?.comissao) {
        this.comissao = employee.comissao;
      }
      this.dataBillBySale.idFuncionario = this.dataSale.idFuncionario;
    },

    async getProdutos() {
      const { data } = await api.get("/products");
      this.products = data.data;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    handleGenerateBillsAndOpenModalBills() {
      if (
        (this.dataSale.bills.length && this.dataSale.bills[0]?.id) ||
        (this.billsFromModal.length && this.billsFromModal[0]?.id)
      ) {
        this.verifyBillIsPaid = !this.verifyBillIsPaid;
        this.getPaymentInformation();
        return this.openModals("modalBills");
      }

      const isNotValid = this.validateBills();
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.verifyBillIsPaid = !this.verifyBillIsPaid;
      this.handleGetTypePaymentAndReceiptBill();
      this.makingThePayment();
      this.getPaymentInformation();
      this.openModals("modalBills");
    },

    handleGetTypePaymentAndReceiptBill() {
      if (this.dataBillBySale.idFormaPagamento) {
        const typePayment = this.allPaymentsTypes.filter(
          (payment) => payment.id === this.dataBillBySale.idFormaPagamento
        );

        const receiptBill = this.allAccountReceiptBills.filter(
          (receiptBills) =>
            receiptBills.id === this.dataBillBySale.idContaRecebimento
        );

        this.paymentSelected = typePayment[0].tipo;
        this.receiptBillSelected = receiptBill[0].nome;
      }
    },

    async verifyStockProducts() {
      for (const item of this.dataSale.products) {
        const product = await this.getProductById(item.idProduto);
        const verifyStock = item.estoque - item.quantidade;

        if (verifyStock < 0) {
          this.$toast.open({
            message: `O produto ${product.nome} não possui estoque.`,
            type: "info",
          });
          return false;
        }
      }
      return true;
    },

    async handleDataToSendModalSale() {
      if (this.isBlockStock === "2") {
        const stockVerified = await this.verifyStockProducts();
        if (!stockVerified) {
          return;
        }
      }
      this.assingValuesSaleForModalSale();
      this.getPaymentInformation();
      this.calculateComissao();
      this.openModals("modalSale");
    },

    assingValuesSaleForModalSale() {
      const productsWithName = this.dataSale.products.map((prod) => {
        return {
          ...prod,
          nome: "",
        };
      });

      this.dataSaleForModalSale.formattedProductsSale = productsWithName.map(
        (prodSale) => {
          if (prodSale.idProduto) {
            return {
              ...prodSale,
              nome: this.products.filter(
                (prod) => prod.id === prodSale.idProduto
              )[0].nome,
            };
          }

          return prodSale;
        }
      );

      this.dataSaleForModalSale.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer.id === this.dataSale.idCliente
        )[0]?.nome;

      this.dataSaleForModalSale.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee.id === this.dataSale.idFuncionario
        )[0]?.nomeFuncionario;

      this.dataSaleForModalSale.data = this.dataSale.data;
    },

    getPaymentInformation() {
      if (this.billsFromModal.length) {
        this.paymentsDataInfo.receiptAccount = this.allAccountReceiptBills.find(
          (receiptBills) =>
            receiptBills.id === this.billsFromModal[0].idContaRecebimento
        ).nome;

        this.paymentsDataInfo.typePayment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.billsFromModal[0].idFormaPagamento
        ).tipo;

        this.paymentsDataInfo.expiredFirst = moment(
          this.billsFromModal.sort((a, b) => a.data - b.data)[0].data
        ).format("DD/MM/YYYY");

        this.paymentsDataInfo.installmentsNumber = this.billsFromModal.length;

        if (this.billsFromModal.length >= 2) {
          this.paymentsDataInfo.installmentsInterval = moment(
            this.billsFromModal[1].data
          ).diff(this.billsFromModal[0].data, "days");
        }
      }
    },

    clearPaymentsDataInfo() {
      this.paymentsDataInfo.receiptAccount = "";
      this.paymentsDataInfo.typePayment = "";
      this.paymentsDataInfo.expiredFirst = "";
      this.paymentsDataInfo.installmentsNumber = 0;
      this.paymentsDataInfo.installmentsInterval = 0;
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    validateBills() {
      const dataFromGenerateBill = {
        idCliente: this.dataSale.idCliente,
        idFormaDePagamento: this.dataBillBySale.idFormaPagamento,
        idContaRecebimento: this.dataBillBySale.idContaRecebimento,
        intervaloDias: this.intervaloDias,
        vencimento: this.dataBillBySale.data,
        quantidadeParcelas: this.totalParcelas,
      };

      return ValidateBillsWhenGenerating(dataFromGenerateBill);
    },

    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllPayment() {
      const result = await ServicePaymentForm.getAllPayment();
      this.allPaymentsTypes = result.sort((a, b) => a.cod - b.cod);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        return this.$toast.open({
          message: `${
            error.response.data.message ||
            "Ocorreu um erro ao carregar todas as situações"
          }`,
          type: "info",
        });
      }
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
    },

    clearAllBills() {
      this.clearDataPaymentsSale();
      this.clearInputForGenerateBills();
      this.billsFromModal = [];
      this.dataSale.bills = [];
      this.paymentSelected = "";
      this.receiptBillSelected = "";
    },

    clearInputForGenerateBills() {
      this.$refs.refContaDeRecebimento.valueItems = "";
      this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
      this.$refs.refVencimentoPrimeiraParcela.valueInput =
        this.dataBillBySale.data;
      this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
    },

    makingThePayment() {
      if (this.paymentMoneyIsSelected) {
        return this.handleMoneyPaymentSelected();
      }

      this.billsFromModal = [];
      const formattedTotalValue = mascaraMoeda(this.totalValue);

      const valorPorDuplicata = (formattedTotalValue / this.totalParcelas)
        .toFixed(2)
        .toLocaleString("pt-br", { minimumFractionDigits: 2 });

      for (let i = 0; i < this.totalParcelas; i++) {
        const dataVencimento =
          i == 0
            ? this.dataBillBySale.data
            : moment(dataVencimento)
                .add(this.intervaloDias, "days")
                .format("YYYY-MM-DD");
        this.billsFromModal.push({
          id: "",
          tipo: "entrada",
          idCliente: this.dataSale.idCliente,
          idFuncionario: this.dataSale.idFuncionario,
          idFormaPagamento: this.dataBillBySale.idFormaPagamento,
          idVenda: this.dataSale.id,
          valorTotal: valorPorDuplicata,
          valorPago: 0.0,
          valorRestante: valorPorDuplicata,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          descricao: "",
          idContaRecebimento: this.dataBillBySale.idContaRecebimento,
        });
      }
    },

    enableTooltipDiscount() {
      if (
        this.$refs.typeDiscount[0].checked &&
        this.dataSale.products.length > 1
      ) {
        return (this.$refs.enableLabelApplyAll[0].style.display = "block");
      }

      this.discountToAllItems = false;
      this.$refs.enableLabelApplyAll[0].style.display = "none";
    },

    alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;

      if (this.discountIsCheked) {
        this.dataSale.tipoDesconto = "Porcentagem";
        this.discountToIndividuallyItem = true;
        this.handleCalculateTotalsValues();
      } else {
        this.dataSale.tipoDesconto = "Valor Monetário";
        this.discountToIndividuallyItem = false;
        this.handleCalculateTotalsValues();
      }
    },

    async enableChangeDiscount() {
      const refsDiscount = this.$refs.hideDiscount;
      await this.$nextTick();
      for (let i = 1; i < refsDiscount.length; i++) {
        this.$refs.hideDiscount[i].style.display = "none";
      }
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        const typesDiscount = this.$refs.typeDiscount;
        for (var i = 0; i < typesDiscount.length; i++) {
          this.$refs.typeDiscount[i].checked = true;
        }

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      }
    },

    async handleValuesAfterTransformInSale() {
      this.assignBudgetValuesToSale();
      this.assingProductsToSale();

      await this.enableChangeDiscount();
    },

    async handleValuesAfterEditSale() {
      this.handleSaleValues();
      this.assingProductsToSale();

      await this.enableChangeDiscount();
    },

    handleSavedSale(saleSaved) {
      this.saleDataFromSaleModal = saleSaved;
      this.isBudgetForSale = false;
    },

    assignBudgetValuesToSale() {
      const approvedSituationId = this.getSpecificSituantionId(
        SaleTypeSituation.aprovado
      );

      Object.assign(this.dataSale, {
        ...this.dataBudgetForSale,
        idSituacao: approvedSituationId,
      });

      if (this.dataSale.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberSale = this.dataBudgetForSale.numero;
      this.$refs.refCliente.valueItems = this.dataBudgetForSale.idCliente;
      this.$refs.refResponsavel.valueItems =
        this.dataBudgetForSale.idFuncionario;
      this.dataSale.frete = this.dataBudgetForSale.frete;
      this.dataSale.status = "Venda";
      this.dataSale.tipo = "Venda";
      this.dataSale.dadosAdicionais = this.dataBudgetForSale.dadosAdicionais;
      this.dataSale.observacoesNf = this.dataBudgetForSale.observacoesNf;
    },

    handleSaleValues() {
      this.handleBillsIfAny();
      this.handleSaleProductsIfAny();
      this.assignDataSearchFromSale();
    },

    handleBillsIfAny() {
      if (!this.dataSearchFromSale?.bills?.length) {
        return Reflect.deleteProperty(this.dataSearchFromSale, "bills");
      }
      this.billsFromModal = this.dataSearchFromSale.bills.map((bill) => {
        return {
          ...bill,
          data: bill.data.split(" ")[0],
          dataPagamento: bill.dataPagamento?.split(" ")[0],
        };
      });
    },

    handleSaleProductsIfAny() {
      if (!this.dataSearchFromSale?.products?.length) {
        Reflect.deleteProperty(this.dataSearchFromSale, "products");
      }
    },

    assignDataSearchFromSale() {
      Object.assign(this.dataSale, this.dataSearchFromSale);
      if (this.dataSale.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberSale = this.dataSearchFromSale.numero;
      if (this.$refs.refCliente) {
        this.$refs.refCliente.valueItems = this.dataSearchFromSale.idCliente;
      }

      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSale.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSale.data;
    },

    assingProductsToSale() {
      this.dataSale.products = this.dataSale.products
        .map((item) => {
          return {
            ...item,
            idList: uuidv4(),
            composicao: item?.composicao?.map((item) => {
              return { ...item, idList: uuidv4() };
            }),
          };
        })
        .sort((a, b) => a.quantidade - b.quantidade);

      this.$nextTick(() => {
        for (let i = 0; i < this.dataSale.products.length; i++) {
          this.$refs.refItens[i].valueItems =
            this.dataSale.products[i].idProduto;

          this.$refs.refQtd[i].valueInput =
            this.dataSale.products[i].quantidade;

          this.$refs.refVlUnit[i].valueInput =
            this.dataSale.products[i].valorUnitario;

          this.$refs.discount[i].valueInput =
            this.dataSale.products[i].desconto;

          this.$refs.valorTotal[i].valueInput =
            this.dataSale.products[i].valorTotal;
        }
      });

      this.$forceUpdate();
    },

    toAssignDefaultSituation(situations) {
      this.allSituations = situations.filter((situation) => {
        if (
          [
            SaleTypeSituation.aprovado,
            SaleTypeSituation.faturado,
            SaleTypeSituation.finalizado,
            SaleTypeSituation.cancelado,
          ].includes(situation.nome)
        ) {
          return situation;
        }
      });

      if (!this.dataSale.id) {
        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.aprovado
        );
      }
    },

    handleFreteMonetaryValue(event) {
      this.dataSale.frete = mascaraMoeda(event.target.value);
    },

    getSpecificSituantionId(saleTypeSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === saleTypeSituation
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleMoneyPaymentSelected() {
      this.billsFromModal = [];

      const formattedTotalValue = mascaraMoeda(this.totalValue);
      const valorPorDuplicata = formattedTotalValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });

      this.billsFromModal.push({
        id: "",
        tipo: "entrada",
        idCliente: this.dataSale.idCliente,
        idFuncionario: this.dataSale.idFuncionario,
        idFormaPagamento: this.dataBillBySale.idFormaPagamento,
        idVenda: this.dataSale.id,
        valorTotal: valorPorDuplicata,
        valorPago: 0.0,
        valorRestante: valorPorDuplicata,
        data: moment().format("YYYY-MM-DD"),
        dataPagamento: moment().format("YYYY-MM-DD"),
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        idContaRecebimento: this.dataBillBySale.idContaRecebimento,
      });
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.allPaymentsTypes.find(
        (formPayment) => formPayment.id === this.dataBillBySale.idFormaPagamento
      );

      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );

        this.dataBillBySale.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },

    handleShowCompositionItem(idProduct, composition, prodIndice) {
      if (idProduct) {
        this.compositionItems = composition;
        this.prodIndice = prodIndice;
        this.idProduct = idProduct;
        this.toggleToUpdate = !this.toggleToUpdate;
        this.$bvModal.show("modalProductSaleComposition");
      }
    },

    handleItemFromModalComposition(payload) {
      this.dataSale.products[payload.indice].composicao = payload.composition;
    },

    async getConfig() {
      const result = await serviceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.isBlockStock = result.venda.bloqueio_estoque;
      }
    },
  },
  async mounted() {
    const idList = uuidv4();
    this.dataSale.products[0].idList = idList;
    this.getCustomersForSelectBox();
    this.listEmployeesSelectBox();
    this.getProdutos();
    this.getProviders();
    this.getAllPayment();
    this.getAllReceiptBill();
    this.getConfig();
    await this.handleSituation();

    if (
      this.dataBudgetForSale.status === "Orçamento" &&
      this.dataBudgetForSale.tipo === "Orçamento"
    ) {
      this.isBudgetForSale = true;
      this.handleValuesAfterTransformInSale();
      this.DATA_BUDGET_FOR_SALE({});
    }

    if (this.dataSearchFromSale.idCliente) {
      this.handleValuesAfterEditSale();
    }

    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();

    if (
      !this.dataSale.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.venda).length &&
      this.dataConfig.venda.dados_adicionais_padrao
    ) {
      this.dataSale.dadosAdicionais =
        this.dataConfig.venda.dados_adicionais_padrao;
    }
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataBudgetForSale: (state) => state.dataBudgetForSale,
      dataSearchFromSale: (state) => state.dataSearchFromSale,
      dataConfig: (state) => state.dataConfig,
    }),
    calculateTotalValue: {
      get() {
        return this.total;
      },
      set({ prod, indice }) {
        const amount = prod.quantidade;
        const unitaryValue = prod.valorUnitario;
        const totalValue = amount * unitaryValue;
        if (!this.$refs.valorTotal[indice]) return;

        this.dataSale.products[indice].valorTotal = totalValue;

        this.total = totalValue;

        this.$refs.valorTotal[indice].valueInput = this.total;

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      },
    },
    applyDiscountToAllItems() {
      if (this.dataSale.products && this.dataSale.products.length) {
        return this.dataSale.products[0].desconto;
      }

      return 0;
    },
    typePaymentSelected() {
      return this.dataBillBySale.idFormaPagamento;
    },
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais);
      }

      return 2;
    },
    freteToAdditionalValues() {
      return this.dataSale.frete;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    disabledProducts() {
      return this.dataSale.status;
    },
    verifyIfBillIsRegistred() {
      if (this.billsFromModal.length && this.billsFromModal[0]?.id)
        return "Ver Parcelas";
      else return "Gerar Parcelas";
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.dataSale.idSituacao
      );

      if (situation && situation.nome === SaleTypeSituation.aprovado) {
        return "orange";
      } else if (situation && situation.nome === SaleTypeSituation.faturado) {
        return "#3e66ae";
      } else if (situation && situation.nome === SaleTypeSituation.finalizado) {
        return "blue";
      }

      return "#9f1313";
    },
  },
  watch: {
    typePaymentSelected() {
      if (this.typePaymentSelected) {
        const payment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.typePaymentSelected
        );
        if (payment.tipo === "Dinheiro") {
          this.paymentMoneyIsSelected = true;
          this.intervaloDias = 0;
          this.dataBillBySale.data = moment().format("YYYY-MM-DD");
          this.totalParcelas = 1;
          this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
          this.$refs.refVencimentoPrimeiraParcela.valueInput =
            this.dataBillBySale.data;
          this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
        } else {
          this.paymentMoneyIsSelected = false;
        }
      }
    },
    applyDiscountToAllItems() {
      if (this.discountToAllItems) {
        const refsDiscount = this.$refs.discount;
        for (var i = 0; i < refsDiscount.length; i++) {
          this.$refs.discount[i].valueInput =
            this.dataSale.products[0].desconto;

          this.dataSale.products[i].desconto =
            this.$refs.discount[i].valueInput;
        }
      }
    },
    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.dataSale.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    isDropBills() {
      this.clearAllBills();
      this.clearPaymentsDataInfo();
    },
    saleDataFromSaleModal() {
      if (this.saleDataFromSaleModal.id) {
        this.dataSale.id = this.saleDataFromSaleModal.id;
      }

      if (this.saleDataFromSaleModal.productsOfSale) {
        const newProductsFromTable =
          this.saleDataFromSaleModal.productsOfSale.map((prod, indice) => {
            return {
              ...prod,
              idList: this.dataSale.products[indice].idList,
            };
          });

        this.dataSale.products = newProductsFromTable;
      }

      if (this.saleDataFromSaleModal.billsOfSale) {
        const newBills = this.saleDataFromSaleModal.billsOfSale.map((bill) => {
          return { ...bill };
        });

        this.dataSale.bills = newBills;
        this.billsFromModal = newBills;
      }
    },
    createdNewCustomer() {
      this.getCustomersForSelectBox();
    },
    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
    createdNewProducts() {
      this.getProdutos();
    },
    createdNewAccountReceiptBill() {
      this.getAllReceiptBill();
    },
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_SALE({});
    this.isBudgetForSale = false;
  },
};
</script>

<style scoped>
#dadosVendaStyle {
  border: none !important;
}

.cardDadosVendas {
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.788) !important;
  box-shadow: inset 0 0 0.5em rgb(255, 255, 255), 0 0 0.5em rgba(5, 5, 5, 0.171);
}

.tamanhoBotaoOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  /* color: white; */
}

.cardDadosVendasBorda {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
  /* background-color: #6a88bd !important; */
  /* color: white; */
}

.tamanhoCards {
  width: 100%;
  display: flex;
  /* background-color: #6a88bd !important; */
  /* box-shadow: 2px 2px 4px 0px black !important ; */
}

.cardDadosVendas {
  padding: 0px 16px 0px 16px !important;
}

.acoesBtns {
  padding: 40px 0 10px 40px;
  margin: 0;
  width: 100%;
  border: none;
}

.btnsGeral {
  display: flex;
  justify-content: space-around;
}

.containerAllCard {
  margin-top: 10px;
}

.containerCheckBox {
  display: flex;
  height: 32px !important;
  margin-top: 31px;
  align-items: center !important;
  background-color: #e9ecef !important;
  border: solid 1px rgb(216, 215, 215);
  border-radius: 5px;
  padding-left: 15px !important;
}

.chkVendaOrcamento {
  margin-top: 18px;
}

.containerGeralPedidoVenda {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

.iconFormaPagamento {
  display: flex;
  justify-content: space-between;
}

.btnFormaPagamento {
  cursor: pointer;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: auto;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  transition: ease-in all 0.1s;
}

.btnsComposition:hover {
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnsSale {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnGenerateBills {
  background-color: transparent;
  border: solid 1px black;
  color: black;
}

.btnCancelSale {
  background-color: #ff3131;
}

.btnReopenSale {
  background-color: #cac64e;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnTransformInSale {
  font-size: 12px;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.stockToast {
  position: absolute;
  left: 20px;
  display: flex;
}

.stockToast span {
  color: black;
}

.alertStock {
  font-size: 10px;
  color: #3e66ae;
  padding-left: 10px;
}

.alertStockNegative {
  font-size: 10px;
  color: #ff1d08;
  padding-left: 10px;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  font-size: 14px;
  position: relative;
  display: inline-block;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.itemsSale {
  position: relative;
  display: flex;
  gap: 20px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 2rem !important;
  width: 100%;
}

.observacoes {
  font-size: 12px;
  color: #d5d3d3;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: 60px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 200px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -40px;
  right: 40px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    max-width: 1336px;
    overflow-x: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tooltipDiscount {
    right: 100px;
  }
}

@media screen and (max-width: 1199px) {
  .amountBills {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .fistBill {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.titleBillsPayments {
  font-size: 20px;
}

.contentBills {
  margin-right: 16px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  bottom: 20px;
  left: 12px;
  z-index: 2;
  background-color: #ffff;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  padding: 0 0.2em;
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralPedidoVenda {
    margin-left: 0rem;
  }
  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }
  .contentIconRemoveItems {
    right: 10px;
  }
  .contentItens {
    margin-top: 0rem !important;
  }
  .itemsSale {
    margin-left: 0px;
    margin-right: 0px;
  }
  .btnsSale {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }
  .contentTotals {
    font-size: 11px;
  }
  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }
  .col-md-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
  .col-md-5 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .col-sm-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralPedidoVenda {
    margin-left: 0rem;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .row {
    gap: 10px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .itemsSale {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px !important;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    flex-wrap: nowrap;
  }

  .contentIconRemoveItems {
    right: -10px;
  }

  .switch {
    top: -35px;
    left: -5px !important;
  }
  .ajustMobile {
    width: 13% !important;
  }
}
</style>
