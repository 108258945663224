export default {
  REGISTER_WIDTH(state, widthCards) {
    state.widthCards = widthCards;
  },
  ALTER_VALUES_MODAL_DIALOG_GENERAL(state, item) {
    state.dataModalDialogGeneral.nameModal = item.nameModal
    state.dataModalDialogGeneral.title = item.title
    state.dataModalDialogGeneral.subTitle = item.subTitle
    state.dataModalDialogGeneral.footerTitle = item.footerTitle
    state.dataModalDialogGeneral.leftButtonText = item.leftButtonText
    state.dataModalDialogGeneral.rightButtonText = item.rightButtonText
  },
  DATA_COMPANY(state, payload) {
    state.dataCompany = payload;
  },
  DATA_CONTRACT(state, payload) {
    state.dataContract = payload;
  },
  DATA_BUDGET_FOR_SALE(state, payload) {
    state.dataBudgetForSale = payload;
  },
  ALTER_SIZE_MODAL_BIG_BOX(state, item) {
    state.sizeModalBigBox = item;
  },
  ALTER_SIZE_MODAL(state, item) {
    state.sizeModal = item;
  },
  ALTER_SIZE_MODAL_REGISTER(state, item) {
    state.sizeModalRegister = item;
  },
  ALTER_MODAL_CONTAINER_BIG_BOX(state, item) {
    state.modalContainerBigBox = item
  },
  ALTER_MODAL_CONTAINER_GENERAL(state, item) {
    state.modalContainerGeneral = item
  },
  ALTER_MODAL_CONTAINER_REGISTER(state, item) {
    state.modalContainer = item
  },
  ALTER_SIZE_MODAL_FINANCIAL(state, item) {
    state.sizeModalFinancial = item;
  },
  ALTER_SIZE_MODAL_CASHIER(state, item) {
    state.sizeModalCashier = item;
  },
  ALTER_MODAL_CONTAINER_FINANCIAL(state, item) {
    state.modalContainerFinancial = item
  },
  ALTER_MODAL_CONTAINER_CASHIER(state, item) {
    state.modalContainerCashier = item
  },
  ALTER_TYPE_DOCUMENT(state, item) {
    state.typeDocument = {
      frenteDeCaixa: false,
      orcamento: false,
      venda: false,
      orcamentoMtq: false,
      vendaMtq: false,
      [item]: true,
    };
  },
  ALTER_TYPE_METER_DOCUMENT(state, item) {
    state.typeMeterDocument = {
      orcamentoMtq: false,
      vendaMtq: false,
      orcamentoMtl: false,
      vendaMtl: false,
      [item]: true,
    };
  },
  ALTER_TYPE_REGISTER(state, item) {
    state.typeRegister = {
      customer: false,
      employee: false,
      products: false,
      supplier: false,
      service: false,
      [item]: true,
    };
  },
  ALTER_TYPE_FISCAL(state, item) {
    state.typeFiscal = {
      nfe: false,
      nfce: false,
      nfse: false,
      mdfe: false,
      [item]: true,
    };
  },
  ALTER_TYPE_FINANCIAL(state, item) {
    state.enableModalsFinancial = {
      toPay: false,
      toReceive: false,
      billDetails: false,
      [item]: true,
    };
  },
  ALTER_TYPE_SETTINGS(state, item) {
    state.enableModalsSettings = {
      accessControl: false,
      fiscalSettings: false,
      logsScreen: false,
      [item]: true,
    }
  },
  ALTER_TYPE_ACCOUNTING_CONVERSATION(state) {
    state.enableModalAccountingConversation = !state.enableModalAccountingConversation
  },
  SET_NAV_ITEM_SELECTED(state, item) {
    state.navItemSelected = item;
  },
  SET_DEFAULT_TYPE_FISCAL(state) {
    state.typeFiscal = {
      nfe: false,
      nfce: false,
      nfse: false,
      mdfe: false,
    };
  },
  SET_DEFAULT_TYPE_FINANCIAL(state) {
    state.enableModalsFinancial = {
      toPay: false,
      toReceive: false,
    };
  },
  SET_COLAPSE_BIG_BOX(state, item) {
    state.colapseBigBox = item
  },
  SET_COLAPSE_FINANCIAL(state, item) {
    state.colapseFinancial = item
  },
  SET_COLAPSE_CASHIER(state, item) {
    state.colapseCashier = item
  },
  SET_COLAPSE_PRINT(state, item) {
    state.colapsePrint = item
  },
  SET_COLAPSE_FISCAL(state, item) {
    state.colapseFiscal = item
  },
  SET_COLAPSE_REGISTER(state, item) {
    state.colapseRegister = item
  },
  SET_COLAPSE_GENERAL(state, item) {
    state.colapseGeneral = item
  },
  SET_DATA_SEARCH_FROM_BOX_FRONT(state, payload) {
    state.dataSearchFromBoxFront = payload
  },
  SET_DATA_SEARCH_FROM_SALE(state, payload) {
    state.dataSearchFromSale = payload
  },
  SET_DATA_SEARCH_FROM_SALE_SQUARE_METER(state, payload) {
    state.dataSearchFromSquareMeter = payload
  },
  SET_DATA_SEARCH_FROM_PROVISION_SERVICE(state, payload) {
    state.dataSearchFromProvisionService = payload
  },
  SET_DATA_SEARCH_FROM_MDFE(state, payload) {
    state.dataSearchFromMdfe = payload
  },
  SET_DATA_SEARCH_FROM_PURCHASE(state, payload) {
    state.dataSearchFromPurchase = payload
  },
  SET_DATA_SEARCH_FROM_BILL(state, payload) {
    state.dataSearchFromBill = payload
  },
  SET_DATA_SEARCH_FROM_CUSTOMER(state, payload) {
    state.dataSearchCustomer = payload
  },
  SET_DATA_SEARCH_FROM_EMPLOYEE(state, payload) {
    state.dataSearchEmployee = payload
  },
  SET_DATA_SEARCH_FROM_PRODUCT(state, payload) {
    state.dataSearchProduct = payload
  },
  SET_DATA_SEARCH_FROM_SUPPLIER(state, payload) {
    state.dataSearchSupplier = payload
  },
  SET_DATA_SEARCH_FROM_SERVICE(state, payload) {
    state.dataSearchService = payload
  },
  SET_DATA_NFE(state, payload) {
    state.dataNFe = payload
  },
  SET_DATA_CONFIG(state, payload) {
    state.dataConfig = payload
  },
  SET_IS_ENABLE_MTQ(state, item) {
    state.enableMtq = item
  },
  SET_SAFE_REGISTER(state, payload) {
    state.dataSafeIsOpen = payload
  },
  SET_IS_SEARCH_BILLS(state) {
    state.isSearchBills = !state.isSearchBills
  },
  SET_IS_SEARCH_REGISTERS(state) {
    state.isSearchRegisters = !state.isSearchRegisters
  },
  SET_SAFE_REMAINING_VALUE(state, item) {
    state.remainingValue = item
  },
  SET_ACCOUTING_CONVERSATIONS(state, payload) {
    state.accountingConversations = payload
  },
  SET_ACCOUTING_NUMBER_NEW_MESSAGES(state, item) {
    state.numberOfNewMessages = item
  },
  SET_OVERFLOW(state, item) {
    state.overflow = item
  },
  SET_PLAN(state, payload) {
    state.dataPlan = payload
  },
  SET_INTERVAL_MACHINE_ID(state, item) {
    state.intervalMachineId = item
  }
};
