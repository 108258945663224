const pagamento = [
  { value: 0, text: "Pagamento à vista" },
  { value: 1, text: "Pagamento à prazo" },
]

const formaPagamento = [
  { value: "01", text: "Dinheiro" },
  { value: "02", text: "Cheque" },
  { value: "03", text: "Cartão de Crédito" },
  { value: "04", text: "Cartão de Débito" },
  { value: "05", text: "Crédito Loja" },
  { value: "10", text: "Vale Alimentação" },
  { value: "11", text: "Vale Refeição" },
  { value: "12", text: "Vale Presente" },
  { value: "13", text: "Vale Combustível" },
  { value: "14", text: "Duplicata Mercantil" },
  { value: "15", text: "Boleto Bancário" },
  { value: "16", text: "Depósito Bancário" },
  { value: "17", text: "Pagamento Instantâneo (PIX)" },
  { value: "18", text: "Transferência bancária, Carteira Digital" },
  { value: "19", text: "Programa de fidelidade, Cashback, Crédito Virtual" },
  { value: "90", text: "Sem pagamento" },
]

const tipoArquivo = [
  { value: "Contrato" },
  { value: "Documento Fiscal" },
  { value: "Documento de Cobrança" },
  { value: "Outros" },
]

const cfop = [
  {
    text: "1102 - Compra para comercialização - Dentro do Estado.",
    value: "1102",
  },
  {
    text: "2102 - Compra para comercialização - Outro Estado.",
    value: "2102",
  },
  {
    text: "2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária - Outro Estado.",
    value: "2403",
  },
  {
    text: "2551 - Classificam-se neste código as compras de produtos destinadas ao uso ou consumo do estabelecimento - Outro Estado.",
    value: "2551",
  },
  {
    text: "2556 - Compra de bem para o ativo imobilizado - Outro Estado.",
    value: "2556",
  },
]

const defaultUserPermission = {
  vendas: {
    painelPrincipal: false,
    novaVenda: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoOrcamento: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFrenteDeCaixa: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  ordensDeServico: {
    painelPrincipal: false,
    novaOrdem: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  cadastros: {
    painelPrincipal: false,
    novoCliente: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFornecedor: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoProduto: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoFuncionario: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novoServico: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
  },
  compras: {
    painelPrincipal: false,
    novaCompra: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    notasRecebidas: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  financeiro: {
    painelPrincipal: false,
    novaContaReceber: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaContaPagar: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    }
  },
  emissaoFiscal: {
    painelPrincipal: false,
    novaNfe: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaNfce: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
    novaNfse: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
      deletar: false
    },
  },
  caixa: {
    painelPrincipal: false,
    abrirCaixa: false,
    fecharCaixa: false,
    sangria: false,
    suprimentosConta: false,
    suprimentosGaveta: false,
    transferencia: false,
    ajusteDeCaixa: false,
    ajusteDeConta: false,
    caixaAutomatico: false,
  },
  agenda: {
    painelPrincipal: false,
  },
  relatorios: {
    painelPrincipal: false,
    imprimir: false,
  },
  configuracoes: {
    painelPrincipal: false,
    empresa: {
      acessar: false,
      salvar: false,
      editar: false,
      ler: false,
    },
    registroPonto: {
      painelPrincipal: false,
    }
  }
}

const defaultAdmPermission = {
  vendas: {
    painelPrincipal: true,
    novaVenda: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoOrcamento: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFrenteDeCaixa: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  ordensDeServico: {
    painelPrincipal: true,
    novaOrdem: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  cadastros: {
    painelPrincipal: true,
    novoCliente: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFornecedor: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoProduto: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoFuncionario: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novoServico: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
  },
  compras: {
    painelPrincipal: true,
    novaCompra: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    notasRecebidas: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  financeiro: {
    painelPrincipal: true,
    novaContaReceber: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaContaPagar: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    }
  },
  emissaoFiscal: {
    painelPrincipal: true,
    novaNfe: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaNfce: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
    novaNfse: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
      deletar: true
    },
  },
  caixa: {
    painelPrincipal: true,
    abrirCaixa: true,
    fecharCaixa: true,
    sangria: true,
    suprimentosConta: true,
    suprimentosGaveta: true,
    transferencia: true,
    ajusteDeCaixa: true,
    ajusteDeConta: true,
    caixaAutomatico: true,
  },
  agenda: {
    painelPrincipal: true,
  },
  relatorios: {
    painelPrincipal: true,
    imprimir: true,
  },
  configuracoes: {
    painelPrincipal: true,
    empresa: {
      acessar: true,
      salvar: true,
      editar: true,
      ler: true,
    },
    registroPonto: {
      painelPrincipal: false,
    }
  }
}

const defaultWorkingSchedule = {
  domingo: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  segunda: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  terca: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  quarta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  quinta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  sexta: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
  sabado: {
    ativo: false,
    inicio: null,
    intervalo: null,
    fim: null,
    carga_horaria: null,
  },
}

const ufs = [
  { text: 'AC', value: 'AC' },
  { text: 'AL', value: 'AL' },
  { text: 'AP', value: 'AP' },
  { text: 'AM', value: 'AM' },
  { text: 'BA', value: 'BA' },
  { text: 'CE', value: 'CE' },
  { text: 'DF', value: 'DF' },
  { text: 'ES', value: 'ES' },
  { text: 'GO', value: 'GO' },
  { text: 'MA', value: 'MA' },
  { text: 'MT', value: 'MT' },
  { text: 'MS', value: 'MS' },
  { text: 'MG', value: 'MG' },
  { text: 'PA', value: 'PA' },
  { text: 'PB', value: 'PB' },
  { text: 'PR', value: 'PR' },
  { text: 'PE', value: 'PE' },
  { text: 'PI', value: 'PI' },
  { text: 'RJ', value: 'RJ' },
  { text: 'RN', value: 'RN' },
  { text: 'RS', value: 'RS' },
  { text: 'RO', value: 'RO' },
  { text: 'RR', value: 'RR' },
  { text: 'SC', value: 'SC' },
  { text: 'SP', value: 'SP' },
  { text: 'SE', value: 'SE' },
  { text: 'TO', value: 'TO' }
];

const nfseSituationsNfse = [
  { text: 'Pendente', value: "Pendente" },
  { text: 'Em andamento', value: "Em andamento" },
  { text: 'Finalizado', value: "Finalizado" },
  { text: 'Faturado', value: "Faturado" },
  { text: 'Cancelado', value: "Cancelado" },
]

const typesInstallment = [
  { text: "Venda à vista", value: 1 },
  { text: "2x", value: 2 },
  { text: "3x", value: 3 },
  { text: "4x", value: 4 },
  { text: "5x", value: 5 },
  { text: "6x", value: 6 },
  { text: "7x", value: 7 },
  { text: "8x", value: 8 },
  { text: "9x", value: 9 },
  { text: "10x", value: 10 },
  { text: "11x", value: 11 },
  { text: "12x", value: 12 },
]

const departaments = [
  {
    text: "Recursos humanos",
    value: "recursos_humanos",
  },
  { text: "Fiscal", value: "fiscal" },
  {
    text: "Contabilidade e Imposto de Renda",
    value: "contabilidade_imposto_renda",
  },
  { text: "Auditoria", value: "auditoria" },
  {
    text: "Previdenciário e legislação",
    value: "previdenciario_legislacao",
  },
  {
    text: "Adminstrativo",
    value: "adminstrativo",
  },
  { text: "Outros", value: "outros" },
]

const resumeTypes = [
  { text: "Diários", value: "diario" },
  { text: "Semanal", value: "semanal" },
  { text: "Mensal", value: "mensal" },
]

const typesKeyPix = [
  { text: "CPF", value: 1 },
  { text: "CNPJ", value: 2 },
  { text: "E-mail", value: 3 },
  { text: "Telefone Celular", value: 4 },
  { text: "Chave aleatória", value: 5 },
]

const decimalPlacesOptions = [
  { text: "2 casas após a vírgula", value: 2 },
  { text: "3 casas após a vírgula", value: 3 },
  { text: "4 casas após a vírgula", value: 4 },
  { text: "5 casas após a vírgula", value: 5 },
]

const unidade = [
  { text: 'Ampola', value: 'AMP' },
  { text: 'Barril', value: 'BR' },
  { text: 'Caixa', value: 'CX' },
  { text: 'Centímetro (cm)', value: 'CM' },
  { text: 'Conjunto', value: 'CONJ' },
  { text: 'Dúzia', value: 'DZ' },
  { text: 'Envolope', value: 'ENV' },
  { text: 'Fardo', value: 'Fardo' },
  { text: 'Frasco', value: 'Frasco' },
  { text: 'Galão', value: 'GL' },
  { text: 'Grama (g)', value: 'GR' },
  { text: 'Jogo', value: 'JG' },
  { text: 'Kit', value: 'Kit' },
  { text: 'Litro (l)', value: 'L' },
  { text: 'Lote', value: 'Lote' },
  { text: 'Metro (m)', value: 'MT' },
  { text: 'Metro Quadrado (mt²)', value: 'MT2' },
  { text: 'Mililitro (ml)', value: 'ML' },
  { text: 'Milímetro (mm)', value: 'MM' },
  { text: 'Pacote', value: 'PCT' },
  { text: 'Palete', value: 'PBR' },
  { text: 'Par', value: 'PAR' },
  { text: 'Peça', value: 'PC' },
  { text: 'Quilograma (kg)', value: 'KG' },
  { text: 'Rolo', value: 'Rolo' },
  { text: 'Saco', value: 'Saco' },
  { text: 'Tubo', value: 'Tubo' },
  { text: 'Tonelada', value: 'TON' },
  { text: 'Unidade', value: 'UND' }
];

export { pagamento, formaPagamento, tipoArquivo, defaultUserPermission, defaultAdmPermission, defaultWorkingSchedule, cfop, ufs, nfseSituationsNfse, typesInstallment, departaments, resumeTypes, typesKeyPix, decimalPlacesOptions, unidade }