import { mapState, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import ModalCreateCustomer from "../../components/Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../../components/Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../../components/Modals/ModalCreateProduct.vue";
import ModalCreateAccountReceiptBill from "../../components/Modals/ModalCreateAccountReceiptBill.vue";
import ModalCreateColors from "../../components/Modals/ModalCreateColors.vue";
import ModalCreateRooms from "../../components/Modals/ModalCreateRooms.vue";
import ModalBillsSquareMeter from "../../components/SaleSquareMeter/Modal-Bills-SaleSquareMeter.vue";
import ModalSaleSquareMeter from "../../components/SaleSquareMeter/Modal-SaleSquareMeter.vue";
import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue";
import Input from "../../components/UI/CustomInputs/Input.vue";
import InputMoney from "../../components/UI/CustomInputs/InputMoney.vue";
import InputQuant from "../../components/UI/CustomInputs/InputQuant.vue";
import InputPercentage from "../../components/UI/CustomInputs/InputPercentage.vue";
import Tabs from "../../components/UI/Tabs/Tab.vue"

import api from "../../services/axios";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceSaleSquareMeter from "../../services/serviceSaleSquareMeter";
import ServiceProductSaleSquareMeter from "../../services/serviceProductSaleSquareMeter";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceSituation from "../../services/serviceSituation";
import ServiceColors from "../../services/serviceColors"
import ServiceRooms from "../../services/serviceRooms"

import { ValidateBillsWhenGenerating } from "../../validators/bills";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/saleSquareMeter";
import { SquareMeterSituationType } from "../../utils/enums/SquareMeterSituationType";
import { SalesSquareMeterType } from "../../utils/enums/SalesSquareMeterType";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import toastAlertErros from "../../utils/toastAlertErros";


export const mixinSalePerMeterSquare = {
  props: {
    statusSquareMeter: {
      type: String,
      default: SalesSquareMeterType.venda
    }
  },
  components: {
    ModalBillsSquareMeter,
    ModalSaleSquareMeter,
    InputSelect,
    Input,
    InputMoney,
    InputQuant,
    InputPercentage,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalCreateAccountReceiptBill,
    ModalCreateColors,
    ModalCreateRooms,
    Tabs,
  },
  data() {
    return {
      saleSquareMeter: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dataVencimento: null,
        dadosAdicionais: "",
        observacoesNf: "",
        status: this.statusSquareMeter,
        frete: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        products: [
          {
            id: "",
            idVendaMtq: "",
            idFornecedor: "",
            idProduto: "",
            quantidade: 0,
            altura: 0,
            largura: 0,
            metragem: 0,
            valorUnitario: 0,
            valorTotal: 0,
            desconto: 0,
            dadosAdicionais: "",
            porcentagemPerda: 0,
            idCor: "",
            idAmbiente: "",
            linear: false,
          },
        ],
        bills: [],
      },
      blocked: false,
      dataCustomers: [],
      dataEmployee: [],
      allSituations: [],
      products: [],
      providers: [],
      comissao: "",
      productUnitaryValue: "",
      fields: ["Produto", "Quantidade", "Valor"],
      dataBillSquareMeter: {
        tipo: "entrada",
        idCliente: "",
        idFuncionario: "",
        idFormaPagamento: "",
        idContaRecebimento: "",
        idVendaMtq: "",
        valorUnitario: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: moment().format("YYYY-MM-DD"),
      },
      totalParcelas: 1,
      intervaloDias: "",
      allPaymentsTypes: [],
      total: "0,00",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      totalValue: 0,
      discountToAllItems: false,
      discountToIndividuallyItem: false,
      discountIsCheked: false,
      billsFromModal: [],
      allAccountReceiptBills: [],
      paymentSelected: "",
      receiptBillSelected: "",
      isPaydBill: "",
      isDropBills: false,
      saleSquareMeterFromModal: {},
      squareMeterForModal: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsSale: [],
        data: "",
      },
      numberSaleSquareMeter: "",
      verifyBillIsPaid: false,
      paymentMoneyIsSelected: false,
      paymentsDataInfo: {
        receiptAccount: "",
        typePayment: "",
        expiredFirst: "",
        installmentsNumber: 0,
        installmentsInterval: 0,
      },
      squareMeterSituationType: SquareMeterSituationType,
      saleIsCanceled: false,
      situationSelected: null,
      createdNewCustomer: null,
      createdNewEmployee: null,
      createdNewProducts: null,
      createdNewAccountReceiptBill: null,
      maskCurrency: maskCurrency,
      salesSquareMeterType: SalesSquareMeterType,
      colors: [],
      rooms: [],
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE_SQUARE_METER",]),
    handleAddItensAndHandleAssignValues() {
      this.addMoreItens();
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    addMoreItens() {
      const idList = uuidv4();
      const newProducts = { ...INITIAL_STATE_PRODUCTS, idList };
      this.saleSquareMeter.products.push(newProducts);
      this.enableChangeDiscount();
      this.enableTooltipDiscount();

      this.cleanTotalValueLastProduct();
      this.addEventScroll()
    },

    async cleanTotalValueLastProduct() {
      await this.$nextTick();
      this.$refs.valorTotal[
        this.saleSquareMeter.products.length - 1
      ].valueInput = 0;
    },

    assignTotalItensValues() {
      const valorTotal = this.saleSquareMeter.products.reduce((oldValue, product) => {
        return oldValue + parseFloat(product.valorTotal)
      }, 0
      );

      this.totalOfItens = parseFloat(valorTotal).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        if (this.discountToAllItems) {
          return (this.totalOfDiscount =
            this.calculateTotalDiscountWithApllyToAll());
        }

        if (!this.discountToAllItems && this.discountIsCheked) {
          return (this.totalOfDiscount = this.calculateTotalDiscount());
        }
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    assignTotalValue() {
      const formattedTotal = mascaraMoeda(this.totalOfItens);
      const formattedTotalDiscount = mascaraMoeda(this.totalOfDiscount);
      const frete = this.saleSquareMeter.frete;

      const newTotal =
        formattedTotal - formattedTotalDiscount + (parseFloat(frete) || 0);

      this.totalValue = newTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithApllyToAll() {
      for (let i = 0; i < this.saleSquareMeter.products.length; i++) {
        this.saleSquareMeter.products[i].desconto =
          this.saleSquareMeter.products[0].desconto;
      }

      const formattedTotalItens = mascaraMoeda(this.totalOfItens);

      const discountTotal =
        (formattedTotalItens * this.saleSquareMeter.products[0].desconto) /
        100;

      return discountTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscount() {
      const discounts = this.saleSquareMeter.products.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.saleSquareMeter.products.map(
        (prod) => {
          if (prod.desconto) {
            const desc = prod.desconto;

            return desc;
          }

          return 0;
        }
      );

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async handleRemoveItens(idList, idItem) {
      if (idItem) {
        try {
          await this.removeItens(idItem);
        } catch (error) {
          return this.$toast.open({
            message:
              `${error.response.data.message}` ||
              "Ocorreu um erro ao remover o produto!",
            type: "info",
          });
        }
      }

      this.removeItensInArray(idList);
      this.removeEventScroll()
    },

    async cancelSaleSquareMeter() {
      try {
        await ServiceSaleSquareMeter.cancel(this.saleSquareMeter.id, this.saleSquareMeter.status);

        this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
          SquareMeterSituationType.cancelado
        );

        this.saleIsCanceled = !this.saleIsCanceled;
        this.clearAllBills();

        return this.$toast.open({
          message: "Venda cancelada!",
          type: "success",
        });
      } catch (error) {
        console.log(error)
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpen() {
      try {
        await ServiceSaleSquareMeter.reOpen(this.saleSquareMeter.id);

        if (this.saleSquareMeter.status === SalesSquareMeterType.venda) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.aprovado
          );
        } else {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.emAberto
          );
        }

        return this.$toast.open({
          message: this.statusSquareMeter === SalesSquareMeterType.orcamento ? "Orçamento reaberto!" : "Venda reaberta!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async removeItens(id) {
      await ServiceProductSaleSquareMeter.delete(id);

      return this.$toast.open({
        message: "Produto removido",
        type: "success",
      });
    },

    removeItensInArray(idList) {
      if (this.saleSquareMeter.products.length > 1) {
        this.saleSquareMeter.products =
          this.saleSquareMeter.products.filter(
            (item) => item.idList !== idList
          );

        this.$nextTick(() => {
          this.$refs.hideDiscount[0].style.display = "block";
        });

        if (this.discountToAllItems) {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        }

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        return this.assignTotalValue();
      }

      const newIdList = uuidv4();
      this.saleSquareMeter.products = [
        { ...INITIAL_STATE_PRODUCTS, newIdList },
      ];

      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
      this.cleanTotalValueLastProduct();
    },

    addEventScroll() {
      this.$nextTick(() => {
        if (this.$refs.scrollableDiv.scrollWidth > this.$refs.scrollableDiv.clientWidth)
          this.$refs.scrollableDiv.addEventListener('wheel', this.handleScroll);
      })
    },

    removeEventScroll() {
      this.$refs.scrollableDiv.removeEventListener('wheel', this.handleScroll);
    },

    handleScroll(event) {
      event.preventDefault();

      this.$refs.scrollableDiv.scrollBy({
        left: event.deltaY,
      })
    },

    proximoCardDadosVenda() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse");
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse2");
    },

    proximoCardAdicionarProdutos() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse2");
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse3");
    },

    proximoCardFinanceiro() {
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse3");
    },

    clearDataPaymentsSquareMeter() {
      this.dataBillSquareMeter.idCliente = "";
      this.dataBillSquareMeter.idFuncionario = "";
      this.dataBillSquareMeter.idFormaPagamento = "";
      this.dataBillSquareMeter.idContaRecebimento = "";
      this.dataBillSquareMeter.idVendaMtq = "";
      this.dataBillSquareMeter.idCor = "";
      this.dataBillSquareMeter.idAmbiente = "";
      this.dataBillSquareMeter.linear = false;
      this.dataBillSquareMeter.valorUnitario = "";
      this.dataBillSquareMeter.valorPago = "";
      this.dataBillSquareMeter.valorRestante = "";
      this.dataBillSquareMeter.data = moment().format("YYYY-MM-DD");
      this.totalParcelas = 1;
      this.intervaloDias = 30;
      this.saleSquareMeter.bills = [];
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async selectProductSetDefaultValue(idProduct, indice) {
      const product = await this.getProductById(idProduct);

      this.saleSquareMeter.products[indice].valorUnitario =
        product.valorVenda;
      this.$refs.refVlUnit[indice].valueInput = product.valorVenda;

      this.saleSquareMeter.products.forEach((prod) => {
        const amount = prod.quantidade;
        const unitaryValue = prod.valorUnitario;
        const total = amount * unitaryValue;
        this.saleSquareMeter.products[indice].valorTotal = total;
        this.$refs.valorTotal[indice].valueInput = total;
      });

      this.assignTotalItensValues();
      this.assignTotalValue();
    },

    alterStatusSale() {
      this.saleSquareMeter.status = "Venda";
    },

    async getCustomersForSelectBox() {
      try {
        const data = await ServiceCustomer.getCustomersForSelectBox();
        this.dataCustomers = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    readComissao() {
      const employee = this.dataEmployee.find(
        (emp) => this.saleSquareMeter.idFuncionario === emp.id
      );

      this.comissao = employee?.comissao;
      this.dataBillSquareMeter.idFuncionario =
        this.saleSquareMeter.idFuncionario;
    },

    async getProdutos() {
      const { data } = await api.get("/products");
      this.products = data.data;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    async getAllColors() {
      try {
        this.colors = await ServiceColors.fillComobobox()
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar as cores",
          type: "info"
        })
      }
    },

    async getAllRooms() {
      try {
        this.rooms = await ServiceRooms.fillComobobox()
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar os ambientes",
          type: "info"
        })
      }
    },

    handleGenerateBillsAndOpenModalBills() {
      if (
        (this.saleSquareMeter.bills.length &&
          this.saleSquareMeter.bills[0]?.id) ||
        (this.billsFromModal.length && this.billsFromModal[0]?.id)
      ) {
        this.verifyBillIsPaid = !this.verifyBillIsPaid;
        this.getPaymentInformation();
        return this.openModals("modalBillsSquareMeter");
      }

      const isNotValid = this.validateBills();
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.verifyBillIsPaid = !this.verifyBillIsPaid;
      this.handleGetTypePaymentAndReceiptBill();
      this.makingThePayment();
      this.getPaymentInformation();
      this.openModals("modalBillsSquareMeter");
    },

    handleGetTypePaymentAndReceiptBill() {
      if (this.dataBillSquareMeter.idFormaPagamento) {
        const typePayment = this.allPaymentsTypes.filter(
          (payment) => payment.id === this.dataBillSquareMeter.idFormaPagamento
        );

        const receiptBill = this.allAccountReceiptBills.filter(
          (receiptBills) =>
            receiptBills.id === this.dataBillSquareMeter.idContaRecebimento
        );

        this.paymentSelected = typePayment[0].tipo;
        this.receiptBillSelected = receiptBill[0].nome;
      }
    },

    handleDataToSendModalSquareMeter() {
      this.assingValuesSquareMeterForModal();
      this.getPaymentInformation();
      this.openModals("modalSaleSquareMeder");
    },

    assingValuesSquareMeterForModal() {
      const productsWithName = this.saleSquareMeter.products.map((prod) => {
        return {
          ...prod,
          nome: "",
        };
      });

      this.squareMeterForModal.formattedProductsSale = productsWithName.map(
        (prodSale) => {
          if (prodSale.idProduto) {
            return {
              ...prodSale,
              nome: this.products.filter(
                (prod) => prod.id === prodSale.idProduto
              )[0].nome,
            };
          }

          return prodSale;
        }
      );

      this.squareMeterForModal.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer.id === this.saleSquareMeter.idCliente
        )[0]?.nome;

      this.squareMeterForModal.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee.id === this.saleSquareMeter.idFuncionario
        )[0]?.nomeFuncionario;

      this.squareMeterForModal.data = this.saleSquareMeter.data;
    },

    getPaymentInformation() {
      if (this.billsFromModal.length) {
        this.paymentsDataInfo.receiptAccount = this.allAccountReceiptBills.find(
          (receiptBills) =>
            receiptBills.id === this.billsFromModal[0].idContaRecebimento
        ).nome;

        this.paymentsDataInfo.typePayment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.billsFromModal[0].idFormaPagamento
        ).tipo;

        this.paymentsDataInfo.expiredFirst = moment(
          this.billsFromModal.sort((a, b) => a.data - b.data)[0].data
        ).format("DD/MM/YYYY");

        this.paymentsDataInfo.installmentsNumber = this.billsFromModal.length;

        if (this.billsFromModal.length >= 2) {
          this.paymentsDataInfo.installmentsInterval = moment(
            this.billsFromModal[1].data
          ).diff(this.billsFromModal[0].data, "days");
        }
      }
    },

    clearPaymentsDataInfo() {
      this.paymentsDataInfo.receiptAccount = "";
      this.paymentsDataInfo.typePayment = "";
      this.paymentsDataInfo.expiredFirst = "";
      this.paymentsDataInfo.installmentsNumber = 0;
      this.paymentsDataInfo.installmentsInterval = 0;
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    validateBills() {
      const dataFromGenerateBill = {
        idCliente: this.saleSquareMeter.idCliente,
        idFormaDePagamento: this.dataBillSquareMeter.idFormaPagamento,
        idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
        intervaloDias: this.intervaloDias,
        vencimento: this.dataBillSquareMeter.data,
        quantidadeParcelas: this.totalParcelas,
      };

      return ValidateBillsWhenGenerating(dataFromGenerateBill);
    },

    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllPayment() {
      const result = await ServicePaymentForm.getAllPayment();
      this.allPaymentsTypes = result.sort((a, b) => a.cod - b.cod);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        return this.$toast.open({
          message: `${error.response.data.message ||
            "Ocorreu um erro ao carregar todas as situações"
            }`,
          type: "info",
        });
      }
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
    },

    clearAllBills() {
      this.clearDataPaymentsSquareMeter();
      this.clearInputForGenerateBills();
      this.billsFromModal = [];
      this.saleSquareMeter.bills = [];
      this.paymentSelected = "";
      this.receiptBillSelected = "";
    },

    clearInputForGenerateBills() {
      if (this.saleSquareMeter.status === SalesSquareMeterType.venda) {
        this.$refs.refContaDeRecebimento.valueItems = ""
        this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
        this.$refs.refVencimentoPrimeiraParcela.valueInput =
          this.dataBillSquareMeter.data;
        this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
      }
    },

    makingThePayment() {
      if (this.paymentMoneyIsSelected) {
        return this.handleMoneyPaymentSelected();
      }

      this.billsFromModal = [];
      const formattedTotalValue = mascaraMoeda(this.totalValue);

      const valorPorDuplicata = (formattedTotalValue / this.totalParcelas)
        .toFixed(2)
        .toLocaleString("pt-br", { minimumFractionDigits: 2 });

      for (let i = 0; i < this.totalParcelas; i++) {
        const dataVencimento =
          i == 0
            ? this.dataBillSquareMeter.data
            : moment(dataVencimento)
              .add(this.intervaloDias, "days")
              .format("YYYY-MM-DD");
        this.billsFromModal.push({
          id: "",
          tipo: "entrada",
          idCliente: this.saleSquareMeter.idCliente,
          idFuncionario: this.saleSquareMeter.idFuncionario,
          idFormaPagamento: this.dataBillSquareMeter.idFormaPagamento,
          idVendaMtq: this.saleSquareMeter.id,
          valorTotal: valorPorDuplicata,
          valorPago: 0.0,
          valorRestante: valorPorDuplicata,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          descricao: "",
          idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
        });
      }
    },

    enableTooltipDiscount() {
      if (
        this.$refs.typeDiscount[0].checked &&
        this.saleSquareMeter.products.length > 1
      ) {
        return (this.$refs.enableLabelApplyAll[0].style.display = "block");
      }

      this.discountToAllItems = false;
      this.$refs.enableLabelApplyAll[0].style.display = "none";
    },

    alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;

      if (this.discountIsCheked) {
        this.saleSquareMeter.tipoDesconto = "Porcentagem";
        this.discountToIndividuallyItem = true;
        this.handleCalculateTotalsValues();
      } else {
        this.saleSquareMeter.tipoDesconto = "Valor Monetário";
        this.discountToIndividuallyItem = false;
        this.handleCalculateTotalsValues();
      }
    },

    async enableChangeDiscount() {
      const refsDiscount = this.$refs.hideDiscount;
      await this.$nextTick();
      for (let i = 1; i < refsDiscount.length; i++) {
        this.$refs.hideDiscount[i].style.display = "none";
      }
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        const typesDiscount = this.$refs.typeDiscount;
        for (var i = 0; i < typesDiscount.length; i++) {
          this.$refs.typeDiscount[i].checked = true;
        }

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      }
    },

    async handleValuesAfterEditSale() {
      this.handleSquareMeterValues();
      this.assingProductsToSaleSquareMeter();

      await this.enableChangeDiscount();
    },

    handleSquareMeterValues() {
      this.handleBillsIfAny();
      this.handleSaleProductsIfAny();
      this.assignDataSearchFromSquareMeter();
    },

    handleBillsIfAny() {
      if (!this.dataSearchFromSquareMeter?.bills?.length) {
        return Reflect.deleteProperty(this.dataSearchFromSquareMeter, "bills");
      }

      this.billsFromModal = this.dataSearchFromSquareMeter.bills.map((bill) => {
        return {
          ...bill,
          data: bill.data.split(" ")[0],
        };
      });
    },

    handleSaleProductsIfAny() {
      if (!this.dataSearchFromSquareMeter.products.length) {
        Reflect.deleteProperty(this.dataSearchFromSquareMeter, "products");
      }
    },

    assignDataSearchFromSquareMeter() {
      Object.assign(this.saleSquareMeter, this.dataSearchFromSquareMeter);
      if (this.saleSquareMeter.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll[0].style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberSaleSquareMeter = this.dataSearchFromSquareMeter.numero;
      this.$refs.refCliente.valueItems = this.dataSearchFromSquareMeter.idCliente;
      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSquareMeter.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSquareMeter.data;

      if (this.$refs.dataVencimento)
        this.$refs.dataVencimento.valueInput =
          this.dataSearchFromSquareMeter.dataVencimento;

    },

    assingProductsToSaleSquareMeter() {
      this.saleSquareMeter.products = this.saleSquareMeter.products
        .map((item) => {
          return {
            ...item,
            idList: uuidv4(),
          };
        })
        .sort((a, b) => a.quantidade - b.quantidade);

      this.$nextTick(() => {
        for (let i = 0; i < this.saleSquareMeter.products.length; i++) {
          this.$refs.refItens[i].valueItems =
            this.saleSquareMeter.products[i].idProduto;

          this.$refs.refColors[i].valueItems =
            this.saleSquareMeter.products[i].idCor;

          this.$refs.refRooms[i].valueItems =
            this.saleSquareMeter.products[i].idAmbiente;

          this.$refs.refQtd[i].valueInput =
            this.saleSquareMeter.products[i].quantidade;

          this.$refs.refAlt[i].valueInput =
            this.saleSquareMeter.products[i].altura;

          this.$refs.refLarg[i].valueInput =
            this.saleSquareMeter.products[i].largura;

          this.$refs.refVlUnit[i].valueInput =
            this.saleSquareMeter.products[i].valorUnitario;

          this.$refs.discount[i].valueInput =
            this.saleSquareMeter.products[i].desconto;

          this.$refs.valorTotal[i].valueInput =
            this.saleSquareMeter.products[i].valorTotal;
        }
      });
    },

    toAssignDefaultSituation(situations) {
      if (this.statusSquareMeter === this.salesSquareMeterType.venda) {
        this.allSituations = situations.filter((situation) => {
          if (
            [
              SquareMeterSituationType.aprovado,
              SquareMeterSituationType.faturado,
              SquareMeterSituationType.cancelado,
            ].includes(situation.nome)
          ) {
            return situation;
          }
        });
      } else {
        this.allSituations = situations
          .filter((situation) => {
            if (
              [SquareMeterSituationType.emAberto, SquareMeterSituationType.cancelado].includes(
                situation.nome
              )
            ) {
              return situation;
            }
          })
          .reverse();
      }


      if (!this.saleSquareMeter.id) {
        if (this.statusSquareMeter === this.salesSquareMeterType.venda) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.aprovado
          );
        } else if (this.statusSquareMeter === this.salesSquareMeterType.orcamento) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.emAberto
          );
        }
      }
    },

    handleFreteMonetaryValue(event) {
      this.saleSquareMeter.frete = mascaraMoeda(event.target.value);
    },

    getSpecificSituantionId(squareMeterSituationType) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === squareMeterSituationType
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleMoneyPaymentSelected() {
      this.billsFromModal = [];

      const formattedTotalValue = mascaraMoeda(this.totalValue);
      const valorPorDuplicata = formattedTotalValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });

      this.billsFromModal.push({
        id: "",
        tipo: "entrada",
        idCliente: this.saleSquareMeter.idCliente,
        idFuncionario: this.saleSquareMeter.idFuncionario,
        idFormaPagamento: this.dataBillSquareMeter.idFormaPagamento,
        idVendaMtq: this.saleSquareMeter.id,
        valorTotal: valorPorDuplicata,
        valorPago: 0.0,
        valorRestante: valorPorDuplicata,
        data: moment().format("YYYY-MM-DD"),
        dataPagamento: moment().format("YYYY-MM-DD"),
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
      });
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.allPaymentsTypes.find(
        (formPayment) => formPayment.id === this.dataBillSquareMeter.idFormaPagamento
      );

      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );

        this.dataBillSquareMeter.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },
  },
  async mounted() {
    const idList = uuidv4();
    this.saleSquareMeter.products[0].idList = idList;
    this.getCustomersForSelectBox();
    this.listEmployeesSelectBox();
    this.getProdutos();
    this.getProviders();
    this.getAllPayment();
    this.getAllReceiptBill();
    this.getAllColors();
    this.getAllRooms();

    if (this.dataSearchFromSquareMeter.idCliente) {
      this.handleValuesAfterEditSale();
    }

    this.handleSituation();
    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataBudgetForSale: (state) => state.dataBudgetForSale,
      dataSearchFromSquareMeter: (state) => state.dataSearchFromSquareMeter,
      dataConfig: (state) => state.dataConfig,
    }),
    calculateTotalValue: {
      get() {
        return this.total;
      },
      set({ prod, indice }) {
        const amount = prod.quantidade;
        const unitaryValue = prod.valorUnitario;
        let meter

        if (!this.$refs.valorTotal[indice]) return;

        if (!this.enableEditMt2) {
          if (!this.saleSquareMeter.products[indice].linear)
            meter = parseFloat(prod.altura.toFixed(this.decimalPlaces) * prod.largura.toFixed(this.decimalPlaces)).toFixed(this.decimalPlaces)
          else
            meter = (parseFloat(prod.altura.toFixed(this.decimalPlaces)) + parseFloat(prod.largura.toFixed(this.decimalPlaces))).toFixed(this.decimalPlaces)
        } else {
          meter = this.saleSquareMeter.products[indice].metragem
        }

        const totalPerda = (meter * this.saleSquareMeter.products[indice].porcentagemPerda) / 100 || 0
        if (totalPerda > 0) {
          meter = parseFloat(parseFloat(meter) + totalPerda).toFixed(this.decimalPlaces)
        }

        this.saleSquareMeter.products[indice].metragem = parseFloat(meter);
        this.$refs.totalMeter[indice].valueInput = meter;

        const totalAmount = parseFloat(amount * meter).toFixed(this.decimalPlaces)
        const totalValue = (totalAmount * unitaryValue).toFixed(this.decimalPlaces)
        this.saleSquareMeter.products[indice].valorTotal = parseFloat(totalValue);
        this.total = parseFloat(totalValue).toFixed(this.decimalPlaces);

        this.$refs.valorTotal[indice].valueInput = parseFloat(this.total).toFixed(this.decimalPlaces);

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      },
    },
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais)
      }

      return 2;
    },
    enableEditMt2() {
      if (Object.keys(this.dataConfig).length) {
        return this.dataConfig?.venda_mtq?.edicao_mt2
      }

      return false;
    },
    lossPercentage() {
      if (this.dataConfig) {
        return Number(this.dataConfig.venda_mtq.porcentagem_perda)
      }

      return 2
    },
    applyDiscountToAllItems() {
      if (
        this.saleSquareMeter.products &&
        this.saleSquareMeter.products.length
      ) {
        return this.saleSquareMeter.products[0].desconto;
      }

      return 0;
    },
    typePaymentSelected() {
      return this.dataBillSquareMeter.idFormaPagamento;
    },
    freteToAdditionalValues() {
      return this.saleSquareMeter.frete;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    verifyIfBillIsRegistred() {
      if (this.billsFromModal.length && this.billsFromModal[0]?.id)
        return "Ver Parcelas";
      else return "Gerar Parcelas";
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.saleSquareMeter.idSituacao
      );

      if (situation && situation.nome === SquareMeterSituationType.aprovado) {
        return "orange";
      } else if (situation && situation.nome === SquareMeterSituationType.faturado) {
        return "#3e66ae";
      } else if (situation && situation.nome === SquareMeterSituationType.emAberto) {
        return "";
      }

      return "#9f1313";
    },
    showTextSaleOrBudget() {
      return this.statusSquareMeter === SalesSquareMeterType.orcamento ? SalesSquareMeterType.orcamento : SalesSquareMeterType.venda
    },
    isSaleOrBudgetSquareMeter() {
      return this.saleSquareMeter.status
    }
  },
  watch: {
    isSaleOrBudgetSquareMeter() {
      this.handleSituation()
    },
    typePaymentSelected() {
      if (this.typePaymentSelected) {
        const payment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.typePaymentSelected
        );
        if (payment.tipo === "Dinheiro") {
          this.paymentMoneyIsSelected = true;
          this.intervaloDias = 0;
          this.dataBillSquareMeter.data = moment().format("YYYY-MM-DD");
          this.totalParcelas = 1;
          this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
          this.$refs.refVencimentoPrimeiraParcela.valueInput =
            this.dataBillSquareMeter.data;
          this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
        } else {
          this.paymentMoneyIsSelected = false;
        }
      }
    },
    applyDiscountToAllItems() {
      if (this.discountToAllItems) {
        const refsDiscount = this.$refs.discount;

        for (var i = 0; i < refsDiscount.length; i++) {
          this.$refs.discount[i].valueInput =
            this.saleSquareMeter.products[0].desconto;

          this.saleSquareMeter.products[i].desconto =
            this.$refs.discount[i].valueInput;
        }
      }
    },
    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.saleSquareMeter.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    isDropBills() {
      this.clearAllBills();
      this.clearPaymentsDataInfo();
    },
    saleSquareMeterFromModal() {
      if (this.saleSquareMeterFromModal.id) {
        this.saleSquareMeter.id = this.saleSquareMeterFromModal.id;
      }

      if (this.saleSquareMeterFromModal.productsOfSale) {
        const newProductsFromTable =
          this.saleSquareMeterFromModal.productsOfSale.map((prod, indice) => {
            return {
              ...prod,
              idList: this.saleSquareMeter.products[indice].idList,
            };
          });

        this.saleSquareMeter.products = newProductsFromTable;
      }

      if (this.saleSquareMeterFromModal.billsOfSaleSquareMeter) {
        const newBills = this.saleSquareMeterFromModal.billsOfSaleSquareMeter.map((bill) => {
          return { ...bill };
        });

        this.saleSquareMeter.bills = newBills;
        this.billsFromModal = newBills;
      }
    },
    createdNewCustomer() {
      this.getCustomersForSelectBox();
    },
    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
    createdNewProducts() {
      this.getProdutos();
    },
    createdNewAccountReceiptBill() {
      this.getAllReceiptBill();
    },
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_SALE_SQUARE_METER({})
  }
}
