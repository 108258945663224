import Joi from "joi";
export const ValidadeBudget = (budget) => {
  const schema = Joi.object({
    idCliente: Joi.string().guid({ version: ["uuidv4"] }).required().messages({
      'string.empty': '(Cliente) deve ser selecionado para prosseguir'
    }),
    idFuncionario: Joi.string().guid({ version: ["uuidv4"] }).optional().allow('', null),
    data: Joi.date().required().messages({
      'date.base': 'Campo data deve ser um formato valido',
      'date.format': 'Campo data deve ser um formato valido'
    }),
    dataVencimento: Joi.date().optional().allow(null, ''),
    dadosAdicionais: Joi.string().optional().allow(''),
    observacoesNf: Joi.string().optional().allow(''),
    status: Joi.valid("Orçamento", "Venda").required(),
    tipo: Joi.valid("Orçamento", "Venda", "Frente de caixa", "Orçamento MTQ", "Venda MTQ").required(), //Venda, Frente de caixa, Venda MTQ
    frete: Joi.alternatives().try(Joi.string(), Joi.number()).optional(),
    tipoDesconto: Joi.valid("Valor Monetário", "Porcentagem"),
    products: Joi.array().items({
      idVenda: Joi.string().guid({ version: ["uuidv4"] }).optional().allow('', null),
      idFornecedor: Joi.string().guid({ version: ["uuidv4"] }).optional().allow('', null),
      idProduto: Joi.string().guid({ version: ["uuidv4"] }).required().messages({
        'string.empty': '(Produto) deve ser selecionado para prosseguir'
      }),
      quantidade: Joi.number().required().messages({
        'number.base': '(quantidade) deve ser um campo numérico',
      }),
      valorUnitario: Joi.number().required().messages({
        'number.base': '(Valor unitário) deve ser um campo numérico',
      }),
      valorTotal: Joi.number().required().messages({
        'number.base': '(Valor) total não pode ser vazio e deve ser numérico para prosseguir',
      }),
      desconto: Joi.number().required().messages({
        'number.base': '(Desconto) não pode ser vazio e deve ser numérico para prosseguir'
      }),
      dadosAdicionais: Joi.string().optional().allow(''),
    }),
  })

  const { error } = schema.validate(budget, {
    allowUnknown: true,
  })

  return error?.details[0]?.message
}