<template>
  <div class="limiter">
    <div class="container-general-login" v-if="!getToken">
      <div class="content-login">
        <div :class="`content-login-form`" v-if="sectionsEnable.login">
          <div class="content-login-img">
            <span>
              <img style="width: 120px" :src="logoBG" />
            </span>
          </div>
          <div class="content-form">
            <p class="text-login">Acessar</p>
            <div class="content-login-wrap-input">
              <b-input
                class="content-login-input"
                type="text"
                name="email"
                placeholder="Usuário"
                v-model="login.email"
              />
              <span class="content-login-focus-input"></span>
              <span class="content-login-symbol-input">
                <i><b-icon-envelope-fill font-scale="1.1" /></i>
              </span>
            </div>

            <div
              class="content-login-wrap-input validate-input"
              data-validate="Password is required"
            >
              <b-input
                class="content-login-input"
                type="password"
                name="pass"
                placeholder="Senha"
                @keyup.enter="sendLogin"
                v-model="login.password"
              />
              <span class="content-login-focus-input"></span>
              <span class="content-login-symbol-input">
                <i><b-icon-lock-fill font-scale="1.1" /></i>
              </span>
            </div>

            <div class="content-login-form-btn">
              <b-button class="login-form-btn" @click="sendLogin"
                >Entrar</b-button
              >
            </div>

            <div
              class="text-center"
              @click="handleSectionsEnable('tester')"
              style="padding-top: 12px"
            >
              <a class="txt1"> Teste GRÁTIS! Clique aqui! </a>
            </div>

            <div
              class="text-center"
              @click="handleSectionsEnable('forgotMyPassword')"
              style="padding-top: 12px"
            >
              <a class="forgotMyPassword"> Esqueci minha senha </a>
            </div>

            <div class="logoBottom">
              <img style="width: 50px; height: 50px" :src="LogoBottom" />
            </div>
          </div>
        </div>

        <div
          v-if="!sectionsEnable.login"
          :class="`content-login-form-saiba-mais`"
        >
          <div
            class="content-login-voltar"
            @click="handleSectionsEnable('login')"
          >
            <span class="d-flex justify-content-center">
              <i
                ><b-icon-arrow-left-circle variant="dark" font-scale="1.2"
              /></i>
            </span>
          </div>

          <div
            v-if="sectionsEnable.tester"
            style="
              display: flex;
              flex-direction: column;
              gap: 25px;
              padding: 10px;
              overflow: auto;
              height: 90%;
              max-height: 95vh;
            "
          >
            <div class="content-login-img-saiba login-img-saiba-centralized">
              <span>
                <img style="width: 100px" :src="logoBG" />
              </span>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.nome"
              />
              <label class="user-label"> Nome completo * </label>
            </div>

            <div class="selectPlus">
              <label class="fixedSelect">Tipo identificação *</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="tipo"
                v-model="company.type"
              >
              </b-form-select>
            </div>

            <div v-if="company.type === 1" class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.cpfcnpj"
                v-mask="maskcpfcnpj"
                maxlength="14"
              />
              <label class="user-label"> CPF * </label>
            </div>

            <div v-if="company.type === 2" class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.cpfcnpj"
                v-mask="maskcpfcnpj"
                maxlength="18"
              />
              <label class="user-label"> CNPJ * </label>
            </div>

            <div class="selectPlus">
              <label class="fixedSelect">Cargo Atual</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="cargo"
                v-model="email.office"
              >
              </b-form-select>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.companyName"
              />
              <label class="user-label">Nome da Empresa * </label>
            </div>

            <div class="selectPlus">
              <label class="fixedSelect">Ramo de Atuação</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="ramo"
                v-model="email.acting"
              >
              </b-form-select>
            </div>

            <div class="selectPlus">
              <label class="fixedSelect">N° de Funcionários</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="funcionario"
                v-model="email.employee"
              >
              </b-form-select>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.telefone"
                v-mask="maskCelular"
              />
              <label class="user-label">Whatsapp * </label>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.email"
              />
              <label class="user-label">Email * </label>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="logger.password"
              />
              <label class="user-label">Crie uma Senha * </label>
            </div>

            <div class="content-login-form-btn" @click="newCompany">
              <b-button class="login-form-btn"
                >Iniciar 30 dias de teste!</b-button
              >
            </div>
          </div>

          <div
            v-if="sectionsEnable.forgotMyPassword"
            class="container-password"
          >
            <div class="content-reset-password">
              <div class="content-header-reset-password mb-3">
                <div class="content-login-img-saiba">
                  <span>
                    <img style="width: 100px" :src="logoBG" />
                  </span>
                </div>
                <p
                  class="title-new-account"
                  @click="handleSectionsEnable('tester')"
                >
                  Teste grátis
                </p>
              </div>

              <div class="container-middle-reset-password">
                <div class="content-middle-reset-password">
                  <div class="content-titles-reset-password mb-3">
                    <p class="title-password">Esqueceu sua senha ?</p>

                    <p class="subtitle-reset-password">
                      Não se preocupe, enviaremos instruções de redefinição para
                      seu email.
                    </p>
                  </div>

                  <div class="input-group">
                    <input
                      required
                      :class="`input borderInput ${invalidinputEmailToResetPassword}`"
                      v-model="emailToResetPassword"
                      ref="emailToResetPassword"
                      @input="clearInvalidInput"
                      @blur="clearInvalidInput"
                    />
                    <label class="user-label">Email * </label>
                  </div>

                  <div class="content-login-form-btn" @click="forgotMyPassword">
                    <b-button class="reset-password-btn"
                      >Recuperar senha</b-button
                    >
                    <p class="invalidMessage">{{ invalidMessage }}</p>
                  </div>
                </div>

                <div class="container-back-password">
                  <div
                    class="content-back-password"
                    @click="handleSectionsEnable('login')"
                  >
                    <b-icon-arrow-left-short scale="1.5" style="color: gray" />
                    <p class="subtitle-back-password">Voltar ao login</p>
                  </div>
                </div>
              </div>

              <div
                v-if="spinResetPassword"
                class="container-loading-reset-password"
              >
                <b-spinner class="spin-reset-password" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-img">
        <b-carousel
          id="carousel-no-animation"
          img-width="1024"
          img-height="680"
          fade
          no-animation
          indicators
        >
          <b-carousel-slide
            caption=""
            class="imgCarousel"
            :img-src="Carrossel1"
          ></b-carousel-slide>
          <b-carousel-slide
            caption=""
            class="imgCarousel"
            :img-src="Carrossel2"
          ></b-carousel-slide>
          <b-carousel-slide
            caption=""
            class="imgCarousel"
            :img-src="Carrossel3"
          ></b-carousel-slide>
          <b-carousel-slide
            caption=""
            class="imgCarousel"
            :img-src="Carrossel4"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </div>

    <div class="container-reset-password" v-else>
      <ResetPassword />
    </div>

    <b-modal id="modal-loader" hide-footer hide-header centered title="loader">
      <div v-if="hourglass" class="contentLoader">
        <div class="alertClock">
          <div class="hourglassBackground">
            <div class="hourglassContainer">
              <div class="hourglassCurves"></div>
              <div class="hourglassCapTop"></div>
              <div class="hourglassGlassTop"></div>
              <div class="hourglassSand"></div>
              <div class="hourglassSandStream"></div>
              <div class="hourglassCapBottom"></div>
              <div class="hourglassGlass"></div>
            </div>
          </div>
        </div>
        <span>Aguarde , estamos realizando seu cadastro!</span>
      </div>
      <div v-if="isCheck" class="contentLoader">
        <img class="loaderCheck" :src="Check" />
        <span>Cadastro realizado com sucesso</span>
        <span>Aguarde , estamos redirecionando para o sistema!</span>
      </div>
      <div v-if="isWarning" class="contentLoader">
        <img class="loaderCheck" :src="WarningLoader" />
        <span class="loaderTextWarning"
          >Nao foi possivel realizar o cadastro. Verifique os dados e tente
          novamente!</span
        >
        <div class="loaderList">
          <span>Possíveis Erros:</span>
          <ul>
            <li>CNPJ incorreto ou já cadastrado.</li>
            <li>Email incorreto ou já cadastrado.</li>
            <li>Celular incorreto.</li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResetPassword from "../ResetPassword/ResetPassword.vue";
import logoBG from "../../assets/logoBG.png";
import LogoBottom from "../../assets/imgCircle.png";
import Carrossel1 from "../../assets/carrossel1.png";
import Carrossel2 from "../../assets/carrossel2.png";
import Carrossel3 from "../../assets/carrossel3.png";
import Carrossel4 from "../../assets/carrossel4.png";
import Check from "../../assets/register-check.svg";
import WarningLoader from "../../assets/register-warning.svg";
import LoginService from "../../services/serviceLogin";
import ServiceMail from "../../services/serviceMail";
import { clearTokens } from "../../utils/clearTokens";
import { ValidateEmailResetPassword } from "../../validators/emailResetPassword";
import { HandleErrors } from "../../utils/handleErrors";
import api from "../../services/axios";
import moment from "moment";

export default {
  components: {
    ResetPassword,
  },
  data() {
    return {
      Carrossel1: Carrossel1,
      Carrossel2: Carrossel2,
      Carrossel3: Carrossel3,
      Carrossel4: Carrossel4,
      LogoBottom: LogoBottom,
      logoBG: logoBG,
      Check: Check,
      WarningLoader: WarningLoader,
      typeWaveAnimation: "",
      visibilitySaibaMais: "hideLogin",
      visibilityLogin: "showLogin",
      datainiciouso: moment().format("YYYY-MM-DD"),
      isCheck: false,
      isWarning: false,
      hourglass: true,
      infoNewClient: {},
      login: {
        email: "",
        password: "",
      },
      companyTotal: { companyCad: {}, companyLogger: {}, companyMail: {} },
      company: {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
      },
      logger: {
        nome: "",
        email: "",
        password: "",
      },
      email: {
        office: "",
        acting: "",
        employee: "",
      },
      sectionsEnable: {
        login: true,
        tester: false,
        forgotMyPassword: false,
      },
      tipo: [
        { value: 1, text: "CPF" },
        { value: 2, text: "CNPJ" },
      ],
      cargo: [
        { value: "", text: "Selecione..." },
        { value: "Analista Contas a Pagar", text: "Analista Contas a Pagar" },
        {
          value: "Analista Contas a Receber",
          text: "Analista Contas a Receber",
        },
        { value: "Analista Faturamento", text: "Analista Faturamento" },
        { value: "Compras/Produção", text: "Compras/Produção" },
        { value: "Contabilidade/Fiscal", text: "Contabilidade/Fiscal" },
        {
          value: "Coordenador/Gerente Financeiro",
          text: "Coordenador/Gerente Financeiro",
        },
        { value: "Diretor Financeiro/CFO", text: "Diretor Financeiro/CFO" },
        { value: "Diretor/Sócio", text: "Diretor/Sócio" },
        { value: "Estagiário/Assistente", text: "Estagiário/Assistente" },
        { value: "TI", text: "TI" },
        { value: "Vendas/Comercial", text: "Vendas/Comercial" },
        { value: "Outros", text: "Outros" },
      ],
      ramo: [
        { value: "", text: "Selecione..." },
        { value: "Alimentação e Bebidas", text: "Alimentação e Bebidas" },
        { value: "Vestuário e calçados", text: "Vestuário e calçados" },
        { value: "Construção", text: "Construção" },
        { value: "Saúde", text: "Saúde" },
        { value: "Educação", text: "Educação" },
        { value: "Serviços pessoais", text: "Serviços pessoais" },
        { value: "Serviços especializados", text: "Serviços especializados" },
        { value: "Informática/Tecnologia", text: "Informática/Tecnologia" },
        { value: "Outro", text: "Outro" },
      ],
      funcionario: [
        { value: "", text: "Selecione..." },
        { value: "1 a 5", text: "De 1 a 5 funcionários" },
        { value: "6 até 15", text: "De 6 até 15 funcionários" },
        { value: "16", text: "Mais de 16 funcionários" },
      ],
      emailToResetPassword: "",
      spinResetPassword: false,
      invalidMessage: "",
      invalidinputEmailToResetPassword: "",
    };
  },
  methods: {
    handleSectionsEnable(name) {
      this.sectionsEnable = {
        login: false,
        tester: false,
        forgotMyPassword: false,
        [name]: true,
      };

      if (name !== "login") this.clearAllInputs();
    },
    async sendLogin() {
      try {
        if (this.login.email === "" || this.login.password === "") {
          return this.$toast.open({
            message: "O campo login e senha devem ser preenchidos",
            type: "info",
          });
        }

        const { token, permissions, userLevel } = await LoginService.logar(
          this.login
        );

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorage.setItem("userLevel", userLevel);

        const tokenList = JSON.parse(localStorage.getItem("tokenList"));
        if (tokenList && tokenList.length) {
          tokenList.push({
            token,
            userLevel,
            permissions,
            nameCompany: this.dataCompany.nome,
          });

          localStorage.setItem("tokenList", JSON.stringify(tokenList));
        }

        if (userLevel === "accountant") {
          return this.$router
            .push({
              path: "/area-contador",
            })
            .then(() => {
              location.reload();
            });
        } else if (
          userLevel !== "adm" &&
          permissions?.configuracoes?.registroPonto?.painelPrincipal
        ) {
          return this.$router
            .push({
              path: "/registro-ponto",
            })
            .then(() => {
              location.reload();
            });
        }

        this.$router
          .push({
            path: "/home",
          })
          .then(() => {
            location.reload();
          });
      } catch (error) {
        console.log(error);
        return this.$toast.open({
          message:
            error.response.data.message ||
            "Verifique se o login e senha estão corretos!",
          type: "info",
        });
      }
    },

    async sendMail() {
      await api.post("/mail/info/client", this.infoNewClient);
    },

    async forgotMyPassword() {
      try {
        const isValid = ValidateEmailResetPassword(this.emailToResetPassword);
        if (isValid) {
          return this.handleInputEmailToResetPassword();
        }

        this.spinResetPassword = true;

        await ServiceMail.confirmCode({ email: this.emailToResetPassword });

        this.handleSectionsEnable("login");
        this.emailToResetPassword = "";
        this.$toast.open({
          message: "Email encaminhado",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao enviar o email",
          type: "info",
        });
      } finally {
        this.spinResetPassword = false;
        this.loadingPassword = "";
      }
    },

    handleInputEmailToResetPassword() {
      this.$refs.emailToResetPassword.focus();
      this.invalidinputEmailToResetPassword =
        "invalidinputEmailToResetPassword";
      this.invalidMessage = "* Campo Email deve ser um email válido!";
    },

    clearInvalidInput() {
      this.invalidinputEmailToResetPassword = "";
      this.invalidMessage = "";
    },

    clearAllInputs() {
      this.company = {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
      };

      this.logger = {
        nome: "",
        email: "",
        password: "",
      };

      this.email = {
        office: "",
        acting: "",
        employee: "",
      };

      this.emailToResetPassword = "";
    },

    verifyExitsToken() {
      if (!this.getToken) {
        const token = localStorage.getItem("token");
        if (token) {
          return this.$router.push({
            path: "/home",
          });
        }

        clearTokens();
      }
    },

    assingValueCadCompany() {
      let currentDate = moment();
      let dateAfter30Days = currentDate.add(30, "days");
      let dateFormatted = dateAfter30Days.format("YYYY-MM-DD");
      this.logger.nome = this.company.nome;
      this.logger.email = this.company.email;
      this.company.datainiciouso = this.datainiciouso;
      this.company.datafimuso = dateFormatted;
      this.companyTotal.companyCad = this.company;
      this.companyTotal.companyLogger = this.logger;
      this.companyTotal.companyMail = this.email;
      this.infoNewClient = this.companyTotal;
    },

    async redirectAndLogger() {
      this.login.email = this.logger.email;
      this.login.password = this.logger.password;
      this.handleSectionsEnable("login");

      this.$bvModal.hide("modal-loader");
      await this.sendLogin();
    },

    async newCompany() {
      try {
        this.$bvModal.show("modal-loader");
        this.assingValueCadCompany();
        // await this.sendMail();
        await api.post("/gestao/tester", this.companyTotal);
        this.isCheck = true;
        this.hourglass = false;
        this.redirectAndLogger();
      } catch (error) {
        this.hourglass = false;
        this.isCheck = false;
        this.isWarning = true;
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao salvar o novo usuario",
          type: "info",
        });
      }
    },
  },
  computed: {
    ...mapState(["dataCompany"]),
    maskCelular() {
      return "(##) #####-####";
    },
    maskcpfcnpj() {
      if (this.company.cpfcnpj === null) {
        return "";
      }
      if (this.company.cpfcnpj.length <= 14) {
        return "###.###.###-##";
      }
      if (this.company.cpfcnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },

    getToken() {
      return this.$route.query.token;
    },
  },
  mounted() {
    this.verifyExitsToken();
  },
};
</script>

<style scoped>
/*------------------[FORMATAÇÃO]--------------------*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: monospace;
}

body,
html {
  height: 100%;
  font-family: monospace;
}

a {
  font-family: monospace;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  border-bottom: solid 1px rgb(0, 0, 0);
}

.content-login-voltar {
  display: flex;
  position: absolute;
  left: 30px;
  align-items: center;
}

input {
  outline: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

.carousel {
  position: relative;
  height: 95vh;
  max-height: 100vh;
}
/*------------------[FORMATAÇÃO]--------------------*/

.txt1 {
  font-family: Arsenal-Regular, sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  font-style: oblique;
}

.forgotMyPassword {
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}

.forgotMyPassword:hover {
  text-decoration: underline;
  border-bottom: none;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-general-login {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}

.container-reset-password {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #002a3f;
}

.content-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.text-login {
  font-weight: bold;
  font-size: 24px;
  color: #002a3f;
  padding-bottom: 30px;
}

.content-login {
  width: 40%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 55px;
}

.logoBottom {
  margin-top: 70px;
}

.content-img {
  width: 70%;
  max-width: 70%;
  height: 100vh;
  max-height: 100vh;
  background: #002a3f;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-top-left-radius: 2% 25px;
  border-bottom-left-radius: 2% 25px; */
}

.imgCarousel {
  margin-top: 50px;
  height: 80vh !important;
}

.img-fluid {
  max-width: 100%;
  height: 95vh !important;
}

.content-login-img {
  display: flex;
  width: 100%;
  padding-bottom: 30px;
}

.content-login-img-saiba {
  display: flex;
  padding-bottom: 10px;
}

.login-img-saiba-centralized {
  justify-content: center;
  width: 100%;
}

.content-login-form {
  width: 100%;
  z-index: 1;
  height: 540px;
  display: block;
}

.content-login-form-saiba-mais {
  width: 100%;
  height: 100%;
  display: block;
}

.content-login-wrap-input {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.content-login-input {
  font-family: Arsenal-Medium, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  width: 100%;
  background: transparent;
  height: 30px;
  box-shadow: none;
  border-radius: 3px;
  border-bottom: solid 1px #222124;
  padding: 0 30px 0 68px;
}

.content-login-input:focus {
  box-shadow: none;
  border-bottom: solid 1px #0241ee;
}

.content-login-input-teste {
  font-family: Arsenal-Medium, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  color: #37363a;
  width: 100%;
  background: transparent;
  height: 30px;
  box-shadow: none;
  border-radius: 3px;
  border-bottom: solid 1px #37363a;
  padding: 0 0px 0 8px;
}

.content-login-input-teste:focus {
  box-shadow: none;
  background: rgb(238, 238, 238);
  border-bottom: solid 1px #3e66ae;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.input-group {
  width: 100%;
}

.user-label {
  position: absolute;
  left: 5px;
  font-size: 13px;
  z-index: 30;
  bottom: 12px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: transparent;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.fixedSelect {
  font-family: Arsenal-Medium, sans-serif;
  font-size: 12px;
  position: absolute;
  margin-bottom: 0px;
  bottom: 20px;
  left: 5px;
  z-index: 2;
  color: #424146;
  background-color: transparent;
}

.borderInput {
  height: 32px;
  width: 100%;
  font-size: 13px;
  padding: 0 10px;
  border-radius: 3px;
  border: none;
  background-color: rgba(237, 237, 237, 0.77);
  border-bottom: solid 1px #37363a;
}

.invalidinputEmailToResetPassword {
  border: solid 1px red !important;
  box-shadow: 0 0 2px 0 rgba(255, 0, 0, 0.613);
}

/*------------------------------------------------------------------
[ Focus ]*/
.content-login-focus-input {
  display: block;
  position: absolute;
  box-shadow: none;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.content-login-symbol-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #66666670;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.content-login-input:focus
  + .content-login-focus-input
  + .content-login-symbol-input {
  color: rgb(0, 0, 0);
  padding-left: 28px;
}

/*------------------------------------------------------------------*/

/*------------------------------------------------------------------
[ Button ]*/
.content-login-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.login-form-btn {
  text-align: center;
  font-size: 14px;
  background: #222124;
  border-radius: 3px;
  font-family: sans-serif;
  width: 100%;
  height: 30px;
  border: none;
  box-shadow: none;
}

.reset-password-btn {
  text-align: center;
  font-size: 14px;
  background: #5d8dcd;
  border-radius: 3px;
  font-family: sans-serif;
  width: 100%;
  height: 30px;
  border: none;
  box-shadow: none;
}

.reset-password-btn:hover {
  background: #4d76ab;
}

.login-form-btn:hover {
  background: #010758;
}

.content-login-saiba-mais {
  z-index: 1;
}

.container-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.content-reset-password {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  height: 80%;
  padding: 0.3rem 1.5rem 2.5rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  background-color: #ffffff;
}

.content-header-reset-password {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-new-account {
  color: #6499dd;
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.title-new-account:hover {
  color: #4d76ab;
  transition: ease-in all 0.1s;
}

.container-middle-reset-password {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-middle-reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 4.5rem;
}

.content-titles-reset-password {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title-password {
  text-align: center;
  font-size: 20px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: 600 !important;
  color: #222124;
}

.subtitle-reset-password {
  text-align: center;
  font-size: 12px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: normal !important;
  color: gray;
}

.container-back-password {
  display: flex;
  width: 100%;
}

.content-back-password {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.content-back-password:hover {
  text-decoration: underline rgba(128, 128, 128, 0.65);
}

.subtitle-back-password {
  font-size: 13px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: normal !important;
  color: gray;
}

.container-loading-reset-password {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 0.174);
  border-radius: 8px;
  z-index: 1000;
}

.spin-reset-password {
  color: rgb(128, 128, 128);
}

.invalidMessage {
  width: 100%;
  text-align: center;
  color: red;
  font-size: 12px;
  margin-top: 1rem !important;
}
/*---------------- RESPONSIVE ------------- */

@media screen and (max-height: 900px) {
  .container-password {
    height: 100% !important;
  }

  .content-reset-password {
    height: 100%;
  }
}

@media (max-width: 1022px) {
  .login-form-btn {
    height: auto;
  }
}

@media (max-width: 500px) {
  .content-login {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }
}

/*---------------- RESPONSIVE ------------- */

.contentLoader {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.loaderCheck {
  width: 130px;
  height: 130px;
}

.loaderTextWarning {
  text-align: center;
}

.loaderList {
  font-size: 11px;
  display: flex;
  width: 100%;
  padding: 0 0 10px 30px;
  flex-direction: column;
}

.hourglassBackground {
  position: relative;
  background-color: #002a3f;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin: 30px auto;
}

.hourglassContainer {
  position: absolute;
  top: 30px;
  left: 40px;
  width: 50px;
  height: 70px;
  -webkit-animation: hourglassRotate 2s ease-in 0s infinite;
  animation: hourglassRotate 2s ease-in 0s infinite;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.hourglassContainer div,
.hourglassContainer div:before,
.hourglassContainer div:after {
  transform-style: preserve-3d;
}

@-webkit-keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.hourglassCapTop {
  top: 0;
}

.hourglassCapTop:before {
  top: -25px;
}

.hourglassCapTop:after {
  top: -20px;
}

.hourglassCapBottom {
  bottom: 0;
}

.hourglassCapBottom:before {
  bottom: -25px;
}

.hourglassCapBottom:after {
  bottom: -20px;
}

.hourglassGlassTop {
  transform: rotateX(90deg);
  position: absolute;
  top: -16px;
  left: 3px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #999999;
}

.hourglassGlass {
  perspective: 100px;
  position: absolute;
  top: 32px;
  left: 20px;
  width: 10px;
  height: 6px;
  background-color: #999999;
  opacity: 0.5;
}

.hourglassGlass:before,
.hourglassGlass:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #999999;
  left: -17px;
  width: 44px;
  height: 28px;
}

.hourglassGlass:before {
  top: -27px;
  border-radius: 0 0 25px 25px;
}

.hourglassGlass:after {
  bottom: -27px;
  border-radius: 25px 25px 0 0;
}

.hourglassCurves:before,
.hourglassCurves:after {
  content: "";
  display: block;
  position: absolute;
  top: 32px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #333;
  animation: hideCurves 2s ease-in 0s infinite;
}

.hourglassCurves:before {
  left: 15px;
}

.hourglassCurves:after {
  left: 29px;
}

@-webkit-keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.hourglassSandStream:before {
  content: "";
  display: block;
  position: absolute;
  left: 24px;
  width: 3px;
  background-color: white;
  -webkit-animation: sandStream1 2s ease-in 0s infinite;
  animation: sandStream1 2s ease-in 0s infinite;
}

.hourglassSandStream:after {
  content: "";
  display: block;
  position: absolute;
  top: 36px;
  left: 19px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  animation: sandStream2 2s ease-in 0s infinite;
}

@-webkit-keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@-webkit-keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.hourglassSand:before,
.hourglassSand:after {
  content: "";
  display: block;
  position: absolute;
  left: 6px;
  background-color: white;
  perspective: 500px;
}

.hourglassSand:before {
  top: 8px;
  width: 39px;
  border-radius: 3px 3px 30px 30px;
  animation: sandFillup 2s ease-in 0s infinite;
}

.hourglassSand:after {
  border-radius: 30px 30px 3px 3px;
  animation: sandDeplete 2s ease-in 0s infinite;
}

@-webkit-keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@-webkit-keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}

@keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}
@media (max-width: 768px) {
  .content-img {
    display: none;
  }

  .content-login {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .content-form {
    gap: 30px;
  }

  .content-reset-password {
    width: 400px;
  }
}

@media (max-width: 780px) and (max-height: 900px) {
  .imgCarousel {
    margin-top: 300px;
  }
}
@media (max-width: 780px) and (max-height: 900px) {
  .imgCarousel {
    margin-top: 300px;
  }
}
@media (min-width: 1366px) and (max-height: 800px) {
  .imgCarousel {
    margin-top: 50px;
  }
}
@media (min-width: 1920px) and (max-height: 1080px) {
  .imgCarousel {
    margin-top: 200px;
  }
}
</style>