<template>
  <div class="dashboard">
    <div class="titleMobile">
      <span>Resumo Principal</span>
    </div>
    <div class="contentCard">
      <div class="cardItem">
        <div class="textHeader">Vendas do Dia</div>
        <div v-if="spinGeneral" class="card_content">
          <b-spinner class="spin"></b-spinner>
        </div>
        <div v-else class="card_content">
          {{
            (this.totalDayFront + this.totalDaySale).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </div>
      </div>
      <div class="cardItem">
        <div class="textHeader">Orçamentos do Dia</div>
        <div v-if="spinGeneral" class="card_content">
          <b-spinner class="spin"></b-spinner>
        </div>
        <div v-else class="card_content">
          {{
            this.totalDayBudget.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </div>
      </div>
      <div class="cardItem">
        <div class="textHeader">A receber hoje</div>
        <div v-if="spinGeneral" class="card_content">
          <b-spinner class="spin"></b-spinner>
        </div>
        <div v-else class="card_content">
          {{
            this.receivedSummary.expireToday.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </div>
      </div>
      <div class="cardItem">
        <div class="textHeader">A pagar hoje</div>
        <div v-if="spinGeneral" class="card_content">
          <b-spinner class="spin"></b-spinner>
        </div>
        <div v-else class="card_content">
          {{
            this.payableSummary.expireToday.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </div>
      </div>
    </div>
    <div class="entrancExitContent">
      <div class="entrancExit">
        <router-link to="/financeiro">
          <div class="textHeader">Recebimentos em Atrasos do Mês</div>
          <div v-if="spinGeneral" class="card_content">
            <b-spinner class="spin"></b-spinner>
          </div>
          <div v-else class="entrancValue">
            {{
              this.receivedSummary.sumMatury.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </div>
        </router-link>
      </div>
      <div class="entrancExit">
        <router-link to="/financeiro">
          <div class="textHeader">Pagamentos em Atrasos do Mês</div>
          <div v-if="spinGeneral" class="card_content">
            <b-spinner class="spin"></b-spinner>
          </div>
          <div v-else class="entrancExitValue">
            {{
              this.payableSummary.sumMatury.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="contentGraphics" v-if="this.userLevel === 'adm'">
      <div class="cardGraphic">
        <GraphicLine />
      </div>
      <div class="cardGraphic1">
        <GraphicBar />
      </div>
    </div>
    <div class="contentGraphics" v-else>
      <div class="cardGraphicNull">
        <b-icon-exclamation-triangle-fill style="color: #f2a83e" />
        <span>Sem Informações</span>
      </div>
      <div class="cardGraphic1Null">
        <b-icon-exclamation-triangle-fill style="color: #f2a83e" />
        <span>Sem Informações</span>
      </div>
    </div>

    <div class="rocketBkg">
      <img style="width: 100%; height: 100%" :src="rocket" />
    </div>

    <div class="containerSchedule" v-if="this.lentscheduleDay > 0">
      <div class="btnSchedule buttonToOpenSideCommon" @click="sideOpenCompany">
        <b-icon-calendar2-week
          class="buttonToOpenSideCommon"
          @click="sideOpenCompany"
        />
        <div
          class="countEvents buttonToOpenSideCommon"
          @click="sideOpenCompany"
        >
          {{ this.lentscheduleDay }}
        </div>
      </div>
      <div :class="`${contentSideOCompany}`">
        <span class="titleSchedule">Eventos do dia</span>
        <div
          :class="sideOCompany"
          v-for="scheduleDay in allScheduleDay"
          :key="scheduleDay.id"
        >
          <div class="hourSchedule">
            <span> {{ scheduleDay.dataAgenda.split(" ")[1].slice(0, 5) }}</span>
          </div>
          <div>
            <span> {{ scheduleDay.tipo }}</span>
            <span class="descriptionSchedule">
              {{ scheduleDay.descricao }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modalterms"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      title="Terms"
    >
      <div class="contentTerms">
        <div class="titleTerms">Termos & Condições</div>
        <img class="imgTerms" loading="lazy" :src="searchRocket" />
        <div class="textTerms">
          <p>Para continuar, por favor, aceite os nossos termos e condições.</p>
          <p>
            É crucial que você os leia e compreenda antes de prosseguir com o
            uso dos nossos serviços. Para acessar o documento completo contendo
            todas as cláusulas
            <a
              class="linkPdf"
              href="https://nfe-web-termo.s3.sa-east-1.amazonaws.com/ContratoUsuario.pdf"
              target="_blank"
              >clique aqui.</a
            >
          </p>
          <div class="checkbox-wrapper-46">
            <input
              type="checkbox"
              v-model="termsAccepted"
              id="cbx-46"
              class="inp-cbx"
            />
            <label for="cbx-46" class="cbx"
              ><span>
                <svg viewBox="0 0 12 10" height="10px" width="12px">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span
              ><span class="checkbox">Concordo com os termos e condições.</span>
            </label>
          </div>
        </div>
        <div class="btnModalTerms">
          <button
            class="btnContent"
            @click="updateTerms(termsAccepted)"
            :disabled="!termsAccepted"
          >
            Confirmar
          </button>
          <button
            class="btnContent"
            style="background: whitesmoke; color: black"
            @click="$bvModal.hide('modalterms')"
          >
            Pergunte-me depois
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "../../services/axios";
import moment from "moment";
import "moment/locale/pt";
import rocket from "../../assets/rocketi.png";
import searchRocket from "../../assets/searchRocket.png";
import GraphicLine from "./GraphicLine.vue";
import GraphicBar from "./GraphicBar.vue";
import { TypeSale } from "../../utils/enums/SalesType";
import serviceGestao from "../../services/serviceGestao";
import { mapState } from "vuex";

export default {
  components: {
    GraphicLine,
    GraphicBar,
  },
  data() {
    return {
      typeSale: TypeSale,
      rocket: rocket,
      searchRocket: searchRocket,
      spinGeneral: false,
      termsAccepted: false,
      totalDayFront: 0,
      totalDaySale: 0,
      totalDayBudget: 0,
      totalMonthSale: "",
      collapsed: false,
      userLevel: "",
      sideOCompany: "disabledContent",
      contentSideOCompany: "disabledContent",
      allScheduleDay: [],
      lentscheduleDay: 0,
      monthIndex: parseInt(moment().format("MM")),
      receivedSummary: {
        expireToday: 0,
        sumMatury: 0,
      },
      payableSummary: {
        expireToday: 0,
        sumMatury: 0,
      },
    };
  },
  methods: {
    async filterBillPerMonth() {
      try {
        this.spinGeneral = true;
        const monthName = {
          year: moment().format("YYYY"),
          month: this.monthIndex,
        };
        if (this.userLevel === "adm") {
          const { data } = await api.get(
            `/bills/search/per/month/sum/bill?month=${monthName.month}&year=${monthName.year}`
          );
          const financer = data?.data ?? [];
          this.receivedSummary.expireToday = financer.sumReceiveTotals.vencem;

          this.payableSummary.expireToday = financer.sumPayableTotals.vencem;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.spinGeneral = false;
      }
    },

    sideOpenCompany() {
      this.collapsed = !this.collapsed;
      this.contentSideOCompany = this.collapsed
        ? "contentSchedule"
        : "disabledContent";
      this.sideOCompany = this.collapsed ? "rowScedule" : "disabledContent";
    },

    handleClickOutside(event) {
      const sideCommonElement = this.$el.querySelector(".contentSchedule");

      if (
        sideCommonElement &&
        !sideCommonElement.contains(event.target) &&
        !event.target.classList.contains("buttonToOpenSideCommon")
      ) {
        this.collapsed = false;
        this.sideOCompany = "disabledContent";
        this.contentSideOCompany = "disabledContent";
      }
    },

    async filterSalePerDay() {
      try {
        if (this.userLevel === "adm") {
          const { data } = await api.get(
            `/sales/per/day/search/sum/group/dashboard/initial`
          );
          const sales = data?.data ?? [];
          this.totalDayFront = this.getTotalSaleByType(
            sales,
            this.typeSale.frenteDeCaixa
          );
          this.totalDaySale = this.getTotalSaleByType(
            sales,
            this.typeSale.venda
          );
          this.totalDayBudget = this.getTotalSaleByType(
            sales,
            this.typeSale.orcamento
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getDaySchedules() {
      try {
        const { data } = await api.get("schedules/get/schedules/day/");
        this.allScheduleDay = data;
        this.lentscheduleDay = data.length;
      } catch (error) {
        console.log(error);
      }
    },

    getTotalSaleByType(sales, type) {
      return sales
        .filter((sale) => sale.tipo === type && sale.situacao != "Cancelado(a)")
        .reduce((total, sale) => total + sale.valorTotalMonth, 0);
    },

    async updateTerms(contract) {
      try {
        const token = localStorage.getItem("token");
        await serviceGestao.updateTerms(token, contract);
        this.$bvModal.hide("modalterms");
        this.$store.commit("DATA_CONTRACT", contract);
        this.$toast.open({
          message: "Contrato atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error,
          type: "info",
        });
      }
    },

    checkUserLevel() {
      this.userLevel = localStorage.getItem("userLevel");
    },

    handleDataContractChange() {
      if (this.dataContract === false) {
        this.$bvModal.show("modalterms");
      } else {
        this.$bvModal.hide("modalterms");
      }
    },
  },
  async created() {
    this.checkUserLevel();
    await this.filterBillPerMonth(this.monthName);
    await this.filterSalePerDay();
    await this.getDaySchedules();
  },
  computed: {
    ...mapState(["dataContract"]),
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.handleDataContractChange();
  },

  watch: {
    dataContract() {
      this.handleDataContractChange();
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style scoped>
.dashboard {
  width: 85%;
  margin-top: 90px;
}

.titleMobile {
  display: none;
}

.contentCard {
  display: flex;
  justify-content: space-evenly;
}

.cardItem {
  width: 23%;
  height: 60px;
  border-radius: 3px;
  border: 2px solid #b3b4b7;
  background: white;
}

.textHeader {
  text-align: center;
  font-size: 12px;
  padding: 6px 12px;
  cursor: context-menu;
  font-weight: 600;
}

.card_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgb(103, 103, 103);
  font-size: 14px;
  font-weight: 600;
}

.spin {
  height: 20px;
  width: 20px;
}

.entrancExitContent {
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly;
}

a {
  color: rgb(37, 37, 37);
  text-decoration: none;
}
a:hover {
  color: rgb(37, 37, 37);
  text-decoration: none;
}

.entrancExit {
  width: 48%;
  height: 65px;
  background: white;
  border-radius: 3px;
  border: 2px solid #b3b4b7;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}
.entrancExit:hover {
  background: #f9f9f9ab;
}

.entrancExitValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgb(182, 8, 8);
  font-weight: 600;

  font-size: 14px;
}
.entrancValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgb(78, 182, 8);
  font-size: 14px;
  font-weight: 600;
}

.contentGraphics {
  justify-content: space-evenly;
  display: flex;
  margin-top: 30px;
  height: 45vh;
  z-index: 500;
}

.cardGraphic {
  width: 48%;
  max-width: 48%;
  height: 100%;
  border-radius: 3px;
  border: 2px solid #b3b4b7;
  z-index: 500;
  background: #ffffffab;
}

.cardGraphicNull {
  width: 48%;
  max-width: 48%;
  height: 100%;
  border-radius: 3px;
  gap: 10px;
  border: 2px solid #b3b4b7;
  z-index: 500;
  background: #ffffffab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardGraphic1 {
  width: 48%;
  max-width: 48%;
  padding-top: 20px;
  height: 100%;
  border-radius: 3px;
  border: 2px solid #b3b4b7;
  z-index: 500;
  background: #ffffffab;
}

.cardGraphic1Null {
  width: 48%;
  max-width: 48%;
  height: 100%;
  border-radius: 3px;
  gap: 10px;
  border: 2px solid #b3b4b7;
  z-index: 500;
  background: #ffffffab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rocketBkg {
  width: 400px;
  height: 400px;
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  margin: 0 50px 0 0;
  opacity: 0.5;
}

.containerSchedule {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  width: 300px;
  height: 50vh;
  right: 20px;
  align-items: center;
  justify-content: center;
}

.btnSchedule {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  width: 50px;
  height: 50px;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
  background: #efefef;
  box-shadow: 0px 1px 4px 1px rgba(142, 142, 142, 0.78);
}

.countEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  right: 20px;
  background: rgb(68, 68, 68);
  color: white;
  font-size: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.contentSchedule {
  width: 250px;
  height: 100%;
  max-height: 50vh;
  overflow: auto;
  display: flex;
  position: absolute;
  background: white;
  right: 10px;
  bottom: 60px;
  border: none;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 0;
  z-index: 1000;
  box-shadow: 0px 1px 4px 1px rgba(142, 142, 142, 0.78);
  transition: ease-in all 0.3s;
}

.disabledContent {
  height: 0;
  width: 250px;
  position: absolute;
  visibility: hidden;
  transition: ease-in all 0.3s;
  /* animation: example 3s; */
}

.titleSchedule {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.descriptionSchedule {
  color: #818181;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 100 !important;
  display: flex;
  flex-wrap: wrap;
}

.rowScedule {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(221, 221, 221);
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}

.hourSchedule {
  font-size: 16px;
  padding: 10px;
  border-radius: 50px;
  background: #ebeaea;
}

.rowScedule span {
  font-weight: bold;
  font-size: 13px;
  display: flex;
  margin-bottom: 0rem !important;
  font-style: oblique;
}

.contentTerms {
  display: flex;
  height: 70vh;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.titleTerms {
  font-size: 23px;
  text-align: center;
}

.textTerms {
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-size: 12px;
  gap: 20px;
  font-weight: bold;
  overflow: auto;
  padding: 30px 40px 20px 40px;
}

.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #506eec;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: #506eec;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: #00486e;
  border-color: #506eec;
  animation: wave-46 0.4s ease;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}

.linkPdf {
  color: #007bff !important;
  text-decoration: underline;
  text-transform: uppercase;
}

.imgTerms {
  width: 275px;
  height: 150px;
  padding-right: 30px;
}

.btnModalTerms {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .textHeader {
    font-size: 11px;
    padding: 2px 0px;
  }
  .card_content {
    font-size: 12px;
  }
  .cardItem {
    height: 50px;
  }
  .entrancExit {
    height: 50px;
  }
  .contentGraphics {
    margin-top: 20px;
  }
  .rocketBkg {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 700px) {
  .contentGraphics {
    display: none;
  }

  .contentCard {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    align-content: center;
  }

  .cardItem {
    width: 80%;
  }

  .entrancExitContent {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 20px;
  }

  .entrancExit {
    width: 80%;
  }

  .titleMobile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    font-weight: bold;
    color: #212121;
    padding: 0px 0px 20px 0px;
  }
}
</style>