<template>
  <div style="font-size: 12px; margin-top: 30px">
    <div class="generalContent">
      <div class="header-logo">
        <div class="contentImg">
          <span class="user-label-ob">
            <b-icon-images></b-icon-images> Selecione a logo
          </span>
          <div>
            <label class="logo" for="input_file">
              <span class="d-flex">
                <p
                  style="padding: 0; margin: 0"
                  :hidden="imgBase64 || dataCompany.urlImage"
                >
                  Selecione sua logo
                </p>
                <img
                  :hidden="!dataCompany.urlImage && !imgBase64"
                  :class="classImg"
                  :src="
                    dataCompany.urlImage
                      ? `${dataCompany.urlImage}?timestamp=${Date.now()}`
                      : imgBase64
                  "
                />
              </span>
            </label>
            <input type="file" id="input_file" @change="selectImage" />
          </div>
        </div>
        <div class="content-btn">
          <div class="celBtn">
            <b-button
              v-if="handleVerifyUserLevel"
              class="BtnCompany"
              @click="findPlan()"
              name="control"
              style="background: #403b74"
            >
              Controle de acesso</b-button
            >

            <b-button
              v-if="handleVerifyUserLevel"
              class="BtnCompany"
              @click="handleModals('logsScreen')"
              style="background: #f2a83e"
            >
              Registros</b-button
            >
          </div>
          <div class="celBtn">
            <b-button
              class="BtnCompany"
              @click="openModals('modalManagementCompany')"
              name="user"
              style="background: #3e66ae"
            >
              Gerenciamento de empresas</b-button
            >

            <b-button
              class="BtnCompany"
              @click="handleModals('fiscalSettings')"
              style="background: #48b865"
            >
              Configurações Adicionais</b-button
            >
          </div>
        </div>
      </div>

      <div class="content-data-company">
        <div class="rowContainer">
          <span>
            <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
            Gerais</span
          >
          <div class="rowContent">
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.nome"
                @input="company = { key: 'nome', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Nome </label>
            </div>

            <div class="input-group">
              <input
                required
                disabled
                class="input borderInput"
                v-model="company.cpfcnpj"
                @input="
                  company = { key: 'cpfcnpj', value: $event.target.value }
                "
                type="text"
              />
              <label class="fixedSelect2"> CNPJ </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.inscricaoEstadual"
                @input="
                  company = {
                    key: 'inscricaoEstadual',
                    value: $event.target.value,
                  }
                "
                type="text"
              />
              <label class="user-label"> Inscrição Estadual </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.im"
                @input="company = { key: 'im', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Inscrição Municipal </label>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span> <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Localização</span>
          <div class="rowContent">
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.endereco"
                @input="
                  company = { key: 'endereco', value: $event.target.value }
                "
                type="text"
              />
              <label class="user-label"> Endereço </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.bairro"
                @input="company = { key: 'bairro', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Bairro </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.numero"
                @input="company = { key: 'numero', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Numero </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.complemento"
                @input="
                  company = { key: 'complemento', value: $event.target.value }
                "
                type="text"
              />
              <label class="user-label"> Complemento </label>
            </div>
          </div>
          <div class="rowContent">
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.cidade"
                @input="company = { key: 'cidade', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Cidade </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.uf"
                @input="company = { key: 'uf', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> UF </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.cep"
                @input="company = { key: 'cep', value: $event.target.value }"
                maxlength="9"
              />
              <label class="user-label"> CEP </label>
              <button class="bcsCep" @click="fetchCep(company.cep)">
                <b-spinner
                  v-if="spinGeneral"
                  small
                  label="Small Spinner"
                ></b-spinner>
                buscar
              </button>
            </div>
          </div>
        </div>

        <div class="rowContainer">
          <span> <b-icon-telephone-fill></b-icon-telephone-fill> Contatos</span>
          <div class="rowContent">
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.email"
                @input="company = { key: 'email', value: $event.target.value }"
                type="text"
              />
              <label class="user-label"> Email </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.telefone"
                @input="
                  company = { key: 'telefone', value: $event.target.value }
                "
                v-mask="maskTelefone"
                type="text"
              />
              <label class="user-label"> Telefone </label>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.celular"
                @input="
                  company = { key: 'celular', value: $event.target.value }
                "
                v-mask="maskCelular"
                type="text"
              />
              <label class="user-label"> Celular </label>
            </div>
          </div>

          <div class="mt-2 d-flex justify-content-end">
            <b-button class="btnGeneral" size="sm" @click="update"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>

      <b-modal size="sm" id="modalManagementCompany" hide-header hide-footer>
        <ManagementCompany v-if="handleVerifyUserLevel" />
      </b-modal>

      <b-modal
        id="modal-tester"
        hide-footer
        hide-header
        centered
        title="inicial"
      >
        <h6 class="titleModal">
          <img class="alertImg" :src="alert" />
        </h6>
        <div class="contentModal">
          <div class="textTester">
            <p>Você não possui licenças no modo teste!</p>
            <p>Adquira seu plano para liberar essa funcionalidade.</p>
            <ul>
              <li>Whatsapp 1: (31) 3891-9414</li>
              <li>Whatsapp 2 (31) 99561-4589</li>
              <li>Email: suporte@bmssoftwares.com.br</li>
            </ul>
          </div>
        </div>
      </b-modal>
    </div>

    <div :class="sizeModal">
      <div :class="modalContainer" v-if="colapseModal">
        <b-button class="closeModal" @click="modalHeight"> X </b-button>
        <Permission
          v-if="handleVerifyUserLevel && enableModalsSettings.accessControl"
        />

        <Settings v-if="enableModalsSettings.fiscalSettings" />
        <LogsScreen v-if="enableModalsSettings.logsScreen" />
      </div>
    </div>
  </div>
</template>

<script>
import mixinSettingsCompany from "../../mixins/Settings/Company";

export default {
  mixins: [mixinSettingsCompany],
};
</script>

<style scoped>
.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModal {
  position: fixed;
  top: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.generalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.titleModal {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 3px solid rgb(238, 238, 238);
  justify-content: center;
}

.btnModal {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 55%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.contentModal {
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.textContact {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.textContact ul {
  font-size: 11px;
  list-style: circle;
}

.textContact p {
  width: 90%;
  text-align: center;
}
.textTester {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.textTester ul {
  font-size: 12px;
  list-style: circle;
}

.textTester p {
  width: 90%;
  text-align: center;
}

.footerBtnFiscal {
  display: flex;
  justify-content: space-around;
}

.contentConfFiscal {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.alertImg {
  width: 100px;
  height: 100px;
}

.contentImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 10px;
  width: 30%;
}

.header-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-btn {
  display: flex;
  gap: 20px;
  width: 40%;
}

.celBtn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.BtnCompany {
  border: none;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.logo {
  height: 135px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 7px;
  border: solid 1px rgb(223 223 223);
  color: #aaa;
  cursor: pointer;
  margin: 0;
}

.logo:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.logo__img {
  max-height: 120px;
  max-width: 200px;
}

.logo img {
  height: 120px;
  width: 200px;
  max-height: 150px;
  max-width: 200px;
  cursor: pointer;
  border-radius: 3px;
}

.logo_img_not {
  height: 148px;
  width: 200px;
  max-height: 150px;
  max-width: 200px;
  cursor: pointer;
}

.content-data-company {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#input_file {
  display: none;
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

@media screen and (max-width: 1028px) {
  .generalContent {
    flex-direction: column;
    gap: 20px;
  }
  .contentImg {
    justify-content: center;
    align-items: center;
  }
}

span {
  font-weight: bold;
  cursor: context-menu;
  font-size: 13px;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.user-label-ob {
  position: absolute;
  left: 40px;
  top: -20px;
  font-size: 13px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  padding: 5px;
  background: white;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.borderInputFile {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
  padding: 0.1rem;
}

.borderInputFile:focus {
  border: 1px solid #505050;
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 6px;
  width: 100%;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.contentEmailAndResume {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.btnGeneral {
  border-radius: 5px;
  background-color: whitesmoke;
  width: 165px;
  border: none;
  color: rgb(29, 120, 21);
  font-size: 12px;
  border: 1px solid rgb(29, 120, 21);
}

.btnGeneral:hover {
  background-color: rgb(233, 255, 223);
  color: rgb(29, 120, 21);
}

.btnCancel {
  background-color: rgb(172, 55, 55);
  color: white;
}

.btnCancel:hover {
  background-color: rgb(144, 45, 45);
  color: white;
}

.btnsUpdateCertificate {
  text-align: center;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: whitesmoke;
  width: 100%;
  border: none;
  color: rgb(29, 120, 21);
  font-size: 12px;
  border: 1px solid rgb(29, 120, 21);
  cursor: pointer;
}

.btnsUpdateCertificate:hover {
  background-color: rgb(233, 255, 223);
  color: rgb(29, 120, 21);
}

.input-group {
  width: 30%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 0 0 1rem;
  padding-left: 0 !important;
}

.card {
  border: none !important;
}

/* INPUT FILE */

.contentBtnsCertificate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contentInputFile {
  display: flex;
  gap: 5px;
  align-items: center;
}

input[type="file"] {
  display: none;
}

.labelInputFile {
  width: 400px;
  min-width: 400px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  cursor: pointer !important;
  margin: 0;
}

.textSelectFile {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  background-color: #c1c1c156;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  padding: 5.75px 9px;
  cursor: pointer;
}

.textSelectFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.textDropFile {
  max-width: 230px !important;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  cursor: pointer;
}

/* INPUT FILE */

.contentCertificateAndVirtualManager {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.containerCertificate {
  width: 40%;
  min-width: 420px;
}

.contentCertificateExpiration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(62, 102, 174);
  color: white;
  padding: 0.25rem;
  border-radius: 5px;
}

.contentCertificateExpiration span {
  font-weight: normal;
}

.contentCertificateExpiration p {
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
  font-weight: normal;
  background-color: rgb(187, 31, 31);
  border-radius: 5px;
  font-size: 14px;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9.5px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.rowCertificate {
  justify-content: center;
}

.containerCertificateExpiration {
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 2.5px 0 rgba(0, 0, 0, 0.545);
}

.contentLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.loading {
  width: 100px;
  height: 100px;
}

.contentActiveVirtualManager {
  min-width: 179px;
  display: flex;
}

.contentActiveVirtualManager label {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #00395d;
  border-radius: 5px;
  gap: 15px;
  color: #00395d;
  background-color: whitesmoke;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.135);
  padding: 5px;
  cursor: pointer;
}

.contentActiveVirtualManager input {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.selectPlus {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.selectPlus1 {
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .generalContent {
    gap: 5px;
  }
  .content-btn {
    gap: 10px;
    width: 35%;
  }
  .contentImg {
    padding: 10px 0px;
    margin-bottom: 0px;
    width: 50%;
  }
  .user-label-ob {
    left: 7px;
    font-size: 12px;
  }
  .BtnCompany {
    font-size: 11px;
  }
  .rowContainer {
    padding: 5px;
  }
  .rowContainer span {
    font-size: 12px;
  }
  .rowContent {
    padding: 5px 0 5px 0;
    gap: 5px;
  }
  .containerCertificateExpiration {
    gap: 0px;
    padding: 5px;
  }
  .btnsUpdateCertificate {
    padding: 5px;
    font-size: 11px;
  }
}

@media screen and (max-width: 1150px) and (min-width: 930px) {
  .selectPlus {
    min-width: 120px;
  }
}

@media screen and (max-width: 930px) and (min-width: 750px) {
  .contentEmailAndResume {
    flex-direction: column;
  }

  .selectPlus {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .selectPlus {
    min-width: 120px;
  }
}

@media screen and (max-width: 750px) {
  .contentCertificateAndVirtualManager {
    flex-direction: column;
  }

  .containerCertificate,
  .contentInputFile,
  .labelInputFile {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .contentBtnsCertificate div,
  .btnSendCertificate,
  .btnCancel {
    width: 100% !important;
  }

  .contentBtnsCertificate {
    gap: 1rem;
  }

  .contentEmailAndResume {
    flex-direction: column;
  }

  .selectPlus {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .contentImg {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 5px;
    padding: 20px 10px;
    margin-bottom: 10px;
    width: auto;
  }
  .logo {
    height: 100px;
    width: 170px;
  }
  .content-btn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
  }

  .ModalGeneral {
    position: fixed !important;
    z-index: 900;
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }

  .rowContent {
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .selectPlus1 {
    width: 100%;
  }
}
</style>