<template>
  <b-modal size="lg" id="modalBudget" hide-header>
    <template #modal-footer>
      <div class="footerBtn">
        <div>
          <button class="btnsBudget" @click="closeModal">Voltar</button>
        </div>

        <div>
          <button
            class="btnsBudget"
            style="border: 1px solid #0fbf4d; color: #0fbf4d"
            @click="saveOrUpdateBudget"
          >
            Salvar
          </button>
        </div>
      </div>
    </template>

    <div>
      <div class="headerBudget">
        <p>Orçamento {{ numberBudget || dataBudget.numero }}</p>
        <p>{{ totals.total }}</p>
      </div>

      <div style="padding: 0 10px">
        <hr style="margin-top: 0.2rem; margin-bottom: 1rem" />
      </div>
      <div @click="$bvModal.hide('modalBudget')" class="closeModal">
        <b-icon-x scale="1.3"></b-icon-x>
      </div>
    </div>

    <div class="contentSections">
      <section class="dataCabecalho">
        <div class="contentItens" style="padding: 0 0 0 5px">
          <p class="titleItens">Cliente</p>
          <p class="itens">
            {{ dataBudgetForModalBudget.customerSelected.nome }}
          </p>
        </div>

        <div class="contentItens" style="align-items: center">
          <p class="titleItens">Data do Orçamento</p>
          <p class="itens">
            {{ dataBudgetForModalBudget.data | moment }}
          </p>
        </div>

        <div class="contentItens" style="align-items: flex-end">
          <p class="titleItens">Funcionário</p>
          <p class="itens">
            {{ dataBudgetForModalBudget.employeeSelected.nomeFuncionario }}
          </p>
        </div>
      </section>
      <section class="dataBudgetItems responsiveTable">
        <table>
          <thead>
            <tr>
              <th>Produto/Serviço</th>
              <th>Observação</th>
              <th style="text-align: start">Quantid.</th>
              <th style="text-align: center">Valor</th>
              <th style="text-align: end">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                product, indice
              ) in dataBudgetForModalBudget.formattedProductsBudget"
              :key="indice"
            >
              <td>{{ product.nome }}</td>
              <td>{{ product.dadosAdicionais }}</td>
              <td style="text-align: start">{{ product.quantidade }}</td>
              <td style="text-align: center">R$ {{ product.valorUnitario }}</td>
              <td style="text-align: end">
                {{
                  product.valorTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <b-row style="margin: 30px 0">
        <div class="contentDataTotals">
          <div class="contentTotals">
            <span class="boxTotals">
              <p>Total de Itens (R$)</p>
              <p class="colorItens">{{ totals.totalItems }}</p>
            </span>

            <span class="boxTotals">
              <p>Desconto (R$)</p>
              <p class="colorItens">{{ totals.totalDiscount }}</p>
            </span>

            <span class="dashed"></span>

            <span class="boxTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <p class="frete">{{ totals.additionalValues }}</p>
            </span>

            <span class="boxTotals totals">
              <p>Total do Orçamento (R$)</p>
              <p class="colorItens">{{ totals.total }}</p>
            </span>
          </div>
        </div>
      </b-row>

      <hr style="margin: 0" />

      <div class="contentObservations">
        <div v-if="dataBudget.dadosAdicionais != ''">
          <p class="obsGeneral">Observações de Pagamento</p>
          <div class="descriptions">{{ dataBudget.dadosAdicionais }}</div>
        </div>

        <div v-if="dataBudget.observacoesNf != ''">
          <p class="obsGeneral">Observações NF-e</p>
          <div class="descriptions">{{ dataBudget.observacoesNf }}</div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-print-budget"
      hide-footer
      hide-header
      centered
      title="Emitir"
    >
      <div class="modal-content-budget">
        <h6 class="titleModal">Deseja imprimir ?</h6>
        <div class="btnModal-budget">
          <button class="btnContentReceive" @click="handleSaveOrPrint">
            Imprimir
          </button>
          <button class="btnContent" @click="handleValidateDataAndGenerateSale">
            Transformar em Venda
          </button>
        </div>
        <a class="rejectModal" v-if="!spinLoading" @click="clearAllData"
          >Não, Obrigado! Iniciar novo orçamento</a
        >
      </div>
      <Loading v-if="spinLoading" class="spin" />
    </b-modal>
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";

import ServiceSale from "../../services/serviceSale";
import ServiceSituation from "../../services/serviceSituation";
import { formatObjectToSaveAndUpdate } from "../../helpers/saleAndBudget";
import toastAlertErros from "../../utils/toastAlertErros";
import { ValidadeBudget } from "../../validators/budget";
import print from "../../reports/sale";

import DefaultLogo from "../../assets/LogoTeste.jpeg";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { HandleErrors } from "../../utils/handleErrors";

export default {
  props: {
    dataBudget: {
      type: Object,
    },
    totals: {
      type: Object,
    },
    dataBudgetForModalBudget: {
      type: Object,
    },
  },
  data() {
    return {
      spinLoading: false,
      defaultLogo: DefaultLogo,
      numberBudget: "",
    };
  },
  methods: {
    ...mapMutations(["DATA_BUDGET_FOR_SALE", "ALTER_TYPE_DOCUMENT"]),
    saveOrUpdateBudget() {
      if (!this.dataBudget.id) return this.saveBudget();
      this.updateCompletedBudget();
    },

    async saveBudget() {
      try {
        const dataBudgetFormatted = formatObjectToSaveAndUpdate(
          this.dataBudget
        );

        const result = await ServiceSale.createNewCompletedSale(
          dataBudgetFormatted
        );

        if (result && Object.keys(result).length && result.productsOfSale) {
          const productsPerType = result.productsOfSale.reduce(
            (prev, prod) => {
              if (prod.tipoProduto === "normal") {
                prev.products.push(prod);
              } else {
                prev.compositions.push(prod);
              }

              return prev;
            },
            { products: [], compositions: [] }
          );

          result.productsOfSale = productsPerType.products.map((prod) => {
            return {
              ...prod,
              composicao: productsPerType.compositions.filter(
                (comp) => comp.idProdutoComposicao === prod.id
              ),
            };
          });
        }

        this.numberBudget = result.number;
        this.$emit("budgetSaved", result);
        this.openModal("modal-print-budget");

        return this.$toast.open({
          message: "Orçamento salvo com Sucesso",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (error.response.status === 422) {
          return this.$toast.open({
            message:
              typeError?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        } else {
          return this.$toast.open({
            message:
              error?.response?.data?.message ||
              "Ocorreu um erro ao salvar o orçamento",
            type: "info",
          });
        }
      }
    },

    async updateCompletedBudget() {
      try {
        const dataBudgetFormatted = formatObjectToSaveAndUpdate(
          this.dataBudget
        );

        const result = await ServiceSale.updateCompletedSale(
          dataBudgetFormatted
        );

        if (result && Object.keys(result).length && result.productsOfSale) {
          const productsPerType = result.productsOfSale.reduce(
            (prev, prod) => {
              if (prod.tipoProduto === "normal") {
                prev.products.push(prod);
              } else {
                prev.compositions.push(prod);
              }

              return prev;
            },
            { products: [], compositions: [] }
          );

          result.productsOfSale = productsPerType.products.map((prod) => {
            return {
              ...prod,
              composicao: productsPerType.compositions.filter(
                (comp) => comp.idProdutoComposicao === prod.id
              ),
            };
          });
        }

        this.$emit("budgetSaved", result);
        this.openModal("modal-print-budget");

        return this.$toast.open({
          message: "Orçamento atualizado com Sucesso",
          type: "success",
        });
      } catch (error) {
        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async handleValidateDataAndGenerateSale() {
      const situation = await ServiceSituation.findById(
        this.dataBudget.idSituacao
      );
      if (situation.nome === SaleTypeSituation.cancelado) {
        return this.$toast.open({
          message:
            "Não é possível gerar uma venda a partir de um orçamento cancelado",
          type: "info",
        });
      }

      const newDataBudget = this.formatValuesForValidate(this.dataBudget);

      const isNotValid = ValidadeBudget(newDataBudget);
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.generateSale(newDataBudget);
    },

    generateSale(newDataBudget) {
      this.DATA_BUDGET_FOR_SALE(newDataBudget);
      this.ALTER_TYPE_DOCUMENT("venda");
    },

    formatValuesForValidate(budget) {
      const newDataBudget = {
        ...budget,
      };

      const newProductsBudget = newDataBudget.products.map((prod) => {
        return {
          ...prod,
          valorUnitario: prod.valorUnitario,
          desconto: prod.desconto,
        };
      });

      newDataBudget.products = newProductsBudget;
      newDataBudget.dataVencimento = null;
      return { ...newDataBudget };
    },

    async handleSaveOrPrint() {
      if (!this.dataBudget.id) await this.saveBudget();
      print("reportVendaOrcamento", this.dataBudget.id);
    },

    closeModal() {
      this.$bvModal.hide("modalBudget");
    },

    openModal(name) {
      this.$bvModal.show(name);
    },

    clearAllData() {
      this.$emit("clearAllBudget", true);
      this.numberBudget = "";
      this.$bvModal.hide("modal-print-budget");
      this.closeModal();
    },
  },
  computed: {
    ...mapState({
      dataCompany: (state) => state.dataCompany,
    }),
    returnTextSaveAndPrint() {
      if (!this.dataBudget.id) {
        return "Salvar e Imprimir";
      } else {
        return "Imprimir";
      }
    },
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.footerBtn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

thead th {
  border-bottom: 1px solid #d5d3d3;
  color: #afadad;
  font-size: 12px;

  font-weight: 600;
}

td {
  color: black;
  font-size: 12px;
}

.dataBudgetItems {
  font-size: 14px;
  margin-top: 25px;
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.responsiveTable {
  max-height: 200px;
  overflow-x: auto;
}

th {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

td {
  color: black;
}

.btnBudget {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

.btnsActions {
  width: 150px;
  font-size: 18px;
  margin-top: 0;
  background-color: #0fbf4d;
}

.btnSaveAndSendLeaf {
  width: 200px;
  font-size: 18px;
  margin-top: 0;
  background-color: #00395d;
}

.summaryTitle h5 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #00486e;
}

.contentSummary {
  display: flex;
  flex-direction: column;
}

.contentSummary p {
  margin-bottom: 5px;
}

.itensSummary {
  display: flex;
  justify-content: space-between;
}

.company p {
  font-family: Arial, Helvetica, sans-serif;
  color: #00486e;
  font-size: 15px;
}

.contentDataCompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataCompany {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dataCompany img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px rgb(162, 144, 144);
}

.contentLogo {
  display: flex;
  justify-content: center;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.boxTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
  cursor: auto;
}

.boxTotals {
  display: flex;
  align-items: center;
  cursor: auto;
}

.boxTotals .colorItens {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f7f7f7;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.totals {
  font-size: 14px !important;
  color: #666360 !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.boxTotals p {
  width: 200px;
}

.contentFreteTotals .frete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f7f7f7;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.dataCabecalho {
  width: 100%;
  display: flex;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.contentItens {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.titleItens {
  font-weight: 600;
  color: #afadad;
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.itens {
  color: black;
  font-size: 14px;
}

.contentSections {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.contentObservations {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.contentObservations p {
  color: black;
  font-size: 14px;
}

.headerBudget {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 0 10px;
}

.headerBudget p {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #666360 !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.476);
  padding: 1px 2px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.contentDataTotals {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.btnsBudget {
  border-radius: 5px;
  background-color: transparent;
  width: 200px;
  height: 28px;
  border: none;
  border: solid 1px black;
  color: black;
  font-size: 12px;
}

.btnGenerateSale {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.descriptions {
  outline: none;
  padding: 5px;
  background-color: #f7f7f7;
  border-radius: 5px;
  min-height: 30px;
  height: auto;
  width: 100%;
}

p {
  margin: 0 !important;
}

.modal-content-budget {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal-budget {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 40%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 30%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.rejectModal {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-style: oblique;
  text-decoration: underline;
}

.spin {
  width: 480px;
  height: 100%;
  top: 0;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00486e;
  border-radius: 2px;
}

.obsGeneral {
  color: black;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .contentTotals {
    font-size: 11px;
    gap: 0px;
  }
  .totals {
    font-size: 12px !important;
  }
  .dataBudgetItems {
    font-size: 11px;
    margin-top: 9px;
  }
}
</style>